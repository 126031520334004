namespace app {
    /**
     * All custom filters should be defined in this class
     * Note each filter must be added in the button of the file
     */
    export class CustomFilters {

        static elipsis() {
            return (input: string, length: number) => {
                if (input == null)
                    return input;
                if (input.length > length) {
                    return input.substring(0, length) + "...";
                }
                return input;
            };
        }
        static booleanToYesNo($filter: any) {
            return (input: boolean): any => {
                if (input === true)
                    return $filter("translate")("app.yes");
                return $filter("translate")("app.no");

            };
        }

        static numberToInfinite() {
            return (input: any) => {
                if (parseInt(input, 10) > 5) {
                    return "Unlimitted";
                }
                return input;
            };
        }

    }
    angular.module("app").filter("elipsis", CustomFilters.elipsis);
    angular.module("app").filter("booleanToYesNo", CustomFilters.booleanToYesNo);
    angular.module("app").filter("numToInf", CustomFilters.numberToInfinite);
}