namespace app {
    export class ProfileController {

        constructor(
            private UserService: UserService,
            private user: User,
            private ToasterService: ToasterService) { }

        changePassword(password: string, confirm: string) {
            if (password !== confirm)
                return;
            this.UserService.updatePassword(this.user, password).then((response) => {
                this.ToasterService.pop("Your password has been updated!");
            });
        }
    }
    angular.module("app").controller("ProfileController", ProfileController);
}