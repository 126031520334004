declare var ObjectId: any;
namespace app {
    export interface IInvoice {
        amount_due: number;
        currency: string;
        date: number;
        closed: boolean;
        paid: boolean;
        period_start: number;
        period_end: number;
        receipt_number: string;
        subtotal: number;
        tax: number;
        tax_percent: number;
        total: number;
        lines: any;
    }
    export interface IPlan {
        id: string;
        amount: number;
        created: number;
        currency: string;
        interval: string;
        interval_count: number;
        livemode: boolean;
        metadata: any;
        name: string;
        statement_descriptor: string;
        trial_period_days: number;
    }

    export interface ISubscription {
        id: string;
        cancel_at_period_end: boolean;
        canceled_at: number;
        current_period_end: number;
        current_period_start: number;
        customer: string;
        discount: number;
        ended_at: number;
        metadata: any;
        plan: IPlan;
        quantity: number;
        start: number;
        status: string;
        tax_percent: any;
        trial_end: number;
        trial_start: number;
    }

    export interface IEntityAttributes {
        is_new?: boolean;
    }
    export class BaseEntity {
        attributes: IEntityAttributes;

        constructor() {
            this.attributes = {
                is_new: true
            };
        }
    }

    export interface ICountResponse {
        count: number;
    }

    export class ProjectActivity {
        project_id: any;
        language_id: any;
        user_id: any;
        email: string;
        project_name: string;
        language_name: string;
        translation_id: any;
        reference_key: string;
        type: string;
        text: string;
        create_time: number;
    }

    export class LicensePlan {
        _id: string;
        name: string;
        num_keys: number;
        num_projects: number;
        price: number;
        create_time: number;
        update_time: number;
        delete_time: number;
    }

    export class Project {
        _id: string;
        account_id: string;
        name: string;
        languages: any[] = [];
        primary_language: any;
        description: string;
        is_public: boolean = false;
        created_by: string;
        create_time: number;
        update_time: number;
        delete_time: number;
        contributors: ProjectContributor[] = [];
        translations: string[] = [];
        translations_count: TranslationCount[] = [];
        user_languages: string[]; // computed
    }

    export class ProjectContributor {
        user_id: string;
        language_id: string;
    }
    export class TranslationCount {
        count: number;
        language_id: string;
    }

    export class Language {
        _id: string;
        name: string;
        code: string;
        icon: string;
        create_time: number;
        update_time: number;
        delete_time: number | null = null;
    }
    export class KeyDefinition {
        reference_key: string;
        tags: string[] = [];
        comments: Comment[] = [];
    }

    export class Translation {
        _id: string;
        project_id: string;
        reference_key: string;
        primary_text: string;
        context: string | undefined;
        tags: string[] = [];
        comments: Comment[] = [];
        definitions: Definition[] = [];
        active_definition: Definition; // Computed - only used in view
        create_time: number;
        update_time: number;
        delete_time: number | null = null;

        selected: any; // Computed - will be deleted before save
    }
    export class Definition {

        constructor() {
            this._id = new ObjectId().toString();
        }

        _id: string;
        language_id: string;
        text: string | null;
        fuzzy: boolean = false;
        translated_by: string;
        reviewed_by: string;
        history: DefinitionHistory[];
        reviewed: boolean = false;
        translate_time: number;
        copied_from: string;
        copy_time: number;
        create_time: number;
        update_time: number;
        delete_time: number | null = null;

    }
    export class DefinitionHistory {
        id: string;
        timestamp: number;
        translated_by: string;
        text: string;
    }
    export class Comment {
        username: string;
        text: string;
        language_id: string;
        timestamp: number;
    }

    export class User {
        _id: string;
        account_id: string;
        email: string;
        username: string;
        city: string;
        country: string;
        admin: boolean = false;
        reviewer: boolean = false;
        contributor: boolean = false;
        projects: UserProject[] = [];
        invite_token: string;
        create_time: number;
        update_time: number;
        delete_time: number | null = null;
    }

    export class Contributor extends User {
        constructor() {
            super();
            this.contributor = true;
        }
    }
    export class UserProject {
        project_id: string;
        language_id: any;
    }

    export class Account {
        _id: string;
        api_key: string;
        license_plan: number;
        stripe_customer_id: string;
        stripe_subscription_id: string;
        card_token: string | null;
        card_id: string | null;
        create_time: number;
        update_time: number;
        delete_time: number;
    }
}