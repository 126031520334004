namespace app {

    export interface ICard {
        number: string;
        cvc: number;
        exp_year: number;
        exp_month: number;
    }

    class AccountSubscriptionController {

        private removingCard: boolean;

        constructor(
            private AccountService: AccountService,
            private ToasterService: ToasterService,
            private account: Account,
            private license_plans: LicensePlan[],
            private current_card: any,
            private subscription: ISubscription,
            private $mdDialog: ng.material.IDialogService,
            private $scope: ng.IScope
        ) {
            $scope.$on("card_added", (event, data) => {
                this.current_card = data;
            });
        }

        removeCard() {
            this.removingCard = true;
            this.AccountService.deleteCurrentCard().then((response) => {
                this.current_card = {};
                this.account.card_token = null;
                this.account.card_id = null;
                this.ToasterService.pop("app.removed.card.successful");
            })
                .catch(() => {
                    this.ToasterService.pop("app.removed.card.failure");
                })
                .finally(() => {
                    this.removingCard = false;
                });
        }

        showAddCreditCard(event: any, current_card: any) {

            this.$mdDialog.show({
                controller: AddCreditCardController,
                templateUrl: "src/features/accounts/add-creditcard-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                bindToController: true,
                clickOutsideToClose: true
            });
        }

        changePlan(license: LicensePlan) {
            if (this.account.license_plan["_id"] === license._id || !license._id)
                this.ToasterService.pop("app.license.already.current");
            this.AccountService.changePlan(license._id).then((response) => {
                this.account.license_plan = response;
            });
        }
    }
    angular.module("app").controller("AccountSubscriptionController", AccountSubscriptionController);
}
