namespace app {
    export class ProjectController {
        hasScrolled: boolean;
        tooltipVisible: boolean;
        public editMenuIsOpen: boolean = false;
        public translatorMenuIsOpen: boolean = false;

        /** Array of yet to be used languages */
        private unusedLanguages: Language[] = [];

        /** Determines which language is currently selected for translation */
        public active_language: Language | null = null;
        public current_project: Project | null = null;

        /** Determines if the project FAB tool is open.  */
        public isOpen: boolean = false;

        constructor(
            private LanguageService: LanguageService,
            private ProjectService: ProjectService,
            private TranslationService: TranslationService,
            private ModalService: ModalService,
            private ToasterService: ToasterService,
            private project: Project,
            private languages: Language[],
            private projects: Project[],
            private UserService: UserService,
            private $rootScope: IRootScope,
            private $state: ng.ui.IStateService,
            private $stateParams: IIdStateService,
            private $timeout: ng.ITimeoutService,
            private $filter: ng.IFilterService,
            private $window: ng.IWindowService,
            private translationCount: any,
            private $mdDialog: any,
            private $scope: ng.IScope,
            private $mdSidenav: any,
            private hotkeys: any,
            private projectTags: string[]) {

            angular.element($window).bind("scroll", () => {
                let documentHeight = $(document).height();
                let windowHeight = $(window).height();
                let scrollPosition = $(window).scrollTop();
                if (documentHeight === windowHeight) {
                    return;
                }
                if (scrollPosition > 1) {
                    this.hasScrolled = true;
                } else {
                    this.hasScrolled = false;
                }
                this.$scope.$apply();
            });

            if ($rootScope.user && !$rootScope.user.admin) {
                let cLangs: any[] = this.project.contributors
                    .filter(element => $rootScope.user!._id === element.user_id)
                    .map(element => element.language_id);

                this.project.languages = this.project.languages.filter(lang => cLangs.some(cLang => cLang === lang._id));
            }

            this.unusedLanguages = this.languages.filter((language: Language) => {
                let exLang = project.languages.find(l => l._id === language._id);
                return (exLang === undefined) ? true : false;
            });

            this.initActiveLanguage();
            this.current_project = this.projects.find(p => p._id === this.$stateParams.id);
            // Init shortcuts if admin
            if ($rootScope.user && $rootScope.user.admin) {
                this.initShortcuts();
            }

            $scope.$on("$destroy", () => {
                this.hasScrolled = false;
                $(window).unbind("scroll");
            });

            this.$rootScope.$on("add.translation", (event, data) => {
                this.translationCount.count++;
            });
            this.$rootScope.$on("delete.translation", (event, data) => {
                this.translationCount.count--;
            });
        }

        filterChanged() {
            this.$rootScope.$broadcast("filter.changed");
        }

        toggleUntranslated() {
            this.TranslationService.filter.untranslated = this.TranslationService.filter.untranslated === true ? false : true;
            this.filterChanged();
        }

        toggleFuzzy() {
            this.TranslationService.filter.fuzzy = this.TranslationService.filter.fuzzy === true ? false : true;
            this.filterChanged();
        }

        toggleUnapproved() {
            this.TranslationService.filter.unreviewed = this.TranslationService.filter.unreviewed === true ? false : true;
            this.filterChanged();
        }

        /**
         * When changing language we need to reset pagination
         */
        changeLanguage(language: Language) {
            this.TranslationService.resetFilter();
            this.active_language = language;
            this.TranslationService.pagination.current_page = 1;
            this.$state.go("app.project.translation", { id: this.project._id, language_id: language._id });
        }

        createNewProject() {
            this.$mdDialog.show({
                controller: AddProjectController,
                templateUrl: "src/features/projects/add-project-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: (LanguageService: LanguageService) => {
                        return this.LanguageService.findAll();
                    }
                }
            });
        }

        /**
         * Change project
         */
        changeProject(project: Project) {
            if (!project) {
                this.createNewProject();
            } else {
                this.$state.go("app.project", { id: project._id });
            }
        }

        notifyProjectOwner() {
            this.ProjectService
                .notifyOwner(this.project._id, this.active_language!._id)
                .then(() => {
                    this.ToasterService.pop("app.owner.notified");
                });
        }

        /**
         * Show the import modal
         */
        showImportView(event) {
            this.ModalService.showImportModal(event, this.project);
        }

        /**
         * Show the export modal
         */
        showExportView(event) {
            this.$mdDialog.show({
                controller: ProjectExportController,
                templateUrl: "src/features/projects/project-export-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: (LanguageService: LanguageService) => {
                        return this.LanguageService.findAll();
                    }
                },
                locals: {
                    project: this.project
                }
            });
        }

        /**
         * Show the add contributor modal
         */
        showAddContributorView(event) {
            this.$mdDialog.show({
                controller: AddContributorController,
                templateUrl: "src/features/projects/add-contributor-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: (LanguageService: LanguageService) => {
                        return this.LanguageService.findAll();
                    },
                    users: () => {
                        return this.UserService.findAll();
                    }
                },
                locals: {
                    project: this.project
                }
            });
        }

        showEditProject(event) {
            this.$mdDialog.show({
                controller: ProjectSettingsController,
                templateUrl: "src/features/projects/project-settings-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    project: this.project
                }
            });
        }

        /**
         * Removes a language from a contributor
         */
        removeContributorLanguage(contributor: Contributor, userProject: UserProject) {
            contributor.projects.splice(contributor.projects.indexOf(userProject), 1);
            this.UserService.saveUserProject(contributor);
        }

        /**
         * Adds a language to a contributor
         */
        addContributorLanguage(contributor: Contributor, language: Language) {
            contributor.projects.push({
                language_id: language._id,
                project_id: this.project._id
            });
            this.UserService.saveUserProject(contributor);
        }

        /**
         * Notify contributors about changes
         */
        notifyContributors(project: Project) {
            this.ProjectService.notifyContributors(project._id).then(() => {
                this.ToasterService.pop("app.translators.notified");
            });
        }

        /**
         * Removes a language from the project
         */
        removeLanguage(language: Language) {
            if (!language || !this.active_language)
                return;
            this.ProjectService.removeLanguage(this.project._id, this.active_language._id).then((response) => {
                this.unusedLanguages.push(this.active_language!);
                this.project.languages.splice(this.project.languages.indexOf(language), 1);
                this.$state.go("app.project", { project_id: this.project._id });

            });
        }

        saveProject(project: Project) {
            return this.ProjectService.save(project);
        }

        toggleMenu(menu: string) {
            if (menu === "translatorMenu") {
                this.translatorMenuIsOpen = !this.translatorMenuIsOpen;
            } else if (menu === "editMenu") {
                this.editMenuIsOpen = !this.editMenuIsOpen;
            }
        }

        /**
         * Initlaize the active language
         */
        private initActiveLanguage() {
            if (this.$state.params["language_id"]) {
                let langs = this.project.languages.filter((language) => {
                    return language._id === this.$state.params["language_id"];
                });
                if (langs && langs.length === 1) {
                    this.active_language = langs[0];
                }
            }
        }

        private initShortcuts() {
            this.hotkeys.add({
                combo: "shift+d",
                description: "Add definition",
                callback: () => {
                    this.$rootScope.$broadcast("add_definition");
                }
            });
            this.hotkeys.add({
                combo: "shift+e",
                description: "Export project",
                callback: () => {
                    this.showExportView(null);
                }
            });
            this.hotkeys.add({
                combo: "shift+c",
                description: "Add contributor",
                callback: () => {
                    this.showAddContributorView(null);
                }
            });
        }
    }
    angular.module("app").controller("ProjectController", ProjectController);
}