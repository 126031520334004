namespace app {
    export class AddProjectController {

        public project: Project = new Project();
        public languageList: Language[] = [];
        public saving: boolean = false;

        constructor(
            private ProjectService: ProjectService,
            private languages: Language[],
            private $state: IIdStateService,
            private TrackingService: TrackingService,
            private $rootScope: IRootScope,
            private $mdDialog: ng.material.IDialogService) {}

        save(project: Project) {
            project.created_by = this.$rootScope.user!._id;
            project.primary_language = project.primary_language._id;
            project.languages = this.languageList.map(lang => lang._id);
            project.languages.unshift(project.primary_language);
            this.saving = true;
            this.ProjectService.save(project).then((response: Project) => {
                this.TrackingService.trackAddProject({name: this.project.name, language: this.languageList.map(l => l.name).join(", ")});
                this.$state.go("app.project.translation", { id: response._id, language_id: project.primary_language});
            }).finally(() => {
                this.saving = false;
                this.close();
            });
        }

        removeFromLanguageList(language: Language) {
           this.languageList = this.languageList.filter(l => l._id === language._id);
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("AddProjectController", AddProjectController);
}