namespace app {

    export class TranslationController {

        public selectedLanguage: any;
        public isPrimaryLanguage: boolean;
        public newTranslation = new Translation();
        public filterQuery: Query = new Query();
        public currentScrollPosition: number | null = null;
        public hasMore: boolean | null = null;
        public loading: boolean = false;
        public loadingMore: boolean = false;
        public selected_translation: Translation | null = null;
        public percentage_done: number | null = null;
        public showNotifyOwner: boolean = true;
        public hasSavedText: boolean = false;
        public debounceMap = {};

        constructor(
            private ProjectService: ProjectService,
            private languages: Language[],
            private language: Language,
            private translations: Translation[],
            private project: Project,
            private projectTags: string[],
            private TranslationService: TranslationService,
            private $rootScope: IRootScope,
            private $mdDialog: ng.material.IDialogService,
            private $state: ng.ui.IStateService,
            private $mdSidenav: ng.material.ISidenavService,
            private hotkeys: any,
            private ModalService: ModalService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private ToasterService: ToasterService,
            private definitionCount: any,
            private translationCount: any
        ) {

            // Always reset the current page to 1 on init
            this.TranslationService.pagination.current_page = 1;
            if ($rootScope.user && $rootScope.user.admin) {
                hotkeys.add({
                    combo: "ctrl+d",
                    description: "Create new definition",
                    callback: () => {
                        this.addDefinition(null);
                    }
                });
            }
            translations.forEach((translation) => {
                this.TranslationService.initTranslation(translation);
            });
            this.isPrimaryLanguage = (this.language._id === this.project.primary_language._id);

            this.hasMore = this.TranslationService.pagination.items_per_page === this.translations.length;
            this.calculatePercentage();
            this.project.languages.sort((a, b) => a._id === this.project.primary_language._id ? -1 : 1);
            this.selectedLanguage = this.project.languages.find(lang => lang._id === this.language._id);

            this.$rootScope.$on("delete.translation", (event, translation: Translation) => {
                this.delete(translation);
            });
            /**
             * Watch the filters search when they change
             */
            this.$scope.$on("filter.changed", () => {
                this.TranslationService.pagination.append = false;
                this.TranslationService.pagination.current_page = 1;
                this.$rootScope.loading = true;
                this.TranslationService.findByFilter(this.project._id, this.language._id).then((response: Translation[]) => {
                    this.translations = response;
                    this.hasMore = this.TranslationService.pagination.items_per_page === response.length;

                }).finally(() => this.$rootScope.loading = false);
            });
            this.initializeInfiniteScroll();
            // Allow other controllers to add definitions
            this.$scope.$on("add_definition", () => {
                this.addDefinition(null);
            });
        }

        calculatePercentage() {
            if (this.translationCount.count === 0)
                return this.percentage_done = 0;
            this.percentage_done = Math.round((this.definitionCount.count / this.translationCount.count * 100) * 100) / 100;
        }

        autoTranslate(translation: Translation) {
            this.TranslationService
                .autoTranslate(translation._id, translation.primary_text, this.project.primary_language._id, this.language._id)
                .then((translatedText) => {
                    translation.active_definition.text = translatedText;
                    this.saveText(translation, translatedText);
                });
        }

        getIcon(definition: Definition) {
            if (definition.reviewed)
                return "done_all";
            if (definition.fuzzy)
                return "flash_on";
            if (definition.create_time)
                return "done";
            return "keyboard";
        }

        changeLanguage(language: Language) {
            this.TranslationService.resetFilter();
            this.TranslationService.pagination.current_page = 1;
            this.$state.go("app.project.translation", { id: this.project._id, language_id: language._id });
        }

        /**
         * Loads more translations and puts them into the table
         */
        loadMoreTranslations() {
            if (!this.hasMore || this.loadingMore === true)
                return;
            this.loadingMore = true;
            this.TranslationService.pagination.append = true;
            this.TranslationService.pagination.current_page += 1;
            this.TranslationService.findByFilter(this.project._id, this.language._id).then((response: Translation[]) => {
                if (this.TranslationService.pagination.append)
                    this.translations = this.translations.concat(response);
                this.hasMore = this.TranslationService.pagination.items_per_page === response.length;
            }).finally(() => {
                this.loadingMore = false;
            });
        }

        initializeInfiniteScroll() {
            let currentPosition: number = 0;
            this.$timeout(() => {
                let docuemntHeight = $(document).height() - $(window).height();
                // Only scroll if document is higher than the saved position
                if (docuemntHeight > currentPosition)
                    window.scrollBy(0, currentPosition);
            }, 100);
            // Inifite scroll
            $(window).scroll(() => {
                this.currentScrollPosition = $(window).scrollTop();
                // When reaching bottom we will load more content
                if ($(window).scrollTop() === $(document).height() - $(window).height()) {
                    this.loadMoreTranslations();
                }
            });
        }

        saveText(translation: Translation, text: string) {
            this.$timeout.cancel(this.debounceMap[translation._id]);
            this.debounceMap[translation._id] = this.$timeout(() => {
                let isNew = (translation.active_definition.create_time) ? false : true;
                this.TranslationService.saveDefinition(translation).then((response) => {
                    if (isNew) {
                        this.definitionCount.count++;
                        this.calculatePercentage();
                    }
                    this.hasSavedText = true;
                }).catch(err => {
                    this.ToasterService.pop("Error saving your text");
                });
            }, 1000);
        }
        addDefinition(event) {
            if (!this.$rootScope.user || !this.$rootScope.user.admin)
                return;
            this.$mdDialog.show({
                controller: AddDefinitionController,
                templateUrl: "src/features/translations/add-definition-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    translations: this.translations,
                    project: this.project,
                    definitionCount: this.definitionCount,
                    translationCount: this.translationCount

                }
            });
        }

        initializeNewDefinition() {
            let newDef = new Definition();
            newDef.language_id = this.language._id;
            newDef.text = null;
            this.newTranslation.definitions.push(newDef);
        }

		/**
		 * Shows the comment sidebar
		 */
        showComments(translation: Translation) {
            this.selected_translation = translation;
            return this.$mdSidenav("comments").toggle();
        }

        showImportModal(event: Event) {
            this.ModalService.showImportModal(event, this.project);
        }

        showHistory(event, translation: Translation) {
            this.$mdDialog.show({
                controller: DefinitionHistoryController,
                templateUrl: "src/features/translations/definition-history-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    translation: translation
                }
            });
        }

        showDetails(event, translation: Translation) {
            this.$mdDialog.show({
                controller: DefinitionDetailsController,
                templateUrl: "src/features/translations/definition-details-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    translation: translation,
                    project: this.project
                }
            });
        }

        /**
         * Pops the add language modal
         */
        showAddLanguage(event, selectedLanguage: Language) {
            const unusedLanguages = this.languages.filter((language: Language) => {
                let exLang = this.project.languages.find(l => l._id === language._id);
                return (exLang === undefined) ? true : false;
            });
            this.$mdDialog.show({
                controller: AddLanguageController,
                templateUrl: "src/features/projects/add-language-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    project: this.project,
                    unusedLanguages: unusedLanguages
                }
            });
            this.$timeout(() => {
                this.selectedLanguage = selectedLanguage;
            }, 100);
        }

        toggleMulti(field: string, flag: boolean) {
            let copyFilterQuery = angular.copy(this.filterQuery);
            copyFilterQuery.removePagination();
            copyFilterQuery.addCriteria(C.elementMatch("definitions", { language_id: this.language._id }));
            copyFilterQuery.select(["_id"]);
            this.TranslationService.findByQuery(copyFilterQuery).then((response: any[]) => {
                let ids = response.map(translation => translation._id);
                this.TranslationService.toggleMulti(field, flag, this.language._id, ids).then(_ => _);
            });
        }

		/**
		 * Toggles if the key is reviewed or not
		 */
        toggleReviewed(translation: Translation) {
            translation.active_definition.reviewed = (translation.active_definition.reviewed) ? false : true;
            if (translation.active_definition.reviewed)
                translation.active_definition.fuzzy = false;
            this.TranslationService.toggleReviewed(translation);
        }

		/**
		 * Toggles if the key is fuzzy / unsure of translation
		 */
        toggleFuzzy(translation: Translation) {
            translation.active_definition.fuzzy ? translation.active_definition.fuzzy = false : translation.active_definition.fuzzy = true;
            this.TranslationService.toggleFuzzy(translation);
        }

		/**
		 * Adds a tag to the key
		 */
		/*addTag(tag : string, translation : Translation) {
			console.log(translation)
			this.save(translation);
			for (var index = 0; index < this.projectTags.length; index++) {
				var element = this.projectTags[index];
				if(element == tag)
					return;
			}
			this.projectTags.push(tag);
		}*/

        delete(translation: Translation) {
            this.TranslationService.delete(translation).then(() => {
                this.translations.splice(this.translations.indexOf(translation), 1);
                this.decreaseTotals();
            });
        }

        increaseTotals() {
            this.translationCount.count++;
            this.calculatePercentage();
        }

        decreaseTotals() {
            this.translationCount.count--;
            this.definitionCount.count--;
            this.calculatePercentage();
        }

    }
    angular.module("app").controller("TranslationController", TranslationController);
}