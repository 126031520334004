namespace app {
    interface ILoaderScope extends ng.IScope {
        events?: string;
    }
    /**
     * Description
     * The loader component shows a spinning loading icons indicating that an operation is in progress
     *
     * Example of usage
     * <loader size="5"></loader> Loader will render in size 5 show at all times
     * <loader events="dataloading|bootstrapping"></loader> Loader will only show in
     * case of $rootScope.dataloading & $rootScope.bootstrapping is true
     */
    class Loader implements ng.IDirective {

        scope = { events: "@" };
        restrict = "AE";

        template(element: JQuery, attrs: any) {
            if (attrs.size == null)
                attrs.size = 5;
            return '<i class="fa fa-cog fa-spin fa-' + attrs.size + 'x" style="color: white;"><li>';
        }

        static factory() {
            return ($rootScope: ng.IRootScopeService) => {
                return new Loader($rootScope);
            };
        }
        /*@ngInject*/
        constructor(public $rootScope: ng.IRootScopeService) {
        }

        link = (scope: ILoaderScope, element: JQuery) => {
            if (scope.events != null) {
                let parts = scope.events.split("|");
                parts.forEach((event) => {
                    this.$rootScope.$watch(event, (n, o) => {
                        (n === true) ? element.show() : element.hide();
                    });
                });
            }
        }
    }
    angular.module("app").directive("loader", Loader.factory());
}