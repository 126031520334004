namespace app {
	export class AuthService {

		constructor(
			private $http: ng.IHttpService,
			private $rootScope: IRootScope,
			private TrackingService: TrackingService,
			private localStorageService: angular.local.storage.ILocalStorageService,
			private Restangular: restangular.IService,
			private $state: ng.ui.IStateService) {}

		logout() {
			this.localStorageService.clearAll();
		}

		authenticate(email: string, password: string, remember: boolean = true) {
			this.$rootScope.authenticating = true;
			return this.$http.post("api/v1/authenticate", { email: email, password: password }).then((response: any) => {
				this.handleRemember(remember);
				this.setToken(response.data.token);
				this.setUser(response.data.user);
				this.setDefaultHeader(response.data.token);
				this.$rootScope.$broadcast("authorized");
				this.$rootScope.authenticating = false;
				this.TrackingService.trackLogin({email});
			}, (response: any) => {
				this.$rootScope.$broadcast("unauthorized");
				this.$rootScope.authenticating = false;
			});
		}

		handleRemember(remember: boolean) {
			(remember) ? this.localStorageService.set("remember", true) : this.localStorageService.remove("remember");
		}

		confirmInvite(token, password, repassword) {
			this.$http.post("api/v1/confirm_invite", {
				token: token,
				password: password,
				repassword: repassword
			}).then((response: any) => {
				this.authenticate(response.data.user.email, password);
			});
		}

		isAuthenticated() {
			let token: string = this.localStorageService.get<string>("token");
			if (token) {
				this.setDefaultHeader(token);
				this.setUserOnRootScope();
				return true;
			}
			return false;
		}

		setToken(token: string) {
			this.localStorageService.set("token", token);
		}

		setUser(user: User) {
			this.localStorageService.set("user", user);
			this.setUserOnRootScope(user);
		}

		private setDefaultHeader(token: string) {
			this.Restangular.setDefaultHeaders({ "x-access-token": token });
		}

		private setUserOnRootScope(user?: User) {
			if (!user)
				user = this.localStorageService.get("user") as User;
			this.$rootScope.user = user;
		}
	}
	angular.module("app").service("AuthService", AuthService);
}