namespace app {

    export class ProjectSettingsController {

        private form: ng.IFormController;

        constructor(
            private ProjectService: ProjectService,
            private project: Project,
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService) {}

        save(project: Project) {
            if (this.form.$invalid) {
                return;
            }
            this.ProjectService.save(project).then(() => {
                this.close();
            });
        }

        delete(project: Project) {
            this.ProjectService.delete(project).then(() => {
                this.$state.go("app.projects");
            });
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("ProjectSettingsController", ProjectSettingsController);
}