namespace app {
    export class DataService<T> {

        public resource;

        /** @ngInject */
        constructor(
            protected Restangular: restangular.IService,
            protected AppConstants: IAppConstants,
            protected UtilityService: UtilityService,
            protected Upload: ng.angularFileUpload.IUploadService,
            protected localStorageService: angular.local.storage.ILocalStorageService,
            protected $rootScope: IRootScope,
            protected $q: ng.IQService,
            protected $timeout: ng.ITimeoutService,
            protected $stateParams: IIdStateService,
            protected $filter: ng.IFilterService) {
        }

        save(entity: any): ng.IPromise<T> {
            this.$rootScope.dataloading = true;
            if (entity._id)
                return this.Restangular.one(this.resource, entity._id).customPUT(entity);
            else
                return this.Restangular.one(this.resource).customPOST(entity);
        }

        findAll(): restangular.ICollectionPromise<T[]> {
            return this.Restangular.all(this.resource).getList();
        }

        findById(id, query?: Query): ng.IPromise<T> {
            this.$rootScope.dataloading = true;
            let endpoint = this.resource;
            if (query)
                return this.Restangular.one(endpoint, id).get(query.toFilter());
            else
                return this.Restangular.one(endpoint, id).get();
        }

        findByQuery(query: Query): ng.IPromise<T[]> {
            this.$rootScope.dataloading = true;
            let endpoint = this.resource;
            return this.Restangular.all(endpoint).getList(query.toFilter());
        }

        countByQuery(query: Query): ng.IPromise<ICountResponse> {
            this.$rootScope.dataloading = true;
            let endpoint = this.resource + "/count";
            return this.Restangular.one(endpoint).get(query.toFilter());
        }

        delete(entity): ng.IPromise<T> {
            return this.Restangular.one(this.resource, entity._id).remove();
        }

        saveAll(entities: any[]) {
            this.$rootScope.dataloading = true;
            let promises: any[] = [];
            entities.forEach((entity) => {
                promises.push(this.save(entity));
            });
            return this.$q.all(promises);
        }
    }
}