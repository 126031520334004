namespace app {
	export class DefinitionHistoryController {

		constructor(
			private translation: Translation,
			private $rootScope: IRootScope,
			private TranslationService: TranslationService,
			private $mdDialog: ng.material.IDialogService) {
		}
		useHistory(history: DefinitionHistory) {
			this.translation.active_definition.text = history.text;
			this.TranslationService.saveDefinition(this.translation).then(() => {
				this.close();
			});
		}
		close() {
			this.$mdDialog.hide();
		}
	}
	angular.module("app").controller("DefinitionHistoryController", DefinitionHistoryController);
}