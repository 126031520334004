namespace app {
	export class LanguageDTO {
		_id: string;
		country: string;
		iso: string;
		language: string;
		flag_icon: string;
		create_time: number;
		update_time: number;
		delete_time: number;
	}
}