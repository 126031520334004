/// <reference path="../../core/base-data.service.ts" />

namespace app {
    export class ProjectService extends DataService<Project> {

        public resource: string = "projects";

        listByName() {
            let query = new Query();
            query.sort("name");
            return this.findByQuery(query);
        }

        getContributorProjects(contributor_id: string) {
            let defer = this.$q.defer();
            this.listByName().then(projects => {
                let filteredProjects = projects
                    .filter(project =>
                        project.contributors.some(contrib => contrib.user_id === contributor_id)
                    );
                defer.resolve(filteredProjects);
            });
            return defer.promise;
        }

        /** Get all  */
        getProjectTags(project_id: string) {
            let query = new Query();
            query.addCriteria(C.equals("_id", project_id)).distinct("translations.tags");
            return this.findByQuery(query);
        }
        /** Get all project definitions */
        getProjectDefinitions(project_id: string, language_id: string) {
            let endpoint = this.resource + "/" + project_id + "/" + language_id;
            return this.Restangular.all(endpoint).getList();
        }

        /** Notifies all contributors of a project about new changes  */
        notifyContributors(project_id: string) {
            let endpoint = "notify/" + project_id + "/translators";
            return this.Restangular.one(endpoint).customPOST();
        }

        notifyOwner(project_id: string, language_id: string) {
            let endpoint = "notify/" + project_id + "/owner";
            return this.Restangular.one(endpoint).customPOST({language_id : language_id});
        }

        /** Add a new contributor to a project */
        addContributorLanguage(project_id: string, user_id: string, language_id: string) {
            let endpoint = this.resource + "/" + project_id + "/users/" + user_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customPUT();
        }
        /** Remove a contributor from a project */
        removeContributorLanguage(project_id: string, user_id: string, language_id: string) {
            let endpoint = this.resource + "/" + project_id + "/users/" + user_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customDELETE("");
        }

        /** Add a new language to a project */
        addLanguage(project_id: string, language_id: string) {
            let endpoint = this.resource + "/" + project_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customPUT();
        }

        addLanguages(project_id: string, language_ids: string[]) {
            let promises: any[] = [];
            language_ids.forEach(id => {
                let endpoint = this.resource + "/" + project_id + "/languages/" + id;
                promises.push(this.Restangular.one(endpoint).customPUT());
            });
            return this.$q.all(promises);
        }

        /** Add a new language to a project */
        removeLanguage(project_id: string, language_id: string) {
            let endpoint = this.resource + "/" + project_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customDELETE("");
        }

        validateKeyCount() {
            return this.Restangular.one(this.resource + "/export/validate").get();
        }

        /** Import translation / definitions to a project in a given language */
        import(project_id: string, language_id: string, format: string, overwrite: boolean, replace: boolean, file: any) {
            // tslint:disable-next-line:max-line-length
            let endpoint = this.resource + "/" + project_id + "/language/" + language_id + "/import/" + format + "?token=" + this.localStorageService.get("token");
            // tslint:enable-next-line:max-line-length
            return this.Upload.upload({
                method: "POST",
                url: this.AppConstants.API_URL + endpoint,
                data: { overwrite, replace, file }
            });
        }

        getSupportedFormats(): IFormatObject[] {
            return [
                { id: "json", name: ".json", img: "json.png" },
                { id: "java", name: "Java .properties", img: "java.png" },
                { id: "php", name: ".php", img: "php.png" },
                { id: "android", name: "Android .xml", img: "android.png" },
                { id: "po", name: ".po", img: "po.png" },
                { id: "iosstring", name: "iOS .strings", img: "iosstrings.png" },
                { id: "msresx", name: "Microsoft .resx", img: "resx.png" },
                { id: "csv", name: "CSV .csv", img: "csv.png" },
                { id: "xliff", name: "iOS .XLIFF", img: "xliff.png" },
                { id: "epixml", name: "EpiServer XML", img: "epixml.png" },
                { id: "umbraco", name: "Umbraco XML", img: "umbraco.png" }
            ];
        }
    }
    angular.module("app").service("ProjectService", ProjectService);

    export interface IFormatObject {
        id: string;
        name: string;
        img: string;
    }
}