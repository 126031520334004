namespace app {
    export class ProjectListController {

        constructor(
            private LanguageService: LanguageService,
            private projects: Project[],
            private $state: ng.ui.IStateService,
            private $rootScope: IRootScope,
            private $mdDialog: any) {
                if (this.projects.length === 0)
                    this.addProject(undefined);
            }

        /**
         * Adds a new project
         */
        addProject(event) {
            this.$mdDialog.show({
                controller: AddProjectController,
                templateUrl: "src/features/projects/add-project-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: (LanguageService: LanguageService) => {
                        return this.LanguageService.findAll();
                    }
                }
            });
        }

        selectProject(project: Project) {
            this.$rootScope.blockui = true;
            let lang_id = project.primary_language;
            if (this.$rootScope.user && !this.$rootScope.user.admin) {
                lang_id = project.contributors
                    .filter(element => this.$rootScope.user!._id === element.user_id)
                    .map(element => element.language_id)[0];
            }
            this.$state.go("app.project.translation", {id : project._id, language_id: lang_id});
        }
    }
    angular.module("app").controller("ProjectListController", ProjectListController);
}