/// <reference path="../../core/base-data.service.ts" />

namespace app {
	export interface IFilter {
		text?: string;
		fuzzy?: boolean;
		unreviewed?: boolean;
		untranslated?: boolean;
		sortBy: string;
	}
	export interface IPagination {
		items_per_page: number;
		current_page: number;
		total_items: number;
		num_pages: number;
		append: boolean;
	}
	export class TranslationService extends DataService<Translation> {

		public resource: string = "translations";
		public isFiltering: boolean = false;
		public filter: IFilter = {
			sortBy: "reference_key"
		};
		public pagination: IPagination = {
			items_per_page: 500,
			current_page: 1,
			total_items: 10,
			num_pages: 1,
			append: false
		};

		initTranslation(translation: Translation) {
			let definitions = this.$filter("filter")(translation.definitions, { language_id: this.$stateParams.language_id });

			/** Set the active translation by the language */
			if (definitions && definitions.length)
				translation.active_definition = definitions[0];
			else {
				/** Nothing found so we create a new */
				let definition = new Definition();
				// definition._id = this.UtilityService.uid();
				definition.language_id = this.$stateParams.language_id;
				translation.definitions.push(definition);
				translation.active_definition = definition;
			}
		}

		resetFilter(): IFilter {
			this.filter = {
				sortBy: "reference_key"
			};
			return this.filter;
		}

		findTranslationCounts(project_id: string, language_id: string) {
			let query = new Query();
			query.addCriteria(C.and(
				C.equals("project_id", project_id),
				C.elementMatch("definitions", { language_id: language_id, text: { $ne: ""} })
			));
			return this.countByQuery(query);
		}
        /**
         * CACHING NOT WORKING VERY WELL
         */
		cacheNext(project_id: string, language_id: string) {
			return;
            /*if(this.translationCache.length > 0)
                return;
            this.findByFilter(project_id,language_id).then((response : Translation[]) => {
                this.translationCache = response;
            })*/
		}

		findByFilter(project_id: string, language_id: string) {
			// Return cache if it exists

			let query = new Query();

			query.addCriteria(C.equals("project_id", project_id));
			// filter by key
			if (this.filter.text && this.filter.text.length > 0 && language_id) {
				query.addCriteria(
					C.or(
						C.contains("primary_text", this.filter.text),
						C.elementMatch("definitions", { text: { $regex: this.filter.text, $options: "i" }, language_id: language_id }),
						C.contains("reference_key", this.filter.text)
					)
				);
			}

			// Filter by fuzzy
			if (angular.isDefined(this.filter.fuzzy) && this.filter.fuzzy === true) {
				query.addCriteria(C.elementMatch("definitions", { language_id: language_id, fuzzy: true }));
			}
			// Filter by reviewed
			if (angular.isDefined(this.filter.unreviewed) && this.filter.unreviewed === true) {
				query.addCriteria(C.elementMatch("definitions", { language_id: language_id, reviewed: false }));
			}
			// Filter by untranslated
			if (angular.isDefined(this.filter.untranslated) && this.filter.untranslated === true) {
				query.addCriteria(
					C.or(
						C.elementMatch("definitions", { language_id: language_id, text: { $eq: "" } }),
						C.notEquals("definitions.language_id", language_id)
					)
				);

			}

			// Apply pagination
			query.limit(this.pagination.items_per_page);
			query.skip((this.pagination.current_page - 1) * this.pagination.items_per_page);

			// Apply sorting
			query.sort(this.filter.sortBy);

			this.isFiltering = true;

			let defered = this.$q.defer();
			super.findByQuery(query).then((response) => {
				response.forEach((translation) => {
					this.initTranslation(translation);
				});
				this.isFiltering = false;
				return defered.resolve(response);
			}, (response) => {
				this.isFiltering = false;
				return defered.reject(response);
			});
			return defered.promise;
		}

		findSimilar(language_id: string, text: string, key_id?: string) {
			let query = new Query();
			query.limit(5);
			query.addCriteria(
				C.elementMatch("definitions", { text: { $regex: text, $options: "i" }, language_id: language_id })
			);
			if (key_id)
				query.addCriteria(C.notEquals("_id", key_id));
			query.populate(["project_id"]);
			let defer = this.$q.defer();
			this.findByQuery(query).then((response: Translation[]) => {
				response.forEach((translation) => {
					this.initTranslation(translation);
				});
				return defer.resolve(response);
			}, (response) => {
				return defer.reject(response);
			});
			return defer.promise;
		}

		save(translation: Translation) {
			translation = this.replaceActiveDefinition(translation);
			return super.save(translation);
		}

		getProjectTags(project_id: string) {
			let query = new Query();
			query.addCriteria(C.equals("project_id", project_id)).distinct("tags");
			return this.findByQuery(query);
		}

		saveComment(translation: Translation, comment: string) {
			return this.Restangular.one(this.resource + "/comment/", translation._id).customPUT({
				project_id: translation.project_id,
				language_id: translation.active_definition.language_id,
				comment: comment
			});
		}

		saveDefinition(translation: Translation): any {
			let defer = this.$q.defer();
			this.Restangular.one(this.resource + "/definition/", translation._id).customPUT({
				project_id: translation.project_id,
				definition: translation.active_definition
			})
				.then((response) => {
					translation.active_definition = response;
					defer.resolve(response);
				})
				.catch((response) => {
					defer.reject(response);
				});
			return defer.promise;
		}

		toggleReviewed(translation: Translation, ids: string[] = []) {
			return this.Restangular.one(this.resource + "/reviewed/", translation._id).customPUT({
				project_id: translation.project_id,
				language_id: translation.active_definition.language_id,
				reviewed: translation.active_definition.reviewed
			});
		}

		toggleFuzzy(translation: Translation, ids: string[] = []) {
			let payload: any = {
				project_id: translation.project_id,
				language_id: translation.active_definition.language_id,
				fuzzy: translation.active_definition.fuzzy
			};
			if (ids.length > 0) {
				payload.multi = true;
				payload.ids = ids;
			}
			return this.Restangular.one(this.resource + "/fuzzy/", translation._id).customPUT(payload);
		}
		toggleMulti(field: string, flag: boolean, language_id: string, ids: string[]) {
			let payload = {
				language_id: language_id,
				ids: ids
			};
			payload[field] = flag;
			return this.Restangular.one(this.resource + "/multi/batch").customPOST(payload);
		}

		isReferenceKeyDuplicate(reference_key: string, project_id: string) {
			let query = new Query();
			query.addCriteria(
				C.and(
					C.equals("reference_key", reference_key),
					C.equals("project_id", project_id)));
			let defered = this.$q.defer();
			super.findByQuery(query).then((response) => {
				if (response.length > 0)
					defered.reject("app.error.key.already.exists");
				else
					defered.resolve();
			});
			return defered.promise;
		}

		autoTranslate(id: string, text: string, srcId: string, targetId: string) {
			let params = {
				text: text,
				src_lang_id: srcId,
				target_lang_id: targetId
			};
			return this.Restangular.one(this.resource + "/auto", id).customPOST(params);
		}

		updateReferenceKey(_id: string, newReferenceKey: string) {
			return this.Restangular.one(this.resource + "/updatereferencekey", _id).customPUT({
                reference_key: newReferenceKey
            });
		}

		replaceActiveDefinition(translation: Translation): Translation {
			if (!translation.active_definition)
				return translation;

			let defIndex = translation.definitions.findIndex(def => def.language_id === translation.active_definition.language_id);
			translation.definitions[defIndex] = translation.active_definition;
			delete translation.active_definition;
			return translation;
		}
	}
	angular.module("app").service("TranslationService", TranslationService);
}