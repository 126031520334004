namespace app {
    export class AuthController {

        private form: ng.IFormController;

        constructor(
            private AuthService: AuthService,
            private $mdDialog: any) {}

        login(email: string, password: string, remember: boolean) {
            if (this.form.$invalid) {
                return;
            }
            this.AuthService.authenticate(email, password, remember);
        }
        showForgotPassword(event) {
            this.$mdDialog.show({
                controller: ForgotPasswordController,
                templateUrl: "src/features/authenticate/forgot-password-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true
            });
        }
    }
    angular.module("app").controller("AuthController", AuthController);
}