namespace app {
    export class ProjectImportController {

        public formats: IFormatObject[] = [];
        public importing: boolean = false;
        public format: IFormatObject;
        public language: string;
        public isMultipleLanguageImport: boolean;

        constructor(
            private ProjectService: ProjectService,
            private project: Project,
            private TrackingService: TrackingService,
            private $mdDialog: ng.material.IDialogService,
            private Upload: ng.angularFileUpload.IUploadService,
            private localStorageService: angular.local.storage.ILocalStorageService,
            private $state: ng.ui.IStateService,
            private ToasterService: ToasterService) {

            this.formats = this.ProjectService.getSupportedFormats().filter(f => f.id !== "php");
            this.format = this.formats[0];
        }

        onFormatChange(format: IFormatObject) {
            if (format.id === "umbraco") {
                this.language = this.project.primary_language._id;
                this.isMultipleLanguageImport = true;
            }
            else {
                this.language = "";
                this.isMultipleLanguageImport = false;
            }
        }

        isPrimaryLanguage() {
            return this.language === this.project.primary_language._id;
        }

        getLanguageName() {
            const lang = this.project.languages.find(l => l._id === this.language);
            if (lang) {
                return lang.name;
            }
        }

        import(project_id, language_id: string, format: string, file: any, overwrite: boolean = false, replace: boolean = false) {
            if (!this.isPrimaryLanguage()) {
                replace = false;
            }
            this.importing = true;
            this.ProjectService.import(project_id, language_id, format, overwrite, replace, file).then((res) => {
                this.TrackingService.trackImport({
                    project: this.project.name,
                    format,
                    language: this.getLanguageName()
                });
                this.close();
                this.ToasterService.pop("app.import.finished");
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }).catch(err => {
                this.ToasterService.pop(err.data.message);
            }).finally(() => {
                this.importing = false;
            });
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("ProjectImportController", ProjectImportController);
}