var app;
(function (app) {
    var DataService = (function () {
        /** @ngInject */
        function DataService(Restangular, AppConstants, UtilityService, Upload, localStorageService, $rootScope, $q, $timeout, $stateParams, $filter) {
            this.Restangular = Restangular;
            this.AppConstants = AppConstants;
            this.UtilityService = UtilityService;
            this.Upload = Upload;
            this.localStorageService = localStorageService;
            this.$rootScope = $rootScope;
            this.$q = $q;
            this.$timeout = $timeout;
            this.$stateParams = $stateParams;
            this.$filter = $filter;
        }
        DataService.prototype.save = function (entity) {
            this.$rootScope.dataloading = true;
            if (entity._id)
                return this.Restangular.one(this.resource, entity._id).customPUT(entity);
            else
                return this.Restangular.one(this.resource).customPOST(entity);
        };
        DataService.prototype.findAll = function () {
            return this.Restangular.all(this.resource).getList();
        };
        DataService.prototype.findById = function (id, query) {
            this.$rootScope.dataloading = true;
            var endpoint = this.resource;
            if (query)
                return this.Restangular.one(endpoint, id).get(query.toFilter());
            else
                return this.Restangular.one(endpoint, id).get();
        };
        DataService.prototype.findByQuery = function (query) {
            this.$rootScope.dataloading = true;
            var endpoint = this.resource;
            return this.Restangular.all(endpoint).getList(query.toFilter());
        };
        DataService.prototype.countByQuery = function (query) {
            this.$rootScope.dataloading = true;
            var endpoint = this.resource + "/count";
            return this.Restangular.one(endpoint).get(query.toFilter());
        };
        DataService.prototype.delete = function (entity) {
            return this.Restangular.one(this.resource, entity._id).remove();
        };
        DataService.prototype.saveAll = function (entities) {
            var _this = this;
            this.$rootScope.dataloading = true;
            var promises = [];
            entities.forEach(function (entity) {
                promises.push(_this.save(entity));
            });
            return this.$q.all(promises);
        };
        return DataService;
    }());
    app.DataService = DataService;
})(app || (app = {}));
var app;
(function (app) {
    /**
     * Executes in the configurations phase of the angular application
     */
    var AppConfigs = (function () {
        /*@ngInject*/
        function AppConfigs($provide, $translateProvider, $locationProvider, RestangularProvider, $mdThemingProvider, $mdIconProvider, stripeProvider) {
            $translateProvider
                .useStaticFilesLoader({
                prefix: "/assets/i18n/",
                suffix: ".json"
            })
                .preferredLanguage(app.AppConstants.DEFAULT_LANGUAGE)
                .fallbackLanguage(app.AppConstants.DEFAULT_LANGUAGE)
                .useLocalStorage();
            // Configure restangular with base url
            RestangularProvider.setBaseUrl(app.AppConstants.API_URL);
            $translateProvider.useMissingTranslationHandler("outputMissingKeysHandler");
            // Set html5 mode
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            }).hashPrefix("!");
            // Stripe
            stripeProvider.setPublishableKey(app.AppConstants.STRIPE_PUB_KEY);
            // Theming
            $mdThemingProvider
                .theme("default")
                .primaryPalette("blue-grey")
                .accentPalette("teal", {
                default: "500" // use shade 200 for default, and keep all other shades the same
            })
                .warnPalette("red", {
                default: "500"
            });
            // Icons
            $mdIconProvider
                .iconSet("social", "img/icons/sets/social-icons.svg", 24)
                .defaultIconSet("img/icons/sets/core-icons.svg", 24);
            // Exception handling
            $provide.decorator("$exceptionHandler", function ($delegate, $log) {
                return function (exception, cause, source) {
                    $delegate(exception, cause);
                    $log.error(exception.message, { exception: exception, cause: cause }, source, "error");
                };
            });
        }
        return AppConfigs;
    }());
    app.AppConfigs = AppConfigs;
})(app || (app = {}));
var app;
(function (app) {
    var AppConstants = (function () {
        function AppConstants() {
        }
        Object.defineProperty(AppConstants, "API_URL", {
            get: function () { return "/api/v1/"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_COMMENT_POSTED", {
            get: function () { return "EVENT_PROJECT_COMMENT_POSTED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_DEFINITION_ADDED", {
            get: function () { return "EVENT_PROJECT_DEFINITION_ADDED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_TRANSLATION_ADDED", {
            get: function () { return "EVENT_PROJECT_TRANSLATION_ADDED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_TRANSLATION_DELETED", {
            get: function () { return "EVENT_PROJECT_TRANSLATION_DELETED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_LANGUAGE_ADDED", {
            get: function () { return "EVENT_PROJECT_LANGUAGE_ADDED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_LANGUAGE_DELETED", {
            get: function () { return "EVENT_PROJECT_LANGUAGE_DELETED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_CONTRIBUTOR_ADDED", {
            get: function () { return "EVENT_PROJECT_CONTRIBUTOR_ADDED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_CONTRIBUTOR_DELETED", {
            get: function () { return "EVENT_PROJECT_CONTRIBUTOR_DELETED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_TOGGLE_FUZZY", {
            get: function () { return "EVENT_PROJECT_TOGGLE_FUZZY"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_TOGGLE_REVIEWED", {
            get: function () { return "EVENT_PROJECT_TOGGLE_REVIEWED"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_IMPORT", {
            get: function () { return "EVENT_PROJECT_IMPORT"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_EXPORT", {
            get: function () { return "EVENT_PROJECT_EXPORT"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_NOTIFY", {
            get: function () { return "EVENT_PROJECT_NOTIFY"; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AppConstants, "EVENT_PROJECT_CREATED", {
            get: function () { return "EVENT_PROJECT_CREATED"; },
            enumerable: true,
            configurable: true
        });
        return AppConstants;
    }());
    AppConstants.DEFAULT_LANGUAGE = "en_US";
    AppConstants.STRIPE_PUB_KEY = "pk_live_hoqNp4Fhiq4uoXoscqNdfBQs";
    app.AppConstants = AppConstants;
})(app || (app = {}));
var app;
(function (app) {
    var AppController = (function () {
        /*@ngInject*/
        function AppController($translate, $mdDialog, localStorageService, $rootScope, $state) {
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
            this.localStorageService = localStorageService;
            this.$rootScope = $rootScope;
            this.$state = $state;
            this.user = $rootScope.user;
        }
        AppController.prototype.getUser = function () {
            return this.localStorageService.get("user");
        };
        AppController.prototype.logout = function () {
            this.localStorageService.clearAll();
            this.user = undefined;
            this.$rootScope.user = undefined;
            this.$state.go("pages.login");
        };
        AppController.prototype.openFeedbackModal = function () {
            console.log("FEEDBACK");
            this.$mdDialog.show({
                controller: app.FeedbackController,
                templateUrl: "src/features/feedback/feedback.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true
            });
        };
        return AppController;
    }());
    app.AppController = AppController;
})(app || (app = {}));
var app;
(function (app) {
    var AppRoutes = (function () {
        /*@ngInject*/
        function AppRoutes($stateProvider, $urlRouterProvider) {
            this.$stateProvider = $stateProvider;
            this.$urlRouterProvider = $urlRouterProvider;
            $urlRouterProvider.otherwise(function ($injector) {
                var $state = $injector.get("$state");
                $state.go("pages.login");
            });
            $stateProvider
                .state("pages", {
                abstract: true,
                url: "/pages",
                templateUrl: "src/features/authenticate/page.html",
            })
                .state("pages.login", {
                url: "/login",
                templateUrl: "src/features/authenticate/login.html",
                controller: app.AuthController,
                controllerAs: "vm"
            })
                .state("pages.logout", {
                url: "/logout",
                controller: app.LogoutController
            })
                .state("pages.invite", {
                url: "/invite/confirm",
                templateUrl: "src/features/authenticate/invite.html",
                controller: app.InviteController,
                controllerAs: "vm"
            })
                .state("pages.reset", {
                url: "/reset_password",
                templateUrl: "src/features/users/reset-password.html",
                controller: app.ResetPasswordController,
                controllerAs: "vm"
            })
                .state("pages.signup", {
                url: "/signup",
                templateUrl: "src/features/users/signup.html",
                controller: app.SignupController,
                controllerAs: "vm",
                data: {
                    blockui: true
                }
            })
                .state("pages.signupcomplete", {
                url: "/signupcomplete",
                template: "<div></div>",
                controller: function () { }
            })
                .state("app", {
                abstract: true,
                url: "/app",
                templateUrl: "src/app.html",
                resolve: {
                    languages: function (LanguageService) {
                        return LanguageService.findAll();
                    }
                }
            })
                .state("app.account", {
                url: "/account",
                templateUrl: "src/features/accounts/account.html",
                controller: "AccountController",
                controllerAs: "vm",
                data: {
                    blockui: true
                },
                resolve: {
                    account: function (AccountService) {
                        var query = new app.Query();
                        query.populate(["license_plan"]);
                        return AccountService.findById("self", query);
                    },
                    subscription: function (AccountService) {
                        return AccountService.getSubscription();
                    }
                },
            })
                .state("app.account.profile", {
                url: "/profile",
                templateUrl: "src/features/accounts/profile.html",
                controller: "ProfileController",
                controllerAs: "vm",
                data: {
                    blockui: true
                },
                resolve: {
                    user: function (UserService) {
                        return UserService.getCurrentUser();
                    }
                }
            })
                .state("app.account.subscription", {
                url: "/subscription",
                data: {
                    requireLogin: true
                },
                templateUrl: "src/features/accounts/account-subscription-details.html",
                controller: "AccountSubscriptionController",
                controllerAs: "vm",
                resolve: {
                    license_plans: function (LicensePlanService) {
                        return LicensePlanService.findAll();
                    },
                    current_card: function (AccountService) {
                        return AccountService.getCurrentCardInfo();
                    }
                }
            })
                .state("app.account.invoices", {
                url: "/invoices",
                data: {
                    requireLogin: true
                },
                templateUrl: "src/features/accounts/account-invoice-list.html",
                controller: "AccountInvoiceListController",
                controllerAs: "vm",
                resolve: {
                    invoices: function (AccountService) {
                        return AccountService.getInvoices();
                    },
                    nextInvoice: function (AccountService) {
                        return AccountService.getNextInvoice();
                    }
                }
            })
                .state("app.license", {
                url: "/license",
                data: {
                    requireLogin: true
                },
                templateUrl: "src/features/licenses/license-plan.html",
                controller: "LicensePlanController",
                controllerAs: "vm",
                resolve: {
                    account: function (AccountService) {
                        var query = new app.Query();
                        query.populate(["license_plan"]);
                        return AccountService.findById("self", query);
                    },
                    license_plans: function (LicensePlanService) {
                        return LicensePlanService.findAll();
                    }
                }
            })
                .state("app.contributors", {
                url: "/contributors",
                templateUrl: "src/features/users/contributor-list.html",
                controller: "ContributorListController",
                controllerAs: "vm",
                data: {
                    blockui: true
                },
                resolve: {
                    users: function (UserService) {
                        var query = new app.Query();
                        query.sort("username");
                        return UserService.findByQuery(query);
                    }
                }
            })
                .state("app.projects", {
                url: "/projects",
                templateUrl: "src/features/projects/project-list.html",
                controller: "ProjectListController",
                controllerAs: "vm",
                data: {
                    blockui: true
                },
                resolve: {
                    projects: function (ProjectService) {
                        return ProjectService.listByName();
                    }
                }
            })
                .state("app.project", {
                url: "/project/:id",
                templateUrl: "src/features/projects/project.html",
                controller: "ProjectController",
                controllerAs: "vm",
                data: {
                    blockui: true
                },
                resolve: {
                    project: function (ProjectService, $stateParams) {
                        if ($stateParams.id) {
                            var query = new app.Query();
                            query.populate(["languages", "primary_language"]);
                            return ProjectService.findById($stateParams.id, query);
                        }
                    },
                    projectTags: function (TranslationService, $stateParams) {
                        if ($stateParams.id)
                            return TranslationService.getProjectTags($stateParams.id);
                    },
                    contributors: function (UserService) {
                        return UserService.findContributors();
                    },
                    projects: function (ProjectService) {
                        var query = new app.Query();
                        query.select(["name"]);
                        return ProjectService.findByQuery(query);
                    },
                    translationCount: function (TranslationService, $stateParams) {
                        var query = new app.Query();
                        query.addCriteria(app.C.equals("project_id", $stateParams.id));
                        return TranslationService.countByQuery(query);
                    }
                }
            })
                .state("app.project.translation", {
                url: "/:language_id",
                templateUrl: "src/features/translations/translation.html",
                controller: "TranslationController",
                controllerAs: "vm",
                data: {
                    blockui: false
                },
                resolve: {
                    language: function (LanguageService, $stateParams) {
                        return LanguageService.findById($stateParams["language_id"]);
                    },
                    translations: function (TranslationService, $stateParams) {
                        return TranslationService.findByFilter($stateParams.id, $stateParams.language_id);
                    },
                    definitionCount: function (TranslationService, $stateParams) {
                        return TranslationService.findTranslationCounts($stateParams.id, $stateParams.language_id);
                    },
                    translationCount: function (TranslationService, $stateParams) {
                        var query = new app.Query();
                        query.addCriteria(app.C.equals("project_id", $stateParams.id));
                        return TranslationService.countByQuery(query);
                    }
                }
            })
                .state("app.languages", {
                url: "/languages",
                templateUrl: "src/features/languages/language-list.html",
                controller: "LanguageListController",
                controllerAs: "vm"
            })
                .state("app.language", {
                url: "/languages/:id",
                templateUrl: "src/features/languages/language.html",
                controller: "LanguageController",
                controllerAs: "vm",
                resolve: {
                    language: function (LanguageService, $stateParams) {
                        if (AppRoutes.hasId($stateParams))
                            return LanguageService.findById($stateParams["id"]);
                        return null;
                    }
                }
            });
        }
        AppRoutes.hasId = function ($stateParams) {
            if ($stateParams["id"] && $stateParams["id"] !== "")
                return true;
            return false;
        };
        return AppRoutes;
    }());
    app.AppRoutes = AppRoutes;
})(app || (app = {}));
var app;
(function (app) {
    /**
     * Executes in the runs phase of the angular application
     */
    var AppRun = (function () {
        /*@ngInject*/
        function AppRun($window, $rootScope, $state, $location, $timeout, localStorageService, Restangular, AuthService, $mdToast, ToasterService, $translate) {
            $window.ga("create", "UA-96473888-1", "auto");
            // Show loader initially
            $rootScope.blockui = false;
            // Handle toast on response
            Restangular.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
                $rootScope.dataloading = false;
                return data;
            });
            $rootScope.stateLoading = {};
            $rootScope.year = new Date().getFullYear();
            // Catch API erorrs
            Restangular.setErrorInterceptor(function (response) {
                if (response.status === 401) {
                    $mdToast.show($mdToast.simple().textContent("Unauthorized"));
                    localStorageService.clearAll();
                    $state.go("pages.login");
                }
                else if (response.status === 403) {
                    ToasterService.pop(response.data.message, 10000);
                    $state.go("app.account.subscription");
                    return response;
                }
                else if (response.status === 404) {
                    $mdToast.show($mdToast.simple().textContent("Resource not found"));
                    console.error("Resource not available...");
                    $rootScope.blockui = false;
                }
                else if (response.status === 400 || response.status === 500) {
                    console.log(response.data);
                    if (response.data.message)
                        ToasterService.pop(response.data.message);
                    if (response.data.errmsg)
                        ToasterService.pop(response.data.errmsg);
                    $rootScope.blockui = false;
                }
                return false; // stop the promise chain
            });
            Restangular.addFullRequestInterceptor(function (element, operation, what, url, headers, params, httpConfig) {
                $rootScope.dataloading = true;
                return element;
            });
            // Set token if it is localstorage
            var token = localStorageService.get("token");
            if (token) {
                Restangular.setDefaultHeaders({ "x-access-token": token });
            }
            // Set language if it is in localstorage
            var lang = localStorageService.get("site.language");
            $rootScope.currentLanguage = (lang) ? lang : "en_US";
            $rootScope.changeLanguage = function (code) {
                $translate.use(code);
                $rootScope.currentLanguage = code;
                localStorageService.set("site.language", code);
            };
            /**
             * Handles when we get authorized
             * Note that this does not mean we are done bootstrapping the application
             */
            $rootScope.$on("authorized", function (event, data) {
                $rootScope.authorized = true;
                $state.go("app.projects");
            });
            /**
             * Handles unauthorized sessions
             */
            $rootScope.$on("unauthorized", function (event, data) {
                $rootScope.bootstrapped = true;
                $rootScope.authorized = false;
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                ToasterService.pop("Unauthorized");
                $state.go("pages.login");
            });
            /**
             * BlockUI when changing state
             */
            $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
                $rootScope.stateLoading = toState.name;
                if (toState.data && toState.data.blockui)
                    $rootScope.blockui = true;
                // if(toState.data && toState.data.requireLogin);
                //  return;
            });
            /**
             * unblock UI when changing state is done
             */
            $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) {
                $window.ga("send", "pageview", $location.path());
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                $rootScope.stateName = toState.name;
            });
            /**
             * unblock UI when state is not found
             */
            $rootScope.$on("$stateNotFound", function (event, unfoundState, fromState, fromParams) {
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                console.log("stateNotFound", event);
            });
            /**
             * unblock UI when state error occurs
             */
            $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                console.log("stateError", error);
            });
            // Handle special invites route
            if ($location.path() === "/pages/invite/confirm") {
                $rootScope.inviteToken = $location.search().token;
                $rootScope.inviteEmail = $location.search().email;
                $state.go("pages.invite");
            }
            else if ($location.path() === "/pages/reset_password") {
                $rootScope.resetToken = $location.search().token;
                $state.go("pages.reset");
            }
            else if ($location.path() === "/pages/signup") {
                return;
            }
            else if ($location.path() === "/pages/login") {
                return;
            }
            else if ($location.path() === "/") {
                if (AuthService.isAuthenticated())
                    $timeout(function () { return $state.go("app.projects"); }, 10);
            }
            else {
                if (!AuthService.isAuthenticated()) {
                    $state.go("pages.login");
                }
            }
        }
        return AppRun;
    }());
    app.AppRun = AppRun;
})(app || (app = {}));
var app;
(function (app) {
    angular.module("app", [
        "app.views",
        "restangular",
        "LocalStorageModule",
        "ngMaterial",
        "ngMessages",
        "cfp.hotkeys",
        "ngAnimate",
        "ngCookies",
        "pascalprecht.translate",
        "ui.router",
        "ngSanitize",
        "sly",
        "angular-stripe",
        "credit-cards",
        "ngFileUpload"
    ])
        .constant("AppConstants", app.AppConstants)
        .config(app.AppRoutes)
        .config(app.AppConfigs)
        .run(app.AppRun)
        .controller("AppController", app.AppController)
        .factory("outputMissingKeysHandler", function () {
        // This is for handling missing keys - making it easy to add to the language sheet
        var missingKeys = [];
        window.printMissingTranslationKeys = function () {
            console.info(missingKeys.sort().join("\n"));
        };
        // Function is called whenever a translation cannot be found
        return function (translationID) {
            if (missingKeys.indexOf(translationID) < 0 && translationID.indexOf(".") >= 0) {
                if (missingKeys.length === 0) {
                    // tslint:disable-next-line:max-line-length
                    console.log("%c%s", "color: #f05050; background: #edf1f2;padding:0 2em;font-size:1.2em;", "Found missing translation keys! Call printMissingTranslationKeys() to view.");
                }
                missingKeys.push(translationID);
            }
        };
    });
    // Bootstrap the application manually
    angular.element(document).ready(function () {
        angular.bootstrap(document, ["app"]);
    });
})(app || (app = {}));
var app;
(function (app) {
    var AccountInvoiceListController = (function () {
        function AccountInvoiceListController(invoices) {
            this.invoices = invoices;
        }
        return AccountInvoiceListController;
    }());
    angular.module("app").controller("AccountInvoiceListController", AccountInvoiceListController);
})(app || (app = {}));
var app;
(function (app) {
    var AccountSubscriptionController = (function () {
        function AccountSubscriptionController(AccountService, ToasterService, account, license_plans, current_card, subscription, $mdDialog, $scope) {
            var _this = this;
            this.AccountService = AccountService;
            this.ToasterService = ToasterService;
            this.account = account;
            this.license_plans = license_plans;
            this.current_card = current_card;
            this.subscription = subscription;
            this.$mdDialog = $mdDialog;
            this.$scope = $scope;
            $scope.$on("card_added", function (event, data) {
                _this.current_card = data;
            });
        }
        AccountSubscriptionController.prototype.removeCard = function () {
            var _this = this;
            this.removingCard = true;
            this.AccountService.deleteCurrentCard().then(function (response) {
                _this.current_card = {};
                _this.account.card_token = null;
                _this.account.card_id = null;
                _this.ToasterService.pop("app.removed.card.successful");
            })
                .catch(function () {
                _this.ToasterService.pop("app.removed.card.failure");
            })
                .finally(function () {
                _this.removingCard = false;
            });
        };
        AccountSubscriptionController.prototype.showAddCreditCard = function (event, current_card) {
            this.$mdDialog.show({
                controller: app.AddCreditCardController,
                templateUrl: "src/features/accounts/add-creditcard-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                bindToController: true,
                clickOutsideToClose: true
            });
        };
        AccountSubscriptionController.prototype.changePlan = function (license) {
            var _this = this;
            if (this.account.license_plan["_id"] === license._id || !license._id)
                this.ToasterService.pop("app.license.already.current");
            this.AccountService.changePlan(license._id).then(function (response) {
                _this.account.license_plan = response;
            });
        };
        return AccountSubscriptionController;
    }());
    angular.module("app").controller("AccountSubscriptionController", AccountSubscriptionController);
})(app || (app = {}));
var app;
(function (app) {
    var AccountController = (function () {
        function AccountController(AccountService, ProjectService, UserService, account, $mdDialog) {
            this.AccountService = AccountService;
            this.ProjectService = ProjectService;
            this.UserService = UserService;
            this.account = account;
            this.$mdDialog = $mdDialog;
        }
        return AccountController;
    }());
    app.AccountController = AccountController;
    angular.module("app").controller("AccountController", AccountController);
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var app;
(function (app) {
    var AccountService = (function (_super) {
        __extends(AccountService, _super);
        function AccountService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "accounts";
            return _this;
        }
        AccountService.prototype.getNextInvoice = function () {
            return this.Restangular.one(this.resource + "/invoices/next").get();
        };
        AccountService.prototype.getInvoices = function () {
            return this.Restangular.all(this.resource + "/invoices/list").getList();
        };
        AccountService.prototype.getSubscription = function () {
            return this.Restangular.one(this.resource + "/subscription/plan").get();
        };
        AccountService.prototype.changePlan = function (id) {
            return this.Restangular.one(this.resource + "/subscription/plan").customPUT({ license_plan: id });
        };
        AccountService.prototype.addCard = function (token, card_id) {
            return this.Restangular.one(this.resource + "/subscription/card").customPOST({ card_token: token, card_id: card_id });
        };
        AccountService.prototype.getCurrentCardInfo = function () {
            return this.Restangular.one(this.resource + "/subscription/card").get();
        };
        AccountService.prototype.deleteCurrentCard = function () {
            return this.Restangular.one(this.resource).customDELETE("subscription/card");
        };
        return AccountService;
    }(app.DataService));
    app.AccountService = AccountService;
    angular.module("app").service("AccountService", AccountService);
})(app || (app = {}));
var app;
(function (app) {
    var AddCreditCardController = (function () {
        function AddCreditCardController(AccountService, ToasterService, stripe, $mdDialog, $rootScope) {
            this.AccountService = AccountService;
            this.ToasterService = ToasterService;
            this.stripe = stripe;
            this.$mdDialog = $mdDialog;
            this.$rootScope = $rootScope;
            this.cardTypes = ["Visa", "Visa (debit)", "American Express", "MasterCard", "Discover", "Diners Club", "JCB"];
            this.years = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
            this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            this.addingcard = false;
        }
        AddCreditCardController.prototype.addCard = function (card) {
            var _this = this;
            this.addingcard = true;
            var copy = angular.copy(card);
            this.stripe.card.createToken(copy).then(function (response) {
                return _this.AccountService.addCard(response.id, response.card.id);
            }).then(function (resCard) {
                _this.$rootScope.$broadcast("card_added", resCard.data);
                _this.ToasterService.pop("app.added.card.successful");
                _this.close();
            }).catch(function () {
                _this.ToasterService.pop("app.added.card.failure");
            }).finally(function () {
                _this.addingcard = false;
            });
        };
        AddCreditCardController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return AddCreditCardController;
    }());
    app.AddCreditCardController = AddCreditCardController;
    angular.module("app").controller("AddCreditCardController", AddCreditCardController);
})(app || (app = {}));
var app;
(function (app) {
    var ProfileController = (function () {
        function ProfileController(UserService, user, ToasterService) {
            this.UserService = UserService;
            this.user = user;
            this.ToasterService = ToasterService;
        }
        ProfileController.prototype.changePassword = function (password, confirm) {
            var _this = this;
            if (password !== confirm)
                return;
            this.UserService.updatePassword(this.user, password).then(function (response) {
                _this.ToasterService.pop("Your password has been updated!");
            });
        };
        return ProfileController;
    }());
    app.ProfileController = ProfileController;
    angular.module("app").controller("ProfileController", ProfileController);
})(app || (app = {}));
var app;
(function (app) {
    var AuthController = (function () {
        function AuthController(AuthService, $mdDialog) {
            this.AuthService = AuthService;
            this.$mdDialog = $mdDialog;
        }
        AuthController.prototype.login = function (email, password, remember) {
            if (this.form.$invalid) {
                return;
            }
            this.AuthService.authenticate(email, password, remember);
        };
        AuthController.prototype.showForgotPassword = function (event) {
            this.$mdDialog.show({
                controller: app.ForgotPasswordController,
                templateUrl: "src/features/authenticate/forgot-password-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true
            });
        };
        return AuthController;
    }());
    app.AuthController = AuthController;
    angular.module("app").controller("AuthController", AuthController);
})(app || (app = {}));
var app;
(function (app) {
    var AuthService = (function () {
        function AuthService($http, $rootScope, TrackingService, localStorageService, Restangular, $state) {
            this.$http = $http;
            this.$rootScope = $rootScope;
            this.TrackingService = TrackingService;
            this.localStorageService = localStorageService;
            this.Restangular = Restangular;
            this.$state = $state;
        }
        AuthService.prototype.logout = function () {
            this.localStorageService.clearAll();
        };
        AuthService.prototype.authenticate = function (email, password, remember) {
            var _this = this;
            if (remember === void 0) { remember = true; }
            this.$rootScope.authenticating = true;
            return this.$http.post("api/v1/authenticate", { email: email, password: password }).then(function (response) {
                _this.handleRemember(remember);
                _this.setToken(response.data.token);
                _this.setUser(response.data.user);
                _this.setDefaultHeader(response.data.token);
                _this.$rootScope.$broadcast("authorized");
                _this.$rootScope.authenticating = false;
                _this.TrackingService.trackLogin({ email: email });
            }, function (response) {
                _this.$rootScope.$broadcast("unauthorized");
                _this.$rootScope.authenticating = false;
            });
        };
        AuthService.prototype.handleRemember = function (remember) {
            (remember) ? this.localStorageService.set("remember", true) : this.localStorageService.remove("remember");
        };
        AuthService.prototype.confirmInvite = function (token, password, repassword) {
            var _this = this;
            this.$http.post("api/v1/confirm_invite", {
                token: token,
                password: password,
                repassword: repassword
            }).then(function (response) {
                _this.authenticate(response.data.user.email, password);
            });
        };
        AuthService.prototype.isAuthenticated = function () {
            var token = this.localStorageService.get("token");
            if (token) {
                this.setDefaultHeader(token);
                this.setUserOnRootScope();
                return true;
            }
            return false;
        };
        AuthService.prototype.setToken = function (token) {
            this.localStorageService.set("token", token);
        };
        AuthService.prototype.setUser = function (user) {
            this.localStorageService.set("user", user);
            this.setUserOnRootScope(user);
        };
        AuthService.prototype.setDefaultHeader = function (token) {
            this.Restangular.setDefaultHeaders({ "x-access-token": token });
        };
        AuthService.prototype.setUserOnRootScope = function (user) {
            if (!user)
                user = this.localStorageService.get("user");
            this.$rootScope.user = user;
        };
        return AuthService;
    }());
    app.AuthService = AuthService;
    angular.module("app").service("AuthService", AuthService);
})(app || (app = {}));
var app;
(function (app) {
    var ForgotPasswordController = (function () {
        function ForgotPasswordController(UserService, $mdDialog, ToasterService) {
            this.UserService = UserService;
            this.$mdDialog = $mdDialog;
            this.ToasterService = ToasterService;
            this.email_not_exists = false;
            this.saving = false;
        }
        ForgotPasswordController.prototype.resetPassword = function (email) {
            var _this = this;
            this.email_not_exists = false;
            this.saving = true;
            this.UserService.requestResetPassword(email).then(function (x) {
                if (x.success === false)
                    return _this.email_not_exists = true;
                _this.close();
                _this.ToasterService.pop("app.email.reset.mail.sent");
            }).finally(function () {
                _this.saving = false;
            });
        };
        ForgotPasswordController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return ForgotPasswordController;
    }());
    app.ForgotPasswordController = ForgotPasswordController;
    angular.module("app").controller("ForgotPasswordController", ForgotPasswordController);
})(app || (app = {}));
var app;
(function (app) {
    var InviteController = (function () {
        function InviteController($mdToast, $rootScope, AuthService) {
            this.$mdToast = $mdToast;
            this.$rootScope = $rootScope;
            this.AuthService = AuthService;
            this.email = this.$rootScope.inviteEmail;
        }
        InviteController.prototype.createUser = function (password, repassword) {
            var token = this.$rootScope.inviteToken;
            if (!token)
                return this.$mdToast.show(this.$mdToast.simple().textContent("Upss something went wrong"));
            if (this.form.$invalid)
                return;
            this.AuthService.confirmInvite(token, password, repassword);
        };
        return InviteController;
    }());
    app.InviteController = InviteController;
    angular.module("app").controller("InviteController", InviteController);
})(app || (app = {}));
var app;
(function (app) {
    var LogoutController = (function () {
        function LogoutController(AuthService, $state) {
            this.AuthService = AuthService;
            this.$state = $state;
            AuthService.logout();
            this.$state.go("pages.login");
        }
        return LogoutController;
    }());
    app.LogoutController = LogoutController;
    angular.module("app").controller("LogoutController", LogoutController);
})(app || (app = {}));
var app;
(function (app) {
    var LanguageListController = (function () {
        function LanguageListController(LanguageService, languages) {
            this.LanguageService = LanguageService;
            this.languages = languages;
        }
        LanguageListController.prototype.remove = function (language) {
            var _this = this;
            this.LanguageService.delete(language).then(function () {
                _this.languages.splice(_this.languages.indexOf(language), 1);
            });
        };
        return LanguageListController;
    }());
    app.LanguageListController = LanguageListController;
    angular.module("app").controller("LanguageListController", LanguageListController);
})(app || (app = {}));
var app;
(function (app) {
    var LanguageController = (function () {
        function LanguageController(LanguageService, $state, language, Restangular) {
            this.LanguageService = LanguageService;
            this.$state = $state;
            this.language = language;
            this.Restangular = Restangular;
        }
        LanguageController.prototype.save = function (language) {
            var _this = this;
            this.LanguageService.save(language).then(function () {
                _this.$state.go("app.languages");
            });
        };
        LanguageController.prototype.remove = function (language) {
            var _this = this;
            this.LanguageService.delete(language).then(function (response) {
                _this.$state.go("app.languages");
            });
        };
        return LanguageController;
    }());
    app.LanguageController = LanguageController;
    angular.module("app").controller("LanguageController", LanguageController);
})(app || (app = {}));
var app;
(function (app) {
    var LanguageDTO = (function () {
        function LanguageDTO() {
        }
        return LanguageDTO;
    }());
    app.LanguageDTO = LanguageDTO;
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var app;
(function (app) {
    var LanguageService = (function (_super) {
        __extends(LanguageService, _super);
        function LanguageService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "languages";
            return _this;
        }
        return LanguageService;
    }(app.DataService));
    app.LanguageService = LanguageService;
    angular.module("app").service("LanguageService", LanguageService);
})(app || (app = {}));
var app;
(function (app) {
    var FeedbackController = (function () {
        /** @ngInject */
        function FeedbackController(FeedbackService, $state, $mdDialog) {
            this.FeedbackService = FeedbackService;
            this.$state = $state;
            this.$mdDialog = $mdDialog;
            this.saving = false;
        }
        FeedbackController.prototype.submit = function () {
            var _this = this;
            this.saving = true;
            this.FeedbackService.sendFeedback(this.subject, this.description).then(function () {
                _this.saving = false;
                _this.close();
            });
        };
        FeedbackController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return FeedbackController;
    }());
    app.FeedbackController = FeedbackController;
    angular.module("app").controller("LanguageController", app.LanguageController);
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var app;
(function (app) {
    var FeedbackService = (function (_super) {
        __extends(FeedbackService, _super);
        function FeedbackService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "feedback";
            return _this;
        }
        FeedbackService.prototype.sendFeedback = function (subject, description) {
            return this.save({ subject: subject, description: description });
        };
        return FeedbackService;
    }(app.DataService));
    app.FeedbackService = FeedbackService;
    angular.module("app").service("FeedbackService", FeedbackService);
})(app || (app = {}));
var app;
(function (app) {
    var LicensePlanController = (function () {
        function LicensePlanController(AccountService, LicensePlanService, account, license_plans) {
            this.AccountService = AccountService;
            this.LicensePlanService = LicensePlanService;
            this.account = account;
            this.license_plans = license_plans;
        }
        return LicensePlanController;
    }());
    app.LicensePlanController = LicensePlanController;
    angular.module("app").controller("LicensePlanController", LicensePlanController);
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var app;
(function (app) {
    var LicensePlanService = (function (_super) {
        __extends(LicensePlanService, _super);
        function LicensePlanService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "license_plans";
            return _this;
        }
        return LicensePlanService;
    }(app.DataService));
    app.LicensePlanService = LicensePlanService;
    angular.module("app").service("LicensePlanService", LicensePlanService);
})(app || (app = {}));
var app;
(function (app) {
    var AddContributorController = (function () {
        function AddContributorController(UserService, ProjectService, ToasterService, users, project, $mdDialog, $rootScope) {
            this.UserService = UserService;
            this.ProjectService = ProjectService;
            this.ToasterService = ToasterService;
            this.users = users;
            this.project = project;
            this.$mdDialog = $mdDialog;
            this.$rootScope = $rootScope;
            this.language = null;
            this.email = null;
            this.is_email_valid = null;
            this.saving = false;
        }
        AddContributorController.prototype.isSelf = function (user) {
            if (this.$rootScope.user && user.email === this.$rootScope.user.email)
                return true;
            return false;
        };
        /**
          * Returns the languages a user has access to
          */
        AddContributorController.prototype.getContributorLanguages = function (user) {
            var langs = this.project.contributors
                .filter(function (contrib) { return contrib.user_id === user._id; })
                .map(function (contrib) { return contrib.language_id; });
            user.language_count = langs.length;
            return langs;
        };
        /**
         * Get available languages for a specific user
         */
        AddContributorController.prototype.getUnusedUserLanguages = function (user) {
            var _this = this;
            var langs = [];
            this.project.languages.forEach(function (language) {
                var hasLanguage = false;
                _this.project.contributors.forEach(function (contrib) {
                    if (contrib.user_id === user._id) {
                        if (contrib.language_id === language._id) {
                            hasLanguage = true;
                        }
                    }
                });
                if (!hasLanguage && langs.indexOf(language._id) === -1)
                    langs.push(language._id);
            });
            return langs;
        };
        AddContributorController.prototype.checkMailValidity = function (email) {
            var _this = this;
            if (!email || email.length < 3)
                return;
            this.UserService.checkEmailValidity(email).then(function (response) {
                _this.is_email_valid = (response.count === 0);
            });
        };
        /**
         * Adds a languages to a contributor
         */
        AddContributorController.prototype.addLanguage = function (user, language_id) {
            var _this = this;
            this.ProjectService.addContributorLanguage(this.project._id, user._id, language_id).then(function (response) {
                _this.project.contributors.push({
                    user_id: user._id,
                    language_id: language_id
                });
            });
        };
        /**
         * Remove a language from a contributor
         */
        AddContributorController.prototype.removeLanguage = function (user, language_id, event) {
            var _this = this;
            event.stopPropagation();
            event.preventDefault();
            this.ProjectService.removeContributorLanguage(this.project._id, user._id, language_id).then(function (response) {
                _this.project.contributors = _this.project.contributors.filter(function (contrib) {
                    return !(contrib.user_id === user._id && contrib.language_id === language_id);
                });
            });
        };
        /**
         * Creates a new contributors and sends an invite
         * User is added to the project contributors
         */
        AddContributorController.prototype.inviteContributor = function (email, language) {
            var _this = this;
            var newContributor = new app.Contributor();
            newContributor.email = email;
            newContributor["invite"] = true;
            if (!this.project.contributors)
                this.project.contributors = [];
            var contribResponse;
            this.saving = true;
            this.UserService
                .save(newContributor)
                .then(function (response) {
                contribResponse = response;
                return _this.ProjectService.addContributorLanguage(_this.project._id, response._id, language._id);
            })
                .then(function () {
                _this.users.push(contribResponse);
                _this.project.contributors.push({
                    user_id: contribResponse._id,
                    language_id: language._id
                });
                _this.ToasterService.pop("app.contributor.invited");
                _this.email = null;
                _this.language = null;
                _this.form.$setPristine();
                _this.form.$setUntouched();
            })
                .finally(function () { return _this.saving = false; });
        };
        AddContributorController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return AddContributorController;
    }());
    app.AddContributorController = AddContributorController;
    angular.module("app").controller("AddContributorController", AddContributorController);
})(app || (app = {}));
var app;
(function (app) {
    var AddLanguageController = (function () {
        function AddLanguageController(ProjectService, project, unusedLanguages, $mdDialog) {
            this.ProjectService = ProjectService;
            this.project = project;
            this.unusedLanguages = unusedLanguages;
            this.$mdDialog = $mdDialog;
            this.languages = [];
        }
        AddLanguageController.prototype.addLanguages = function (languages) {
            var _this = this;
            this.saving = true;
            var ids = languages.map(function (lang) { return lang._id; });
            this.ProjectService.addLanguages(this.project._id, ids).then(function (response) {
                languages.forEach(function (lang) {
                    _this.project.languages.push(lang);
                    _this.unusedLanguages.splice(_this.unusedLanguages.indexOf(lang), 1);
                    _this.close();
                });
            }).finally(function () {
                _this.saving = false;
            });
        };
        AddLanguageController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return AddLanguageController;
    }());
    app.AddLanguageController = AddLanguageController;
    angular.module("app").controller("AddLanguageController", AddLanguageController);
})(app || (app = {}));
var app;
(function (app) {
    var AddProjectController = (function () {
        function AddProjectController(ProjectService, languages, $state, TrackingService, $rootScope, $mdDialog) {
            this.ProjectService = ProjectService;
            this.languages = languages;
            this.$state = $state;
            this.TrackingService = TrackingService;
            this.$rootScope = $rootScope;
            this.$mdDialog = $mdDialog;
            this.project = new app.Project();
            this.languageList = [];
            this.saving = false;
        }
        AddProjectController.prototype.save = function (project) {
            var _this = this;
            project.created_by = this.$rootScope.user._id;
            project.primary_language = project.primary_language._id;
            project.languages = this.languageList.map(function (lang) { return lang._id; });
            project.languages.unshift(project.primary_language);
            this.saving = true;
            this.ProjectService.save(project).then(function (response) {
                _this.TrackingService.trackAddProject({ name: _this.project.name, language: _this.languageList.map(function (l) { return l.name; }).join(", ") });
                _this.$state.go("app.project.translation", { id: response._id, language_id: project.primary_language });
            }).finally(function () {
                _this.saving = false;
                _this.close();
            });
        };
        AddProjectController.prototype.removeFromLanguageList = function (language) {
            this.languageList = this.languageList.filter(function (l) { return l._id === language._id; });
        };
        AddProjectController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return AddProjectController;
    }());
    app.AddProjectController = AddProjectController;
    angular.module("app").controller("AddProjectController", AddProjectController);
})(app || (app = {}));
var app;
(function (app) {
    var ProjectExportController = (function () {
        function ProjectExportController(ProjectService, project, TrackingService, $mdDialog, localStorageService) {
            this.ProjectService = ProjectService;
            this.project = project;
            this.TrackingService = TrackingService;
            this.$mdDialog = $mdDialog;
            this.localStorageService = localStorageService;
            this.formats = [];
            this.formats = this.ProjectService.getSupportedFormats();
            this.token = this.localStorageService.get("token");
        }
        ProjectExportController.prototype.export = function (format) {
            var _this = this;
            this.ProjectService.validateKeyCount().then(function () {
                _this.TrackingService.trackExport({
                    project: _this.project.name,
                    format: format,
                    language: _this.project.languages.map(function (l) { return l.name; }).join(", ")
                });
                setTimeout(function () {
                    window.location.assign("/api/v1/projects/" + _this.project._id + "/export/" + format + "?token=" + _this.token);
                }, 500);
            });
        };
        ProjectExportController.prototype.downloadConfig = function (format) {
            window.location.assign("/api/v1/projects/" + this.project._id + "/cliconfig/" + format + "?token=" + this.token);
        };
        ProjectExportController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return ProjectExportController;
    }());
    app.ProjectExportController = ProjectExportController;
    angular.module("app").controller("ProjectExportController", ProjectExportController);
})(app || (app = {}));
var app;
(function (app) {
    var ProjectImportController = (function () {
        function ProjectImportController(ProjectService, project, TrackingService, $mdDialog, Upload, localStorageService, $state, ToasterService) {
            this.ProjectService = ProjectService;
            this.project = project;
            this.TrackingService = TrackingService;
            this.$mdDialog = $mdDialog;
            this.Upload = Upload;
            this.localStorageService = localStorageService;
            this.$state = $state;
            this.ToasterService = ToasterService;
            this.formats = [];
            this.importing = false;
            this.formats = this.ProjectService.getSupportedFormats().filter(function (f) { return f.id !== "php"; });
            this.format = this.formats[0];
        }
        ProjectImportController.prototype.onFormatChange = function (format) {
            if (format.id === "umbraco") {
                this.language = this.project.primary_language._id;
                this.isMultipleLanguageImport = true;
            }
            else {
                this.language = "";
                this.isMultipleLanguageImport = false;
            }
        };
        ProjectImportController.prototype.isPrimaryLanguage = function () {
            return this.language === this.project.primary_language._id;
        };
        ProjectImportController.prototype.getLanguageName = function () {
            var _this = this;
            var lang = this.project.languages.find(function (l) { return l._id === _this.language; });
            if (lang) {
                return lang.name;
            }
        };
        ProjectImportController.prototype.import = function (project_id, language_id, format, file, overwrite, replace) {
            var _this = this;
            if (overwrite === void 0) { overwrite = false; }
            if (replace === void 0) { replace = false; }
            if (!this.isPrimaryLanguage()) {
                replace = false;
            }
            this.importing = true;
            this.ProjectService.import(project_id, language_id, format, overwrite, replace, file).then(function (res) {
                _this.TrackingService.trackImport({
                    project: _this.project.name,
                    format: format,
                    language: _this.getLanguageName()
                });
                _this.close();
                _this.ToasterService.pop("app.import.finished");
                setTimeout(function () {
                    window.location.reload();
                }, 200);
            }).catch(function (err) {
                _this.ToasterService.pop(err.data.message);
            }).finally(function () {
                _this.importing = false;
            });
        };
        ProjectImportController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return ProjectImportController;
    }());
    app.ProjectImportController = ProjectImportController;
    angular.module("app").controller("ProjectImportController", ProjectImportController);
})(app || (app = {}));
var app;
(function (app) {
    var ProjectListController = (function () {
        function ProjectListController(LanguageService, projects, $state, $rootScope, $mdDialog) {
            this.LanguageService = LanguageService;
            this.projects = projects;
            this.$state = $state;
            this.$rootScope = $rootScope;
            this.$mdDialog = $mdDialog;
            if (this.projects.length === 0)
                this.addProject(undefined);
        }
        /**
         * Adds a new project
         */
        ProjectListController.prototype.addProject = function (event) {
            var _this = this;
            this.$mdDialog.show({
                controller: app.AddProjectController,
                templateUrl: "src/features/projects/add-project-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: function (LanguageService) {
                        return _this.LanguageService.findAll();
                    }
                }
            });
        };
        ProjectListController.prototype.selectProject = function (project) {
            var _this = this;
            this.$rootScope.blockui = true;
            var lang_id = project.primary_language;
            if (this.$rootScope.user && !this.$rootScope.user.admin) {
                lang_id = project.contributors
                    .filter(function (element) { return _this.$rootScope.user._id === element.user_id; })
                    .map(function (element) { return element.language_id; })[0];
            }
            this.$state.go("app.project.translation", { id: project._id, language_id: lang_id });
        };
        return ProjectListController;
    }());
    app.ProjectListController = ProjectListController;
    angular.module("app").controller("ProjectListController", ProjectListController);
})(app || (app = {}));
var app;
(function (app) {
    var ProjectSettingsController = (function () {
        function ProjectSettingsController(ProjectService, project, $state, $mdDialog) {
            this.ProjectService = ProjectService;
            this.project = project;
            this.$state = $state;
            this.$mdDialog = $mdDialog;
        }
        ProjectSettingsController.prototype.save = function (project) {
            var _this = this;
            if (this.form.$invalid) {
                return;
            }
            this.ProjectService.save(project).then(function () {
                _this.close();
            });
        };
        ProjectSettingsController.prototype.delete = function (project) {
            var _this = this;
            this.ProjectService.delete(project).then(function () {
                _this.$state.go("app.projects");
            });
        };
        ProjectSettingsController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return ProjectSettingsController;
    }());
    app.ProjectSettingsController = ProjectSettingsController;
    angular.module("app").controller("ProjectSettingsController", ProjectSettingsController);
})(app || (app = {}));
var app;
(function (app) {
    var ProjectController = (function () {
        function ProjectController(LanguageService, ProjectService, TranslationService, ModalService, ToasterService, project, languages, projects, UserService, $rootScope, $state, $stateParams, $timeout, $filter, $window, translationCount, $mdDialog, $scope, $mdSidenav, hotkeys, projectTags) {
            var _this = this;
            this.LanguageService = LanguageService;
            this.ProjectService = ProjectService;
            this.TranslationService = TranslationService;
            this.ModalService = ModalService;
            this.ToasterService = ToasterService;
            this.project = project;
            this.languages = languages;
            this.projects = projects;
            this.UserService = UserService;
            this.$rootScope = $rootScope;
            this.$state = $state;
            this.$stateParams = $stateParams;
            this.$timeout = $timeout;
            this.$filter = $filter;
            this.$window = $window;
            this.translationCount = translationCount;
            this.$mdDialog = $mdDialog;
            this.$scope = $scope;
            this.$mdSidenav = $mdSidenav;
            this.hotkeys = hotkeys;
            this.projectTags = projectTags;
            this.editMenuIsOpen = false;
            this.translatorMenuIsOpen = false;
            /** Array of yet to be used languages */
            this.unusedLanguages = [];
            /** Determines which language is currently selected for translation */
            this.active_language = null;
            this.current_project = null;
            /** Determines if the project FAB tool is open.  */
            this.isOpen = false;
            angular.element($window).bind("scroll", function () {
                var documentHeight = $(document).height();
                var windowHeight = $(window).height();
                var scrollPosition = $(window).scrollTop();
                if (documentHeight === windowHeight) {
                    return;
                }
                if (scrollPosition > 1) {
                    _this.hasScrolled = true;
                }
                else {
                    _this.hasScrolled = false;
                }
                _this.$scope.$apply();
            });
            if ($rootScope.user && !$rootScope.user.admin) {
                var cLangs_1 = this.project.contributors
                    .filter(function (element) { return $rootScope.user._id === element.user_id; })
                    .map(function (element) { return element.language_id; });
                this.project.languages = this.project.languages.filter(function (lang) { return cLangs_1.some(function (cLang) { return cLang === lang._id; }); });
            }
            this.unusedLanguages = this.languages.filter(function (language) {
                var exLang = project.languages.find(function (l) { return l._id === language._id; });
                return (exLang === undefined) ? true : false;
            });
            this.initActiveLanguage();
            this.current_project = this.projects.find(function (p) { return p._id === _this.$stateParams.id; });
            // Init shortcuts if admin
            if ($rootScope.user && $rootScope.user.admin) {
                this.initShortcuts();
            }
            $scope.$on("$destroy", function () {
                _this.hasScrolled = false;
                $(window).unbind("scroll");
            });
            this.$rootScope.$on("add.translation", function (event, data) {
                _this.translationCount.count++;
            });
            this.$rootScope.$on("delete.translation", function (event, data) {
                _this.translationCount.count--;
            });
        }
        ProjectController.prototype.filterChanged = function () {
            this.$rootScope.$broadcast("filter.changed");
        };
        ProjectController.prototype.toggleUntranslated = function () {
            this.TranslationService.filter.untranslated = this.TranslationService.filter.untranslated === true ? false : true;
            this.filterChanged();
        };
        ProjectController.prototype.toggleFuzzy = function () {
            this.TranslationService.filter.fuzzy = this.TranslationService.filter.fuzzy === true ? false : true;
            this.filterChanged();
        };
        ProjectController.prototype.toggleUnapproved = function () {
            this.TranslationService.filter.unreviewed = this.TranslationService.filter.unreviewed === true ? false : true;
            this.filterChanged();
        };
        /**
         * When changing language we need to reset pagination
         */
        ProjectController.prototype.changeLanguage = function (language) {
            this.TranslationService.resetFilter();
            this.active_language = language;
            this.TranslationService.pagination.current_page = 1;
            this.$state.go("app.project.translation", { id: this.project._id, language_id: language._id });
        };
        ProjectController.prototype.createNewProject = function () {
            var _this = this;
            this.$mdDialog.show({
                controller: app.AddProjectController,
                templateUrl: "src/features/projects/add-project-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: function (LanguageService) {
                        return _this.LanguageService.findAll();
                    }
                }
            });
        };
        /**
         * Change project
         */
        ProjectController.prototype.changeProject = function (project) {
            if (!project) {
                this.createNewProject();
            }
            else {
                this.$state.go("app.project", { id: project._id });
            }
        };
        ProjectController.prototype.notifyProjectOwner = function () {
            var _this = this;
            this.ProjectService
                .notifyOwner(this.project._id, this.active_language._id)
                .then(function () {
                _this.ToasterService.pop("app.owner.notified");
            });
        };
        /**
         * Show the import modal
         */
        ProjectController.prototype.showImportView = function (event) {
            this.ModalService.showImportModal(event, this.project);
        };
        /**
         * Show the export modal
         */
        ProjectController.prototype.showExportView = function (event) {
            var _this = this;
            this.$mdDialog.show({
                controller: app.ProjectExportController,
                templateUrl: "src/features/projects/project-export-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: function (LanguageService) {
                        return _this.LanguageService.findAll();
                    }
                },
                locals: {
                    project: this.project
                }
            });
        };
        /**
         * Show the add contributor modal
         */
        ProjectController.prototype.showAddContributorView = function (event) {
            var _this = this;
            this.$mdDialog.show({
                controller: app.AddContributorController,
                templateUrl: "src/features/projects/add-contributor-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: function (LanguageService) {
                        return _this.LanguageService.findAll();
                    },
                    users: function () {
                        return _this.UserService.findAll();
                    }
                },
                locals: {
                    project: this.project
                }
            });
        };
        ProjectController.prototype.showEditProject = function (event) {
            this.$mdDialog.show({
                controller: app.ProjectSettingsController,
                templateUrl: "src/features/projects/project-settings-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    project: this.project
                }
            });
        };
        /**
         * Removes a language from a contributor
         */
        ProjectController.prototype.removeContributorLanguage = function (contributor, userProject) {
            contributor.projects.splice(contributor.projects.indexOf(userProject), 1);
            this.UserService.saveUserProject(contributor);
        };
        /**
         * Adds a language to a contributor
         */
        ProjectController.prototype.addContributorLanguage = function (contributor, language) {
            contributor.projects.push({
                language_id: language._id,
                project_id: this.project._id
            });
            this.UserService.saveUserProject(contributor);
        };
        /**
         * Notify contributors about changes
         */
        ProjectController.prototype.notifyContributors = function (project) {
            var _this = this;
            this.ProjectService.notifyContributors(project._id).then(function () {
                _this.ToasterService.pop("app.translators.notified");
            });
        };
        /**
         * Removes a language from the project
         */
        ProjectController.prototype.removeLanguage = function (language) {
            var _this = this;
            if (!language || !this.active_language)
                return;
            this.ProjectService.removeLanguage(this.project._id, this.active_language._id).then(function (response) {
                _this.unusedLanguages.push(_this.active_language);
                _this.project.languages.splice(_this.project.languages.indexOf(language), 1);
                _this.$state.go("app.project", { project_id: _this.project._id });
            });
        };
        ProjectController.prototype.saveProject = function (project) {
            return this.ProjectService.save(project);
        };
        ProjectController.prototype.toggleMenu = function (menu) {
            if (menu === "translatorMenu") {
                this.translatorMenuIsOpen = !this.translatorMenuIsOpen;
            }
            else if (menu === "editMenu") {
                this.editMenuIsOpen = !this.editMenuIsOpen;
            }
        };
        /**
         * Initlaize the active language
         */
        ProjectController.prototype.initActiveLanguage = function () {
            var _this = this;
            if (this.$state.params["language_id"]) {
                var langs = this.project.languages.filter(function (language) {
                    return language._id === _this.$state.params["language_id"];
                });
                if (langs && langs.length === 1) {
                    this.active_language = langs[0];
                }
            }
        };
        ProjectController.prototype.initShortcuts = function () {
            var _this = this;
            this.hotkeys.add({
                combo: "shift+d",
                description: "Add definition",
                callback: function () {
                    _this.$rootScope.$broadcast("add_definition");
                }
            });
            this.hotkeys.add({
                combo: "shift+e",
                description: "Export project",
                callback: function () {
                    _this.showExportView(null);
                }
            });
            this.hotkeys.add({
                combo: "shift+c",
                description: "Add contributor",
                callback: function () {
                    _this.showAddContributorView(null);
                }
            });
        };
        return ProjectController;
    }());
    app.ProjectController = ProjectController;
    angular.module("app").controller("ProjectController", ProjectController);
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var app;
(function (app) {
    var ProjectService = (function (_super) {
        __extends(ProjectService, _super);
        function ProjectService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "projects";
            return _this;
        }
        ProjectService.prototype.listByName = function () {
            var query = new app.Query();
            query.sort("name");
            return this.findByQuery(query);
        };
        ProjectService.prototype.getContributorProjects = function (contributor_id) {
            var defer = this.$q.defer();
            this.listByName().then(function (projects) {
                var filteredProjects = projects
                    .filter(function (project) {
                    return project.contributors.some(function (contrib) { return contrib.user_id === contributor_id; });
                });
                defer.resolve(filteredProjects);
            });
            return defer.promise;
        };
        /** Get all  */
        ProjectService.prototype.getProjectTags = function (project_id) {
            var query = new app.Query();
            query.addCriteria(app.C.equals("_id", project_id)).distinct("translations.tags");
            return this.findByQuery(query);
        };
        /** Get all project definitions */
        ProjectService.prototype.getProjectDefinitions = function (project_id, language_id) {
            var endpoint = this.resource + "/" + project_id + "/" + language_id;
            return this.Restangular.all(endpoint).getList();
        };
        /** Notifies all contributors of a project about new changes  */
        ProjectService.prototype.notifyContributors = function (project_id) {
            var endpoint = "notify/" + project_id + "/translators";
            return this.Restangular.one(endpoint).customPOST();
        };
        ProjectService.prototype.notifyOwner = function (project_id, language_id) {
            var endpoint = "notify/" + project_id + "/owner";
            return this.Restangular.one(endpoint).customPOST({ language_id: language_id });
        };
        /** Add a new contributor to a project */
        ProjectService.prototype.addContributorLanguage = function (project_id, user_id, language_id) {
            var endpoint = this.resource + "/" + project_id + "/users/" + user_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customPUT();
        };
        /** Remove a contributor from a project */
        ProjectService.prototype.removeContributorLanguage = function (project_id, user_id, language_id) {
            var endpoint = this.resource + "/" + project_id + "/users/" + user_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customDELETE("");
        };
        /** Add a new language to a project */
        ProjectService.prototype.addLanguage = function (project_id, language_id) {
            var endpoint = this.resource + "/" + project_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customPUT();
        };
        ProjectService.prototype.addLanguages = function (project_id, language_ids) {
            var _this = this;
            var promises = [];
            language_ids.forEach(function (id) {
                var endpoint = _this.resource + "/" + project_id + "/languages/" + id;
                promises.push(_this.Restangular.one(endpoint).customPUT());
            });
            return this.$q.all(promises);
        };
        /** Add a new language to a project */
        ProjectService.prototype.removeLanguage = function (project_id, language_id) {
            var endpoint = this.resource + "/" + project_id + "/languages/" + language_id;
            return this.Restangular.one(endpoint).customDELETE("");
        };
        ProjectService.prototype.validateKeyCount = function () {
            return this.Restangular.one(this.resource + "/export/validate").get();
        };
        /** Import translation / definitions to a project in a given language */
        ProjectService.prototype.import = function (project_id, language_id, format, overwrite, replace, file) {
            // tslint:disable-next-line:max-line-length
            var endpoint = this.resource + "/" + project_id + "/language/" + language_id + "/import/" + format + "?token=" + this.localStorageService.get("token");
            // tslint:enable-next-line:max-line-length
            return this.Upload.upload({
                method: "POST",
                url: this.AppConstants.API_URL + endpoint,
                data: { overwrite: overwrite, replace: replace, file: file }
            });
        };
        ProjectService.prototype.getSupportedFormats = function () {
            return [
                { id: "json", name: ".json", img: "json.png" },
                { id: "java", name: "Java .properties", img: "java.png" },
                { id: "php", name: ".php", img: "php.png" },
                { id: "android", name: "Android .xml", img: "android.png" },
                { id: "po", name: ".po", img: "po.png" },
                { id: "iosstring", name: "iOS .strings", img: "iosstrings.png" },
                { id: "msresx", name: "Microsoft .resx", img: "resx.png" },
                { id: "csv", name: "CSV .csv", img: "csv.png" },
                { id: "xliff", name: "iOS .XLIFF", img: "xliff.png" },
                { id: "epixml", name: "EpiServer XML", img: "epixml.png" },
                { id: "umbraco", name: "Umbraco XML", img: "umbraco.png" }
            ];
        };
        return ProjectService;
    }(app.DataService));
    app.ProjectService = ProjectService;
    angular.module("app").service("ProjectService", ProjectService);
})(app || (app = {}));
var app;
(function (app) {
    var AddDefinitionController = (function () {
        function AddDefinitionController(TranslationService, TrackingService, ProjectService, $rootScope, $stateParams, translations, project, $mdDialog, definitionCount, translationCount, ToasterService) {
            this.TranslationService = TranslationService;
            this.TrackingService = TrackingService;
            this.ProjectService = ProjectService;
            this.$rootScope = $rootScope;
            this.$stateParams = $stateParams;
            this.translations = translations;
            this.project = project;
            this.$mdDialog = $mdDialog;
            this.definitionCount = definitionCount;
            this.translationCount = translationCount;
            this.ToasterService = ToasterService;
            this.related = [];
            this.related_proposed = false;
            this.loadingRelated = false;
            this.context = "";
            this.key_valid = null;
            this.reference_key = null;
            this.saving = false;
        }
        AddDefinitionController.prototype.copyRelated = function (related) {
            var translation = new app.Translation();
            translation.active_definition = related.active_definition;
            translation.definitions = related.definitions;
            translation.comments = [];
            translation.primary_text = related.primary_text;
            translation.project_id = this.project._id;
            var now = new Date();
            translation.definitions.forEach(function (definition, idx) {
                definition.copied_from = related.definitions[idx]._id;
                definition.copy_time = now.getTime();
            });
            translation.reference_key = this.reference_key;
            this.persist(translation);
        };
        AddDefinitionController.prototype.save = function (reference_key, text, context) {
            if (this.form.$invalid)
                return;
            if (this.active_related)
                return this.copyRelated(this.active_related);
            var translation = new app.Translation();
            translation.reference_key = reference_key;
            translation.project_id = this.$stateParams.id;
            translation.primary_text = text;
            if (context)
                translation.context = context;
            var definition = new app.Definition();
            definition.language_id = this.project.primary_language._id;
            definition.translated_by = this.$rootScope.user.email;
            definition.text = text;
            translation.definitions.push(definition);
            this.persist(translation);
        };
        AddDefinitionController.prototype.persist = function (translation) {
            var _this = this;
            this.saving = true;
            this.TranslationService.save(translation)
                .then(function (response) {
                _this.TranslationService.initTranslation(response);
                _this.translations.unshift(response);
                _this.translationCount.count++;
                if (_this.project.primary_language._id === _this.$stateParams.language_id)
                    _this.definitionCount.count++;
                _this.resetForm();
                _this.$rootScope.$broadcast("add.translation", translation);
                _this.ToasterService.pop("String was successfully added!");
                _this.TrackingService.trackAddNewDefinition({
                    text: translation.active_definition.text,
                    language: _this.project.primary_language.name
                });
            })
                .finally(function () {
                _this.saving = false;
            }).catch(function () {
                _this.close();
            });
        };
        AddDefinitionController.prototype.resetForm = function () {
            this.related = [];
            this.active_related = null;
            this.key_valid = null;
            this.reference_key = null;
            this.text = null;
            this.context = "";
            this.form.$setUntouched();
            this.$rootScope.$broadcast("focus:addtrans:ref");
        };
        AddDefinitionController.prototype.validateReferenceKey = function (reference_key) {
            var _this = this;
            if (!reference_key || reference_key === "")
                return this.key_valid = null;
            var query = new app.Query();
            query.addCriteria(app.C.and(app.C.equals("project_id", this.project._id), app.C.equals("reference_key", reference_key)));
            this.TranslationService.countByQuery(query).then(function (response) {
                _this.key_valid = (response.count === 0);
            });
        };
        AddDefinitionController.prototype.checkRelated = function (text) {
            var _this = this;
            if (text && text.length > 2) {
                this.loadingRelated = true;
                this.TranslationService.findSimilar(this.project.primary_language._id, text).then(function (response) {
                    _this.related = response;
                    _this.related_proposed = true;
                }).finally(function () {
                    _this.loadingRelated = false;
                });
            }
        };
        AddDefinitionController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return AddDefinitionController;
    }());
    app.AddDefinitionController = AddDefinitionController;
    angular.module("app").controller("AddDefinitionController", AddDefinitionController);
})(app || (app = {}));
var app;
(function (app) {
    var DefinitionDetailsController = (function () {
        function DefinitionDetailsController($mdDialog, $rootScope, TranslationService, translation, project) {
            this.$mdDialog = $mdDialog;
            this.$rootScope = $rootScope;
            this.TranslationService = TranslationService;
            this.translation = translation;
            this.project = project;
            this.referenceKey = this.translation.reference_key;
        }
        DefinitionDetailsController.prototype.close = function () {
            this.$mdDialog.cancel();
        };
        DefinitionDetailsController.prototype.delete = function () {
            this.$rootScope.$broadcast("delete.translation", this.translation);
            this.close();
        };
        DefinitionDetailsController.prototype.save = function (referenceKey) {
            var _this = this;
            if (referenceKey.length < 2) {
                return;
            }
            this.TranslationService.updateReferenceKey(this.translation._id, referenceKey).then(function () {
                _this.translation.reference_key = referenceKey;
                _this.close();
            });
        };
        return DefinitionDetailsController;
    }());
    app.DefinitionDetailsController = DefinitionDetailsController;
    angular.module("app").controller("DefinitionDetailsController", DefinitionDetailsController);
})(app || (app = {}));
var app;
(function (app) {
    var DefinitionHistoryController = (function () {
        function DefinitionHistoryController(translation, $rootScope, TranslationService, $mdDialog) {
            this.translation = translation;
            this.$rootScope = $rootScope;
            this.TranslationService = TranslationService;
            this.$mdDialog = $mdDialog;
        }
        DefinitionHistoryController.prototype.useHistory = function (history) {
            var _this = this;
            this.translation.active_definition.text = history.text;
            this.TranslationService.saveDefinition(this.translation).then(function () {
                _this.close();
            });
        };
        DefinitionHistoryController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return DefinitionHistoryController;
    }());
    app.DefinitionHistoryController = DefinitionHistoryController;
    angular.module("app").controller("DefinitionHistoryController", DefinitionHistoryController);
})(app || (app = {}));
var app;
(function (app) {
    var TranslationController = (function () {
        function TranslationController(ProjectService, languages, language, translations, project, projectTags, TranslationService, $rootScope, $mdDialog, $state, $mdSidenav, hotkeys, ModalService, $scope, $timeout, ToasterService, definitionCount, translationCount) {
            var _this = this;
            this.ProjectService = ProjectService;
            this.languages = languages;
            this.language = language;
            this.translations = translations;
            this.project = project;
            this.projectTags = projectTags;
            this.TranslationService = TranslationService;
            this.$rootScope = $rootScope;
            this.$mdDialog = $mdDialog;
            this.$state = $state;
            this.$mdSidenav = $mdSidenav;
            this.hotkeys = hotkeys;
            this.ModalService = ModalService;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.ToasterService = ToasterService;
            this.definitionCount = definitionCount;
            this.translationCount = translationCount;
            this.newTranslation = new app.Translation();
            this.filterQuery = new app.Query();
            this.currentScrollPosition = null;
            this.hasMore = null;
            this.loading = false;
            this.loadingMore = false;
            this.selected_translation = null;
            this.percentage_done = null;
            this.showNotifyOwner = true;
            this.hasSavedText = false;
            this.debounceMap = {};
            // Always reset the current page to 1 on init
            this.TranslationService.pagination.current_page = 1;
            if ($rootScope.user && $rootScope.user.admin) {
                hotkeys.add({
                    combo: "ctrl+d",
                    description: "Create new definition",
                    callback: function () {
                        _this.addDefinition(null);
                    }
                });
            }
            translations.forEach(function (translation) {
                _this.TranslationService.initTranslation(translation);
            });
            this.isPrimaryLanguage = (this.language._id === this.project.primary_language._id);
            this.hasMore = this.TranslationService.pagination.items_per_page === this.translations.length;
            this.calculatePercentage();
            this.project.languages.sort(function (a, b) { return a._id === _this.project.primary_language._id ? -1 : 1; });
            this.selectedLanguage = this.project.languages.find(function (lang) { return lang._id === _this.language._id; });
            this.$rootScope.$on("delete.translation", function (event, translation) {
                _this.delete(translation);
            });
            /**
             * Watch the filters search when they change
             */
            this.$scope.$on("filter.changed", function () {
                _this.TranslationService.pagination.append = false;
                _this.TranslationService.pagination.current_page = 1;
                _this.$rootScope.loading = true;
                _this.TranslationService.findByFilter(_this.project._id, _this.language._id).then(function (response) {
                    _this.translations = response;
                    _this.hasMore = _this.TranslationService.pagination.items_per_page === response.length;
                }).finally(function () { return _this.$rootScope.loading = false; });
            });
            this.initializeInfiniteScroll();
            // Allow other controllers to add definitions
            this.$scope.$on("add_definition", function () {
                _this.addDefinition(null);
            });
        }
        TranslationController.prototype.calculatePercentage = function () {
            if (this.translationCount.count === 0)
                return this.percentage_done = 0;
            this.percentage_done = Math.round((this.definitionCount.count / this.translationCount.count * 100) * 100) / 100;
        };
        TranslationController.prototype.autoTranslate = function (translation) {
            var _this = this;
            this.TranslationService
                .autoTranslate(translation._id, translation.primary_text, this.project.primary_language._id, this.language._id)
                .then(function (translatedText) {
                translation.active_definition.text = translatedText;
                _this.saveText(translation, translatedText);
            });
        };
        TranslationController.prototype.getIcon = function (definition) {
            if (definition.reviewed)
                return "done_all";
            if (definition.fuzzy)
                return "flash_on";
            if (definition.create_time)
                return "done";
            return "keyboard";
        };
        TranslationController.prototype.changeLanguage = function (language) {
            this.TranslationService.resetFilter();
            this.TranslationService.pagination.current_page = 1;
            this.$state.go("app.project.translation", { id: this.project._id, language_id: language._id });
        };
        /**
         * Loads more translations and puts them into the table
         */
        TranslationController.prototype.loadMoreTranslations = function () {
            var _this = this;
            if (!this.hasMore || this.loadingMore === true)
                return;
            this.loadingMore = true;
            this.TranslationService.pagination.append = true;
            this.TranslationService.pagination.current_page += 1;
            this.TranslationService.findByFilter(this.project._id, this.language._id).then(function (response) {
                if (_this.TranslationService.pagination.append)
                    _this.translations = _this.translations.concat(response);
                _this.hasMore = _this.TranslationService.pagination.items_per_page === response.length;
            }).finally(function () {
                _this.loadingMore = false;
            });
        };
        TranslationController.prototype.initializeInfiniteScroll = function () {
            var _this = this;
            var currentPosition = 0;
            this.$timeout(function () {
                var docuemntHeight = $(document).height() - $(window).height();
                // Only scroll if document is higher than the saved position
                if (docuemntHeight > currentPosition)
                    window.scrollBy(0, currentPosition);
            }, 100);
            // Inifite scroll
            $(window).scroll(function () {
                _this.currentScrollPosition = $(window).scrollTop();
                // When reaching bottom we will load more content
                if ($(window).scrollTop() === $(document).height() - $(window).height()) {
                    _this.loadMoreTranslations();
                }
            });
        };
        TranslationController.prototype.saveText = function (translation, text) {
            var _this = this;
            this.$timeout.cancel(this.debounceMap[translation._id]);
            this.debounceMap[translation._id] = this.$timeout(function () {
                var isNew = (translation.active_definition.create_time) ? false : true;
                _this.TranslationService.saveDefinition(translation).then(function (response) {
                    if (isNew) {
                        _this.definitionCount.count++;
                        _this.calculatePercentage();
                    }
                    _this.hasSavedText = true;
                }).catch(function (err) {
                    _this.ToasterService.pop("Error saving your text");
                });
            }, 1000);
        };
        TranslationController.prototype.addDefinition = function (event) {
            if (!this.$rootScope.user || !this.$rootScope.user.admin)
                return;
            this.$mdDialog.show({
                controller: app.AddDefinitionController,
                templateUrl: "src/features/translations/add-definition-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    translations: this.translations,
                    project: this.project,
                    definitionCount: this.definitionCount,
                    translationCount: this.translationCount
                }
            });
        };
        TranslationController.prototype.initializeNewDefinition = function () {
            var newDef = new app.Definition();
            newDef.language_id = this.language._id;
            newDef.text = null;
            this.newTranslation.definitions.push(newDef);
        };
        /**
         * Shows the comment sidebar
         */
        TranslationController.prototype.showComments = function (translation) {
            this.selected_translation = translation;
            return this.$mdSidenav("comments").toggle();
        };
        TranslationController.prototype.showImportModal = function (event) {
            this.ModalService.showImportModal(event, this.project);
        };
        TranslationController.prototype.showHistory = function (event, translation) {
            this.$mdDialog.show({
                controller: app.DefinitionHistoryController,
                templateUrl: "src/features/translations/definition-history-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    translation: translation
                }
            });
        };
        TranslationController.prototype.showDetails = function (event, translation) {
            this.$mdDialog.show({
                controller: app.DefinitionDetailsController,
                templateUrl: "src/features/translations/definition-details-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    translation: translation,
                    project: this.project
                }
            });
        };
        /**
         * Pops the add language modal
         */
        TranslationController.prototype.showAddLanguage = function (event, selectedLanguage) {
            var _this = this;
            var unusedLanguages = this.languages.filter(function (language) {
                var exLang = _this.project.languages.find(function (l) { return l._id === language._id; });
                return (exLang === undefined) ? true : false;
            });
            this.$mdDialog.show({
                controller: app.AddLanguageController,
                templateUrl: "src/features/projects/add-language-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                locals: {
                    project: this.project,
                    unusedLanguages: unusedLanguages
                }
            });
            this.$timeout(function () {
                _this.selectedLanguage = selectedLanguage;
            }, 100);
        };
        TranslationController.prototype.toggleMulti = function (field, flag) {
            var _this = this;
            var copyFilterQuery = angular.copy(this.filterQuery);
            copyFilterQuery.removePagination();
            copyFilterQuery.addCriteria(app.C.elementMatch("definitions", { language_id: this.language._id }));
            copyFilterQuery.select(["_id"]);
            this.TranslationService.findByQuery(copyFilterQuery).then(function (response) {
                var ids = response.map(function (translation) { return translation._id; });
                _this.TranslationService.toggleMulti(field, flag, _this.language._id, ids).then(function (_) { return _; });
            });
        };
        /**
         * Toggles if the key is reviewed or not
         */
        TranslationController.prototype.toggleReviewed = function (translation) {
            translation.active_definition.reviewed = (translation.active_definition.reviewed) ? false : true;
            if (translation.active_definition.reviewed)
                translation.active_definition.fuzzy = false;
            this.TranslationService.toggleReviewed(translation);
        };
        /**
         * Toggles if the key is fuzzy / unsure of translation
         */
        TranslationController.prototype.toggleFuzzy = function (translation) {
            translation.active_definition.fuzzy ? translation.active_definition.fuzzy = false : translation.active_definition.fuzzy = true;
            this.TranslationService.toggleFuzzy(translation);
        };
        /**
         * Adds a tag to the key
         */
        /*addTag(tag : string, translation : Translation) {
            console.log(translation)
            this.save(translation);
            for (var index = 0; index < this.projectTags.length; index++) {
                var element = this.projectTags[index];
                if(element == tag)
                    return;
            }
            this.projectTags.push(tag);
        }*/
        TranslationController.prototype.delete = function (translation) {
            var _this = this;
            this.TranslationService.delete(translation).then(function () {
                _this.translations.splice(_this.translations.indexOf(translation), 1);
                _this.decreaseTotals();
            });
        };
        TranslationController.prototype.increaseTotals = function () {
            this.translationCount.count++;
            this.calculatePercentage();
        };
        TranslationController.prototype.decreaseTotals = function () {
            this.translationCount.count--;
            this.definitionCount.count--;
            this.calculatePercentage();
        };
        return TranslationController;
    }());
    app.TranslationController = TranslationController;
    angular.module("app").controller("TranslationController", TranslationController);
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var app;
(function (app) {
    var TranslationService = (function (_super) {
        __extends(TranslationService, _super);
        function TranslationService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "translations";
            _this.isFiltering = false;
            _this.filter = {
                sortBy: "reference_key"
            };
            _this.pagination = {
                items_per_page: 500,
                current_page: 1,
                total_items: 10,
                num_pages: 1,
                append: false
            };
            return _this;
        }
        TranslationService.prototype.initTranslation = function (translation) {
            var definitions = this.$filter("filter")(translation.definitions, { language_id: this.$stateParams.language_id });
            /** Set the active translation by the language */
            if (definitions && definitions.length)
                translation.active_definition = definitions[0];
            else {
                /** Nothing found so we create a new */
                var definition = new app.Definition();
                // definition._id = this.UtilityService.uid();
                definition.language_id = this.$stateParams.language_id;
                translation.definitions.push(definition);
                translation.active_definition = definition;
            }
        };
        TranslationService.prototype.resetFilter = function () {
            this.filter = {
                sortBy: "reference_key"
            };
            return this.filter;
        };
        TranslationService.prototype.findTranslationCounts = function (project_id, language_id) {
            var query = new app.Query();
            query.addCriteria(app.C.and(app.C.equals("project_id", project_id), app.C.elementMatch("definitions", { language_id: language_id, text: { $ne: "" } })));
            return this.countByQuery(query);
        };
        /**
         * CACHING NOT WORKING VERY WELL
         */
        TranslationService.prototype.cacheNext = function (project_id, language_id) {
            return;
            /*if(this.translationCache.length > 0)
                return;
            this.findByFilter(project_id,language_id).then((response : Translation[]) => {
                this.translationCache = response;
            })*/
        };
        TranslationService.prototype.findByFilter = function (project_id, language_id) {
            // Return cache if it exists
            var _this = this;
            var query = new app.Query();
            query.addCriteria(app.C.equals("project_id", project_id));
            // filter by key
            if (this.filter.text && this.filter.text.length > 0 && language_id) {
                query.addCriteria(app.C.or(app.C.contains("primary_text", this.filter.text), app.C.elementMatch("definitions", { text: { $regex: this.filter.text, $options: "i" }, language_id: language_id }), app.C.contains("reference_key", this.filter.text)));
            }
            // Filter by fuzzy
            if (angular.isDefined(this.filter.fuzzy) && this.filter.fuzzy === true) {
                query.addCriteria(app.C.elementMatch("definitions", { language_id: language_id, fuzzy: true }));
            }
            // Filter by reviewed
            if (angular.isDefined(this.filter.unreviewed) && this.filter.unreviewed === true) {
                query.addCriteria(app.C.elementMatch("definitions", { language_id: language_id, reviewed: false }));
            }
            // Filter by untranslated
            if (angular.isDefined(this.filter.untranslated) && this.filter.untranslated === true) {
                query.addCriteria(app.C.or(app.C.elementMatch("definitions", { language_id: language_id, text: { $eq: "" } }), app.C.notEquals("definitions.language_id", language_id)));
            }
            // Apply pagination
            query.limit(this.pagination.items_per_page);
            query.skip((this.pagination.current_page - 1) * this.pagination.items_per_page);
            // Apply sorting
            query.sort(this.filter.sortBy);
            this.isFiltering = true;
            var defered = this.$q.defer();
            _super.prototype.findByQuery.call(this, query).then(function (response) {
                response.forEach(function (translation) {
                    _this.initTranslation(translation);
                });
                _this.isFiltering = false;
                return defered.resolve(response);
            }, function (response) {
                _this.isFiltering = false;
                return defered.reject(response);
            });
            return defered.promise;
        };
        TranslationService.prototype.findSimilar = function (language_id, text, key_id) {
            var _this = this;
            var query = new app.Query();
            query.limit(5);
            query.addCriteria(app.C.elementMatch("definitions", { text: { $regex: text, $options: "i" }, language_id: language_id }));
            if (key_id)
                query.addCriteria(app.C.notEquals("_id", key_id));
            query.populate(["project_id"]);
            var defer = this.$q.defer();
            this.findByQuery(query).then(function (response) {
                response.forEach(function (translation) {
                    _this.initTranslation(translation);
                });
                return defer.resolve(response);
            }, function (response) {
                return defer.reject(response);
            });
            return defer.promise;
        };
        TranslationService.prototype.save = function (translation) {
            translation = this.replaceActiveDefinition(translation);
            return _super.prototype.save.call(this, translation);
        };
        TranslationService.prototype.getProjectTags = function (project_id) {
            var query = new app.Query();
            query.addCriteria(app.C.equals("project_id", project_id)).distinct("tags");
            return this.findByQuery(query);
        };
        TranslationService.prototype.saveComment = function (translation, comment) {
            return this.Restangular.one(this.resource + "/comment/", translation._id).customPUT({
                project_id: translation.project_id,
                language_id: translation.active_definition.language_id,
                comment: comment
            });
        };
        TranslationService.prototype.saveDefinition = function (translation) {
            var defer = this.$q.defer();
            this.Restangular.one(this.resource + "/definition/", translation._id).customPUT({
                project_id: translation.project_id,
                definition: translation.active_definition
            })
                .then(function (response) {
                translation.active_definition = response;
                defer.resolve(response);
            })
                .catch(function (response) {
                defer.reject(response);
            });
            return defer.promise;
        };
        TranslationService.prototype.toggleReviewed = function (translation, ids) {
            if (ids === void 0) { ids = []; }
            return this.Restangular.one(this.resource + "/reviewed/", translation._id).customPUT({
                project_id: translation.project_id,
                language_id: translation.active_definition.language_id,
                reviewed: translation.active_definition.reviewed
            });
        };
        TranslationService.prototype.toggleFuzzy = function (translation, ids) {
            if (ids === void 0) { ids = []; }
            var payload = {
                project_id: translation.project_id,
                language_id: translation.active_definition.language_id,
                fuzzy: translation.active_definition.fuzzy
            };
            if (ids.length > 0) {
                payload.multi = true;
                payload.ids = ids;
            }
            return this.Restangular.one(this.resource + "/fuzzy/", translation._id).customPUT(payload);
        };
        TranslationService.prototype.toggleMulti = function (field, flag, language_id, ids) {
            var payload = {
                language_id: language_id,
                ids: ids
            };
            payload[field] = flag;
            return this.Restangular.one(this.resource + "/multi/batch").customPOST(payload);
        };
        TranslationService.prototype.isReferenceKeyDuplicate = function (reference_key, project_id) {
            var query = new app.Query();
            query.addCriteria(app.C.and(app.C.equals("reference_key", reference_key), app.C.equals("project_id", project_id)));
            var defered = this.$q.defer();
            _super.prototype.findByQuery.call(this, query).then(function (response) {
                if (response.length > 0)
                    defered.reject("app.error.key.already.exists");
                else
                    defered.resolve();
            });
            return defered.promise;
        };
        TranslationService.prototype.autoTranslate = function (id, text, srcId, targetId) {
            var params = {
                text: text,
                src_lang_id: srcId,
                target_lang_id: targetId
            };
            return this.Restangular.one(this.resource + "/auto", id).customPOST(params);
        };
        TranslationService.prototype.updateReferenceKey = function (_id, newReferenceKey) {
            return this.Restangular.one(this.resource + "/updatereferencekey", _id).customPUT({
                reference_key: newReferenceKey
            });
        };
        TranslationService.prototype.replaceActiveDefinition = function (translation) {
            if (!translation.active_definition)
                return translation;
            var defIndex = translation.definitions.findIndex(function (def) { return def.language_id === translation.active_definition.language_id; });
            translation.definitions[defIndex] = translation.active_definition;
            delete translation.active_definition;
            return translation;
        };
        return TranslationService;
    }(app.DataService));
    app.TranslationService = TranslationService;
    angular.module("app").service("TranslationService", TranslationService);
})(app || (app = {}));
var app;
(function (app) {
    var ChangePasswordController = (function () {
        function ChangePasswordController(user, UserService, $mdDialog, ToasterService) {
            this.user = user;
            this.UserService = UserService;
            this.$mdDialog = $mdDialog;
            this.ToasterService = ToasterService;
        }
        ChangePasswordController.prototype.changePassword = function (password, confirm) {
            var _this = this;
            if (password !== confirm)
                return;
            this.UserService.updatePassword(this.user, password).then(function (response) {
                _this.ToasterService.pop("app.password.updated");
                _this.close();
            });
        };
        ChangePasswordController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        return ChangePasswordController;
    }());
    app.ChangePasswordController = ChangePasswordController;
    angular.module("app").controller("ChangePasswordController", ChangePasswordController);
})(app || (app = {}));
var app;
(function (app) {
    var ContributorDetailsController = (function () {
        function ContributorDetailsController(userProjects, contributor, $rootScope, $mdDialog, ToasterService, CountryService, ProjectService, $state, UserService) {
            var _this = this;
            this.userProjects = userProjects;
            this.contributor = contributor;
            this.$rootScope = $rootScope;
            this.$mdDialog = $mdDialog;
            this.ToasterService = ToasterService;
            this.CountryService = CountryService;
            this.ProjectService = ProjectService;
            this.$state = $state;
            this.UserService = UserService;
            userProjects.forEach(function (project) {
                project.user_languages = _this.getUserLanguages(project);
            });
        }
        ContributorDetailsController.prototype.addLanguage = function (project, language_id) {
            var _this = this;
            this.ProjectService.addContributorLanguage(project._id, this.contributor._id, language_id).then(function (response) {
                project.contributors.push({
                    user_id: _this.contributor._id,
                    language_id: language_id
                });
                project.user_languages.push(language_id);
            });
        };
        ContributorDetailsController.prototype.removeLanguage = function (project, lang_id, event) {
            this.ProjectService.removeContributorLanguage(project._id, this.contributor._id, lang_id).then(function () {
                project.user_languages = project.user_languages.filter(function (lang) { return lang !== lang_id; });
            });
            event.stopPropagation();
        };
        ContributorDetailsController.prototype.close = function () {
            this.$mdDialog.cancel();
        };
        ContributorDetailsController.prototype.save = function (user) {
            var _this = this;
            this.UserService.save(user).then(function () {
                _this.ToasterService.pop("User updated!");
                _this.$state.go("app.contributors");
            });
        };
        ContributorDetailsController.prototype.isSelf = function (user) {
            return this.$rootScope.user && user.email === this.$rootScope.user.email;
        };
        ContributorDetailsController.prototype.remove = function (user) {
            var _this = this;
            if (this.isSelf(user))
                return;
            this.UserService.delete(user).then(function (response) {
                _this.$rootScope.$broadcast("delete:user", user);
            });
        };
        ContributorDetailsController.prototype.getUnusedUserLanguages = function (project) {
            return project.languages.filter(function (language) {
                var isUserLang = project.user_languages.some(function (userLang) { return userLang === language; });
                return !isUserLang;
            });
        };
        ContributorDetailsController.prototype.getUserLanguages = function (project) {
            var _this = this;
            return project.contributors
                .filter(function (contrib) { return contrib.user_id === _this.contributor._id; })
                .map(function (contrib) { return contrib.language_id; });
        };
        return ContributorDetailsController;
    }());
    app.ContributorDetailsController = ContributorDetailsController;
    angular.module("app").controller("ContributorDetailsController", ContributorDetailsController);
})(app || (app = {}));
var app;
(function (app) {
    var ContributorListController = (function () {
        function ContributorListController(UserService, ModalService, users, $rootScope) {
            var _this = this;
            this.UserService = UserService;
            this.ModalService = ModalService;
            this.users = users;
            this.$rootScope = $rootScope;
            this.users = this.users.filter(function (user) { return !_this.isSelf(user); });
            this.$rootScope.$on("delete:user", function (event, data) {
                _this.users = _this.users.filter(function (user) { return user._id !== data._id; });
            });
        }
        ContributorListController.prototype.isSelf = function (user) {
            return this.$rootScope.user && user.email === this.$rootScope.user.email;
        };
        ContributorListController.prototype.showContributorDetails = function (user, event) {
            this.ModalService.showContributorDetailsModal(user, event);
        };
        return ContributorListController;
    }());
    app.ContributorListController = ContributorListController;
    angular.module("app").controller("ContributorListController", ContributorListController);
})(app || (app = {}));
var app;
(function (app) {
    var ResetPasswordController = (function () {
        function ResetPasswordController(ToasterService, UserService, $location, $rootScope, $mdToast, $state) {
            this.ToasterService = ToasterService;
            this.UserService = UserService;
            this.$location = $location;
            this.$rootScope = $rootScope;
            this.$mdToast = $mdToast;
            this.$state = $state;
            this.loading = false;
        }
        /**
         * Do the password reset!
         */
        ResetPasswordController.prototype.resetPassword = function (password) {
            var _this = this;
            var token = this.$rootScope.resetToken;
            if (!token)
                return this.$mdToast.show(this.$mdToast.simple().textContent("Upss something went wrong"));
            this.loading = true;
            this.UserService.doResetPassword(password, token).then(function () {
                _this.ToasterService.pop("app.password.has.been.reset");
                _this.$state.go("pages.login");
            }).finally(function () {
                _this.loading = false;
            });
        };
        return ResetPasswordController;
    }());
    app.ResetPasswordController = ResetPasswordController;
    angular.module("app").controller("ResetPasswordController", ResetPasswordController);
})(app || (app = {}));
var app;
(function (app) {
    var SignupController = (function () {
        function SignupController(UserService, AuthService, $state) {
            this.UserService = UserService;
            this.AuthService = AuthService;
            this.$state = $state;
            this.signingup = false;
            this.is_email_valid = null;
        }
        SignupController.prototype.signup = function (email, password) {
            var _this = this;
            if (this.form.$invalid)
                return;
            if (this.is_email_valid === false) {
                return;
            }
            this.signingup = true;
            this.UserService.signup(email, password).then(function (response) {
                _this.$state.go("pages.signupcomplete").then(function () {
                    _this.AuthService.authenticate(email, password);
                });
            }).catch(function () {
                _this.signingup = false;
            });
        };
        return SignupController;
    }());
    app.SignupController = SignupController;
    angular.module("app").controller("SignupController", SignupController);
})(app || (app = {}));
/// <reference path="../../core/base-data.service.ts" />
var app;
(function (app) {
    var UserService = (function (_super) {
        __extends(UserService, _super);
        function UserService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "users";
            return _this;
        }
        UserService.prototype.findContributors = function () {
            var query = new app.Query()
                .addCriteria(app.C.equals("contributor", true))
                .populate(["projects.language_id"])
                .sort("email");
            return this.findByQuery(query);
        };
        UserService.prototype.getCurrentUser = function () {
            var _this = this;
            if (this.$rootScope.user) {
                var deferred_1 = this.$q.defer();
                this.$timeout(function () {
                    return deferred_1.resolve(_this.$rootScope.user);
                });
                return deferred_1.promise;
            }
            else {
                return this.Restangular.one(this.resource + "/current").get();
            }
        };
        UserService.prototype.saveUserProject = function (user) {
            return this.Restangular.one(this.resource + "/projects", user._id).customPUT({
                projects: user.projects
            });
        };
        UserService.prototype.countByEmail = function (email) {
            var query = new app.Query();
            query.addCriteria(app.C.equals("email", email));
            return this.countByQuery(query);
        };
        UserService.prototype.checkEmailValidity = function (email) {
            return this.Restangular.one("check_email_validity").get({ email: email });
        };
        UserService.prototype.updatePassword = function (user, password) {
            return this.Restangular.one("update_password/" + this.resource, user._id).customPUT({
                password: password
            });
        };
        UserService.prototype.requestResetPassword = function (email) {
            return this.Restangular.one("request_reset_password").customPOST({
                email: email
            });
        };
        UserService.prototype.doResetPassword = function (password, token) {
            return this.Restangular.one("do_reset_password").customPOST({
                password: password,
                token: token
            });
        };
        UserService.prototype.signup = function (email, password) {
            var user = new app.User();
            user.email = email;
            user["password"] = password;
            return this.Restangular.one("signup").customPOST({
                user: user
            });
        };
        return UserService;
    }(app.DataService));
    app.UserService = UserService;
    angular.module("app").service("UserService", UserService);
})(app || (app = {}));
var app;
(function (app) {
    var ProjectActivityComponent = (function () {
        function ProjectActivityComponent() {
            this.scope = { navId: "@" };
            this.controllerAs = "vm";
            this.bindToController = true;
            this.controller = app.ProjectActivityController;
            this.templateUrl = "src/features/projects/project-activity/project-activity.html";
        }
        ProjectActivityComponent.factory = function () {
            return function () {
                return new ProjectActivityComponent();
            };
        };
        return ProjectActivityComponent;
    }());
    angular.module("app").directive("projectActivity", ProjectActivityComponent.factory());
})(app || (app = {}));
var app;
(function (app) {
    var ProjectActivityController = (function () {
        function ProjectActivityController(ProjectService, ProjectActivityService, $rootScope, $mdSidenav, $stateParams, AppConstants) {
            var _this = this;
            this.ProjectService = ProjectService;
            this.ProjectActivityService = ProjectActivityService;
            this.$rootScope = $rootScope;
            this.$mdSidenav = $mdSidenav;
            this.$stateParams = $stateParams;
            this.AppConstants = AppConstants;
            this.activities = [];
            this.ProjectActivityService.findRecentActiviy($stateParams["id"]).then(function (response) {
                _this.activities = response;
            });
        }
        ProjectActivityController.prototype.generateText = function (activity) {
            var languageName = (!activity.language_id) ? " - " : activity.language_id.name;
            if (activity.type === this.AppConstants.EVENT_PROJECT_DEFINITION_ADDED)
                // tslint:disable-next-line:max-line-length
                return "Saved new text <b>" + activity.text + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_IMPORT)
                return "Imported <b>" + activity.text + "</b> number of definitions in <b>" + languageName + "</b>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_COMMENT_POSTED)
                // tslint:disable-next-line:max-line-length
                return "Added comment <b>" + activity.text + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TOGGLE_FUZZY) {
                var fuzzy = (activity.text) ? "on" : "off";
                // tslint:disable-next-line:max-line-length
                return "<span>Toggle fuzzy <b>" + fuzzy + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b></span>";
            }
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TOGGLE_REVIEWED) {
                var reviewed = (activity.text) ? "on" : "off";
                // tslint:disable-next-line:max-line-length
                return "<span>Toggle reviewed <b>" + reviewed + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b></span>";
            }
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TRANSLATION_ADDED)
                // tslint:disable-next-line:max-line-length
                return "<span>Added key <b>" + activity.reference_key + "</b> with primary text <b>" + activity.text + "</b> in <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TRANSLATION_DELETED)
                return "<span>Deleted key <b>" + activity.reference_key + "</b> with primary text <b>" + activity.text + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_NOTIFY)
                return "<span>Notified contributors by email</span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_CONTRIBUTOR_ADDED)
                return "<span>Added <b>" + activity.text + "</b> as contributor for <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_CONTRIBUTOR_DELETED)
                return "<span>Removed <b>" + activity.text + "</b> as contributor for <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_LANGUAGE_ADDED)
                return "<span>Added language <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_LANGUAGE_DELETED)
                return "<span>Removed language <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_CREATED)
                return "<span>Created project <b>" + activity.project_id.name + "</b></span>";
        };
        ProjectActivityController.prototype.close = function () {
            this.$mdSidenav(this.navId).close();
        };
        return ProjectActivityController;
    }());
    app.ProjectActivityController = ProjectActivityController;
    angular.module("app").controller("AddProjectController", app.AddProjectController);
})(app || (app = {}));
/// <reference path="../../../core/base-data.service.ts" />
var app;
(function (app) {
    var ProjectActivityService = (function (_super) {
        __extends(ProjectActivityService, _super);
        function ProjectActivityService() {
            var _this = _super.apply(this, arguments) || this;
            _this.resource = "project_activities";
            _this.pagination = {
                items_per_page: 30,
                current_page: 1,
                total_items: 10,
                num_pages: 1,
                append: false
            };
            return _this;
        }
        /**
         * Find recent project activity
         */
        ProjectActivityService.prototype.findRecentActiviy = function (project_id) {
            var q = new app.Query();
            q.addCriteria(app.C.and(app.C.equals("project_id", project_id)));
            q.sort("-create_time");
            q.pagination(this.pagination);
            q.populate(["language_id", "project_id", "translation_id"]);
            return this.findByQuery(q);
        };
        return ProjectActivityService;
    }(app.DataService));
    app.ProjectActivityService = ProjectActivityService;
    angular.module("app").service("ProjectActivityService", ProjectActivityService);
})(app || (app = {}));
var app;
(function (app) {
    var CommentsComponent = (function () {
        function CommentsComponent() {
            this.scope = {
                translation: "="
            };
            this.controllerAs = "vm";
            this.bindToController = true;
            this.controller = app.CommentsController;
            this.templateUrl = "src/features/translations/comments/comments.html";
        }
        CommentsComponent.factory = function () {
            return function () {
                return new CommentsComponent();
            };
        };
        return CommentsComponent;
    }());
    angular.module("app").directive("comments", CommentsComponent.factory());
})(app || (app = {}));
var app;
(function (app) {
    var CommentsController = (function () {
        function CommentsController($rootScope, TranslationService, $mdSidenav) {
            this.$rootScope = $rootScope;
            this.TranslationService = TranslationService;
            this.$mdSidenav = $mdSidenav;
            this.navId = "comments";
            this.saving = false;
        }
        CommentsController.prototype.isSelf = function (comment) {
            return this.$rootScope.user && this.$rootScope.user.email === comment.username;
        };
        CommentsController.prototype.addComment = function (text) {
            var _this = this;
            this.saving = true;
            this.TranslationService.saveComment(this.translation, text).then(function (response) {
                _this.translation.comments.push(response);
                _this.resetForm();
            }).finally(function () {
                _this.saving = false;
            });
        };
        CommentsController.prototype.resetForm = function () {
            this.text = null;
            this.form.$setPristine();
            this.form.$setUntouched();
        };
        CommentsController.prototype.close = function () {
            this.$mdSidenav(this.navId).close();
        };
        return CommentsController;
    }());
    app.CommentsController = CommentsController;
    angular.module("app").controller("CommentsController", CommentsController);
})(app || (app = {}));
var app;
(function (app) {
    var CompareTo = (function () {
        function CompareTo() {
            this.require = "ngModel";
            this.scope = {
                otherModelValue: "=compareTo"
            };
            this.link = function (scope, element, attributes, ngModel) {
                ngModel.$validators.compareTo = function (modelValue) {
                    return modelValue === scope.otherModelValue;
                };
                scope.$watch("otherModelValue", function () {
                    ngModel.$validate();
                });
            };
        }
        CompareTo.factory = function () {
            return function () {
                return new CompareTo();
            };
        };
        return CompareTo;
    }());
    angular.module("app").directive("compareTo", CompareTo.factory());
})(app || (app = {}));
var app;
(function (app) {
    var DialogHeader = (function () {
        function DialogHeader($mdDialog) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.scope = {
                text: "@"
            };
            this.link = function (scope, element, attributes, ngModel) {
                scope.closeDialog = function () {
                    _this.$mdDialog.cancel();
                };
            };
            this.template = "<md-toolbar>" +
                '<div class="md-toolbar-tools">' +
                "<h2 translate>{{text}}</h2>" +
                "<span flex></span>" +
                '<md-button class="md-icon-button" ng-click="closeDialog()">' +
                '<md-icon aria-label="Close dialog">close</md-icon>' +
                "</md-button>" +
                "</div>" +
                "</md-toolbar>";
        }
        DialogHeader.factory = function () {
            return function ($mdDialog) {
                return new DialogHeader($mdDialog);
            };
        };
        return DialogHeader;
    }());
    angular.module("app").directive("mdDialogHeader", DialogHeader.factory());
})(app || (app = {}));
var app;
(function (app) {
    var FocusOn = (function () {
        function FocusOn() {
            this.scope = {
                focusOn: "@"
            };
            this.link = function (scope, element, attributes, ngModel) {
                scope.$on(scope.focusOn, function () {
                    element[0].focus();
                });
            };
        }
        FocusOn.factory = function () {
            return function () {
                return new FocusOn();
            };
        };
        return FocusOn;
    }());
    angular.module("app").directive("focusOn", FocusOn.factory());
})(app || (app = {}));
var app;
(function (app) {
    var IconCarouselController = (function () {
        function IconCarouselController($interval) {
            var _this = this;
            this.$interval = $interval;
            this.icons = [];
            this.text = "";
            this.nextIcon();
            this.timer = $interval(function () { _this.nextIcon(); }, this.interval || 5000);
        }
        IconCarouselController.prototype.nextIcon = function () {
            var idx = this.icons.indexOf(this.icon);
            var nextIdx = (idx === -1 || idx === this.icons.length - 1) ? 0 : idx + 1;
            this.icon = this.icons[nextIdx];
            this.setText(this.icon);
        };
        IconCarouselController.prototype.setText = function (icon) {
            if (icon === "desktop_mac")
                this.text = "Desktop App";
            if (icon === "phone_android")
                this.text = "Mobile App";
            if (icon === "web")
                this.text = "Web site";
        };
        return IconCarouselController;
    }());
    var IconCarousel = (function () {
        function IconCarousel() {
            this.require = "ngMdIcon";
            // tslint:disable-next-line:max-line-length
            this.template = '<div style="color : grey; text-align : center" class="md-display-1">Localize your</div><ng-md-icon style="height : auto !important; width: auto !important;" size="192" options=\'{"rotation": "none", "easing" : "expo-out", "duration" : "700"}\' icon="{{vm.icon}}"></ng-md-icon><div style="color : grey; text-align : center" class="md-display-1">{{vm.text}}</div>';
            this.scope = {
                icons: "=",
                interval: "@",
                animation: "@"
            };
            this.bindToController = true;
            this.controllerAs = "vm";
            this.controller = IconCarouselController;
        }
        IconCarousel.factory = function () {
            return function () {
                return new IconCarousel();
            };
        };
        return IconCarousel;
    }());
    angular.module("app").directive("iconCarousel", IconCarousel.factory());
})(app || (app = {}));
var app;
(function (app) {
    /**
     * will set a default image if we get a 404 on image src
     */
    var ImageNotFound = (function () {
        function ImageNotFound() {
            this.link = function (scope, element, attrs) {
                element.bind("error", function () {
                    if (attrs.src !== attrs.errSrc) {
                        attrs.$set("src", attrs.errSrc);
                    }
                });
            };
        }
        ImageNotFound.factory = function () {
            return function () {
                return new ImageNotFound();
            };
        };
        return ImageNotFound;
    }());
    angular.module("app").directive("errSrc", ImageNotFound.factory());
})(app || (app = {}));
var app;
(function (app) {
    /**
     * Description
     * The loader component shows a spinning loading icons indicating that an operation is in progress
     *
     * Example of usage
     * <loader size="5"></loader> Loader will render in size 5 show at all times
     * <loader events="dataloading|bootstrapping"></loader> Loader will only show in
     * case of $rootScope.dataloading & $rootScope.bootstrapping is true
     */
    var Loader = (function () {
        /*@ngInject*/
        function Loader($rootScope) {
            var _this = this;
            this.$rootScope = $rootScope;
            this.scope = { events: "@" };
            this.restrict = "AE";
            this.link = function (scope, element) {
                if (scope.events != null) {
                    var parts = scope.events.split("|");
                    parts.forEach(function (event) {
                        _this.$rootScope.$watch(event, function (n, o) {
                            (n === true) ? element.show() : element.hide();
                        });
                    });
                }
            };
        }
        Loader.prototype.template = function (element, attrs) {
            if (attrs.size == null)
                attrs.size = 5;
            return '<i class="fa fa-cog fa-spin fa-' + attrs.size + 'x" style="color: white;"><li>';
        };
        Loader.factory = function () {
            return function ($rootScope) {
                return new Loader($rootScope);
            };
        };
        return Loader;
    }());
    angular.module("app").directive("loader", Loader.factory());
})(app || (app = {}));
var app;
(function (app) {
    /**
     * Will trigger the method specific in attributes "on-enter" when pressing the enter key
     * Used on inputs for submitting /executing on enter
     */
    var OnEnter = (function () {
        function OnEnter() {
            this.scope = {
                onEnter: "&"
            };
            this.link = function (scope, element) {
                element.bind("keydown keypress", function (event) {
                    var keyCode = event.which || event.keyCode;
                    // Enter is pressed
                    if (keyCode === 13) {
                        scope.onEnter();
                        scope.$apply();
                        event.preventDefault();
                    }
                });
            };
        }
        OnEnter.factory = function () {
            return function () {
                return new OnEnter();
            };
        };
        return OnEnter;
    }());
    angular.module("app").directive("onEnter", OnEnter.factory());
})(app || (app = {}));
var app;
(function (app) {
    var RestrictToAdmin = (function () {
        /*@ngInject*/
        function RestrictToAdmin(ngIfDirective, $rootScope) {
            var _this = this;
            this.$rootScope = $rootScope;
            this.link = function (scope, element, $attr) {
                if (_this.$rootScope.user && _this.$rootScope.user.admin) {
                    $attr.ngIf = function () {
                        return false;
                    };
                    _this.ngIf.link.apply(_this.ngIf);
                }
            };
            this.ngIf = ngIfDirective[0];
        }
        RestrictToAdmin.factory = function () {
            return function (ngIfDirective, $rootScope) {
                return new RestrictToAdmin(ngIfDirective, $rootScope);
            };
        };
        return RestrictToAdmin;
    }());
    angular.module("app").directive("restrictToAdmin", RestrictToAdmin.factory());
})(app || (app = {}));
var app;
(function (app) {
    var ValidateEmail = (function () {
        function ValidateEmail(UserService, $q) {
            var _this = this;
            this.UserService = UserService;
            this.$q = $q;
            this.require = "ngModel";
            this.link = function (scope, element, attributes, ngModel) {
                ngModel.$asyncValidators["duplicate"] = function (modelValue) {
                    return _this.UserService.checkEmailValidity(modelValue).then(function (response) {
                        if (response.count === 0)
                            return true;
                        return _this.$q.reject("Email already exists");
                    });
                };
                scope.$watch("email", function () {
                    ngModel.$validate();
                });
            };
        }
        ValidateEmail.factory = function () {
            return function (UserService, $q) {
                return new ValidateEmail(UserService, $q);
            };
        };
        return ValidateEmail;
    }());
    angular.module("app").directive("validateEmail", ValidateEmail.factory());
})(app || (app = {}));
var app;
(function (app) {
    var CountryService = (function () {
        function CountryService() {
            this.countries = [
                { name: "Afghanistan", code: "AF" },
                { name: "Åland Islands", code: "AX" },
                { name: "Albania", code: "AL" },
                { name: "Algeria", code: "DZ" },
                { name: "American Samoa", code: "AS" },
                { name: "AndorrA", code: "AD" },
                { name: "Angola", code: "AO" },
                { name: "Anguilla", code: "AI" },
                { name: "Antarctica", code: "AQ" },
                { name: "Antigua and Barbuda", code: "AG" },
                { name: "Argentina", code: "AR" },
                { name: "Armenia", code: "AM" },
                { name: "Aruba", code: "AW" },
                { name: "Australia", code: "AU" },
                { name: "Austria", code: "AT" },
                { name: "Azerbaijan", code: "AZ" },
                { name: "Bahamas", code: "BS" },
                { name: "Bahrain", code: "BH" },
                { name: "Bangladesh", code: "BD" },
                { name: "Barbados", code: "BB" },
                { name: "Belarus", code: "BY" },
                { name: "Belgium", code: "BE" },
                { name: "Belize", code: "BZ" },
                { name: "Benin", code: "BJ" },
                { name: "Bermuda", code: "BM" },
                { name: "Bhutan", code: "BT" },
                { name: "Bolivia", code: "BO" },
                { name: "Bosnia and Herzegovina", code: "BA" },
                { name: "Botswana", code: "BW" },
                { name: "Bouvet Island", code: "BV" },
                { name: "Brazil", code: "BR" },
                { name: "British Indian Ocean Territory", code: "IO" },
                { name: "Brunei Darussalam", code: "BN" },
                { name: "Bulgaria", code: "BG" },
                { name: "Burkina Faso", code: "BF" },
                { name: "Burundi", code: "BI" },
                { name: "Cambodia", code: "KH" },
                { name: "Cameroon", code: "CM" },
                { name: "Canada", code: "CA" },
                { name: "Cape Verde", code: "CV" },
                { name: "Cayman Islands", code: "KY" },
                { name: "Central African Republic", code: "CF" },
                { name: "Chad", code: "TD" },
                { name: "Chile", code: "CL" },
                { name: "China", code: "CN" },
                { name: "Christmas Island", code: "CX" },
                { name: "Cocos (Keeling) Islands", code: "CC" },
                { name: "Colombia", code: "CO" },
                { name: "Comoros", code: "KM" },
                { name: "Congo", code: "CG" },
                { name: "Congo, The Democratic Republic of the", code: "CD" },
                { name: "Cook Islands", code: "CK" },
                { name: "Costa Rica", code: "CR" },
                { name: "Cote D\"Ivoire", code: "CI" },
                { name: "Croatia", code: "HR" },
                { name: "Cuba", code: "CU" },
                { name: "Cyprus", code: "CY" },
                { name: "Czech Republic", code: "CZ" },
                { name: "Denmark", code: "DK" },
                { name: "Djibouti", code: "DJ" },
                { name: "Dominica", code: "DM" },
                { name: "Dominican Republic", code: "DO" },
                { name: "Ecuador", code: "EC" },
                { name: "Egypt", code: "EG" },
                { name: "El Salvador", code: "SV" },
                { name: "Equatorial Guinea", code: "GQ" },
                { name: "Eritrea", code: "ER" },
                { name: "Estonia", code: "EE" },
                { name: "Ethiopia", code: "ET" },
                { name: "Falkland Islands (Malvinas)", code: "FK" },
                { name: "Faroe Islands", code: "FO" },
                { name: "Fiji", code: "FJ" },
                { name: "Finland", code: "FI" },
                { name: "France", code: "FR" },
                { name: "French Guiana", code: "GF" },
                { name: "French Polynesia", code: "PF" },
                { name: "French Southern Territories", code: "TF" },
                { name: "Gabon", code: "GA" },
                { name: "Gambia", code: "GM" },
                { name: "Georgia", code: "GE" },
                { name: "Germany", code: "DE" },
                { name: "Ghana", code: "GH" },
                { name: "Gibraltar", code: "GI" },
                { name: "Greece", code: "GR" },
                { name: "Greenland", code: "GL" },
                { name: "Grenada", code: "GD" },
                { name: "Guadeloupe", code: "GP" },
                { name: "Guam", code: "GU" },
                { name: "Guatemala", code: "GT" },
                { name: "Guernsey", code: "GG" },
                { name: "Guinea", code: "GN" },
                { name: "Guinea-Bissau", code: "GW" },
                { name: "Guyana", code: "GY" },
                { name: "Haiti", code: "HT" },
                { name: "Heard Island and Mcdonald Islands", code: "HM" },
                { name: "Holy See (Vatican City State)", code: "VA" },
                { name: "Honduras", code: "HN" },
                { name: "Hong Kong", code: "HK" },
                { name: "Hungary", code: "HU" },
                { name: "Iceland", code: "IS" },
                { name: "India", code: "IN" },
                { name: "Indonesia", code: "ID" },
                { name: "Iran, Islamic Republic Of", code: "IR" },
                { name: "Iraq", code: "IQ" },
                { name: "Ireland", code: "IE" },
                { name: "Isle of Man", code: "IM" },
                { name: "Israel", code: "IL" },
                { name: "Italy", code: "IT" },
                { name: "Jamaica", code: "JM" },
                { name: "Japan", code: "JP" },
                { name: "Jersey", code: "JE" },
                { name: "Jordan", code: "JO" },
                { name: "Kazakhstan", code: "KZ" },
                { name: "Kenya", code: "KE" },
                { name: "Kiribati", code: "KI" },
                { name: "Korea, Democratic People\"S Republic of", code: "KP" },
                { name: "Korea, Republic of", code: "KR" },
                { name: "Kuwait", code: "KW" },
                { name: "Kyrgyzstan", code: "KG" },
                { name: "Lao People\"S Democratic Republic", code: "LA" },
                { name: "Latvia", code: "LV" },
                { name: "Lebanon", code: "LB" },
                { name: "Lesotho", code: "LS" },
                { name: "Liberia", code: "LR" },
                { name: "Libyan Arab Jamahiriya", code: "LY" },
                { name: "Liechtenstein", code: "LI" },
                { name: "Lithuania", code: "LT" },
                { name: "Luxembourg", code: "LU" },
                { name: "Macao", code: "MO" },
                { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
                { name: "Madagascar", code: "MG" },
                { name: "Malawi", code: "MW" },
                { name: "Malaysia", code: "MY" },
                { name: "Maldives", code: "MV" },
                { name: "Mali", code: "ML" },
                { name: "Malta", code: "MT" },
                { name: "Marshall Islands", code: "MH" },
                { name: "Martinique", code: "MQ" },
                { name: "Mauritania", code: "MR" },
                { name: "Mauritius", code: "MU" },
                { name: "Mayotte", code: "YT" },
                { name: "Mexico", code: "MX" },
                { name: "Micronesia, Federated States of", code: "FM" },
                { name: "Moldova, Republic of", code: "MD" },
                { name: "Monaco", code: "MC" },
                { name: "Mongolia", code: "MN" },
                { name: "Montserrat", code: "MS" },
                { name: "Morocco", code: "MA" },
                { name: "Mozambique", code: "MZ" },
                { name: "Myanmar", code: "MM" },
                { name: "Namibia", code: "NA" },
                { name: "Nauru", code: "NR" },
                { name: "Nepal", code: "NP" },
                { name: "Netherlands", code: "NL" },
                { name: "Netherlands Antilles", code: "AN" },
                { name: "New Caledonia", code: "NC" },
                { name: "New Zealand", code: "NZ" },
                { name: "Nicaragua", code: "NI" },
                { name: "Niger", code: "NE" },
                { name: "Nigeria", code: "NG" },
                { name: "Niue", code: "NU" },
                { name: "Norfolk Island", code: "NF" },
                { name: "Northern Mariana Islands", code: "MP" },
                { name: "Norway", code: "NO" },
                { name: "Oman", code: "OM" },
                { name: "Pakistan", code: "PK" },
                { name: "Palau", code: "PW" },
                { name: "Palestinian Territory, Occupied", code: "PS" },
                { name: "Panama", code: "PA" },
                { name: "Papua New Guinea", code: "PG" },
                { name: "Paraguay", code: "PY" },
                { name: "Peru", code: "PE" },
                { name: "Philippines", code: "PH" },
                { name: "Pitcairn", code: "PN" },
                { name: "Poland", code: "PL" },
                { name: "Portugal", code: "PT" },
                { name: "Puerto Rico", code: "PR" },
                { name: "Qatar", code: "QA" },
                { name: "Reunion", code: "RE" },
                { name: "Romania", code: "RO" },
                { name: "Russian Federation", code: "RU" },
                { name: "RWANDA", code: "RW" },
                { name: "Saint Helena", code: "SH" },
                { name: "Saint Kitts and Nevis", code: "KN" },
                { name: "Saint Lucia", code: "LC" },
                { name: "Saint Pierre and Miquelon", code: "PM" },
                { name: "Saint Vincent and the Grenadines", code: "VC" },
                { name: "Samoa", code: "WS" },
                { name: "San Marino", code: "SM" },
                { name: "Sao Tome and Principe", code: "ST" },
                { name: "Saudi Arabia", code: "SA" },
                { name: "Senegal", code: "SN" },
                { name: "Serbia and Montenegro", code: "CS" },
                { name: "Seychelles", code: "SC" },
                { name: "Sierra Leone", code: "SL" },
                { name: "Singapore", code: "SG" },
                { name: "Slovakia", code: "SK" },
                { name: "Slovenia", code: "SI" },
                { name: "Solomon Islands", code: "SB" },
                { name: "Somalia", code: "SO" },
                { name: "South Africa", code: "ZA" },
                { name: "South Georgia and the South Sandwich Islands", code: "GS" },
                { name: "Spain", code: "ES" },
                { name: "Sri Lanka", code: "LK" },
                { name: "Sudan", code: "SD" },
                { name: "Suriname", code: "SR" },
                { name: "Svalbard and Jan Mayen", code: "SJ" },
                { name: "Swaziland", code: "SZ" },
                { name: "Sweden", code: "SE" },
                { name: "Switzerland", code: "CH" },
                { name: "Syrian Arab Republic", code: "SY" },
                { name: "Taiwan, Province of China", code: "TW" },
                { name: "Tajikistan", code: "TJ" },
                { name: "Tanzania, United Republic of", code: "TZ" },
                { name: "Thailand", code: "TH" },
                { name: "Timor-Leste", code: "TL" },
                { name: "Togo", code: "TG" },
                { name: "Tokelau", code: "TK" },
                { name: "Tonga", code: "TO" },
                { name: "Trinidad and Tobago", code: "TT" },
                { name: "Tunisia", code: "TN" },
                { name: "Turkey", code: "TR" },
                { name: "Turkmenistan", code: "TM" },
                { name: "Turks and Caicos Islands", code: "TC" },
                { name: "Tuvalu", code: "TV" },
                { name: "Uganda", code: "UG" },
                { name: "Ukraine", code: "UA" },
                { name: "United Arab Emirates", code: "AE" },
                { name: "United Kingdom", code: "GB" },
                { name: "United States", code: "US" },
                { name: "United States Minor Outlying Islands", code: "UM" },
                { name: "Uruguay", code: "UY" },
                { name: "Uzbekistan", code: "UZ" },
                { name: "Vanuatu", code: "VU" },
                { name: "Venezuela", code: "VE" },
                { name: "Viet Nam", code: "VN" },
                { name: "Virgin Islands, British", code: "VG" },
                { name: "Virgin Islands, U.S.", code: "VI" },
                { name: "Wallis and Futuna", code: "WF" },
                { name: "Western Sahara", code: "EH" },
                { name: "Yemen", code: "YE" },
                { name: "Zambia", code: "ZM" },
                { name: "Zimbabwe", code: "ZW" }
            ];
        }
        CountryService.prototype.getCountryList = function () {
            return this.countries;
        };
        return CountryService;
    }());
    app.CountryService = CountryService;
    angular.module("app").service("CountryService", CountryService);
})(app || (app = {}));
var app;
(function (app) {
    var C = (function () {
        function C() {
        }
        C.or = function () {
            var conditions = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                conditions[_i] = arguments[_i];
            }
            var or = { $or: [] };
            conditions.forEach(function (cond) {
                or.$or.push(cond);
            });
            return or;
        };
        C.and = function () {
            var conditions = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                conditions[_i] = arguments[_i];
            }
            var and = { $and: [] };
            conditions.forEach(function (cond) {
                and.$and.push(cond);
            });
            return and;
        };
        C.in = function (property, strings) {
            var crit = {};
            crit[property] = { $in: strings };
            return crit;
        };
        C.equals = function (property, value) {
            var crit = {};
            crit[property] = { $eq: value };
            return crit;
        };
        C.elementMatch = function (property, match) {
            var crit = {};
            crit[property] = { $elemMatch: match };
            return crit;
        };
        C.notElementMatch = function (property, match) {
            var crit = {};
            crit[property] = { $not: { $elemMatch: match } };
            return crit;
        };
        C.notEquals = function (property, value) {
            var crit = {};
            crit[property] = { $ne: value };
            return crit;
        };
        C.regex = function (property, value) {
            var crit = {};
            crit[property] = value;
            return crit;
        };
        C.contains = function (property, value) {
            var crit = {};
            crit[property] = { $regex: value };
            return crit;
        };
        C.lt = function (property, value) {
            var crit = {};
            crit[property] = { $lt: value };
            return crit;
        };
        C.lte = function (property, value) {
            var crit = {};
            crit[property] = { $lte: value };
            return crit;
        };
        C.gt = function (property, value) {
            var crit = {};
            crit[property] = { $gt: value };
            return crit;
        };
        C.gte = function (property, value) {
            var crit = {};
            crit[property] = { $gte: value };
            return crit;
        };
        return C;
    }());
    app.C = C;
})(app || (app = {}));
var app;
(function (app) {
    var ModalService = (function () {
        function ModalService($mdDialog, LanguageService, ProjectService) {
            this.$mdDialog = $mdDialog;
            this.LanguageService = LanguageService;
            this.ProjectService = ProjectService;
        }
        ModalService.prototype.showImportModal = function (event, project) {
            var _this = this;
            this.$mdDialog.show({
                controller: app.ProjectImportController,
                templateUrl: "src/features/projects/project-import-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: function (LanguageService) {
                        return _this.LanguageService.findAll();
                    }
                },
                locals: {
                    project: project
                }
            });
        };
        ModalService.prototype.showContributorDetailsModal = function (user, event) {
            var _this = this;
            this.$mdDialog.show({
                controller: app.ContributorDetailsController,
                templateUrl: "src/features/users/contributor-details.modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    userProjects: function () {
                        return _this.ProjectService.getContributorProjects(user._id);
                    }
                },
                locals: {
                    contributor: user
                }
            });
        };
        return ModalService;
    }());
    app.ModalService = ModalService;
    angular.module("app").service("ModalService", ModalService);
})(app || (app = {}));
var app;
(function (app) {
    var BaseEntity = (function () {
        function BaseEntity() {
            this.attributes = {
                is_new: true
            };
        }
        return BaseEntity;
    }());
    app.BaseEntity = BaseEntity;
    var ProjectActivity = (function () {
        function ProjectActivity() {
        }
        return ProjectActivity;
    }());
    app.ProjectActivity = ProjectActivity;
    var LicensePlan = (function () {
        function LicensePlan() {
        }
        return LicensePlan;
    }());
    app.LicensePlan = LicensePlan;
    var Project = (function () {
        function Project() {
            this.languages = [];
            this.is_public = false;
            this.contributors = [];
            this.translations = [];
            this.translations_count = [];
        }
        return Project;
    }());
    app.Project = Project;
    var ProjectContributor = (function () {
        function ProjectContributor() {
        }
        return ProjectContributor;
    }());
    app.ProjectContributor = ProjectContributor;
    var TranslationCount = (function () {
        function TranslationCount() {
        }
        return TranslationCount;
    }());
    app.TranslationCount = TranslationCount;
    var Language = (function () {
        function Language() {
            this.delete_time = null;
        }
        return Language;
    }());
    app.Language = Language;
    var KeyDefinition = (function () {
        function KeyDefinition() {
            this.tags = [];
            this.comments = [];
        }
        return KeyDefinition;
    }());
    app.KeyDefinition = KeyDefinition;
    var Translation = (function () {
        function Translation() {
            this.tags = [];
            this.comments = [];
            this.definitions = [];
            this.delete_time = null;
        }
        return Translation;
    }());
    app.Translation = Translation;
    var Definition = (function () {
        function Definition() {
            this.fuzzy = false;
            this.reviewed = false;
            this.delete_time = null;
            this._id = new ObjectId().toString();
        }
        return Definition;
    }());
    app.Definition = Definition;
    var DefinitionHistory = (function () {
        function DefinitionHistory() {
        }
        return DefinitionHistory;
    }());
    app.DefinitionHistory = DefinitionHistory;
    var Comment = (function () {
        function Comment() {
        }
        return Comment;
    }());
    app.Comment = Comment;
    var User = (function () {
        function User() {
            this.admin = false;
            this.reviewer = false;
            this.contributor = false;
            this.projects = [];
            this.delete_time = null;
        }
        return User;
    }());
    app.User = User;
    var Contributor = (function (_super) {
        __extends(Contributor, _super);
        function Contributor() {
            var _this = _super.call(this) || this;
            _this.contributor = true;
            return _this;
        }
        return Contributor;
    }(User));
    app.Contributor = Contributor;
    var UserProject = (function () {
        function UserProject() {
        }
        return UserProject;
    }());
    app.UserProject = UserProject;
    var Account = (function () {
        function Account() {
        }
        return Account;
    }());
    app.Account = Account;
})(app || (app = {}));
var app;
(function (app) {
    var Query = (function () {
        function Query() {
            this._select = null;
            this._sort = null;
            this._skip = null;
            this._limit = null;
            this._count = false;
            this._distinct = null;
            this._populate = [];
            this._query = { $and: [] };
        }
        Query.prototype.isQueryEmpty = function () {
            return (this._query.$and.length === 0);
        };
        Query.prototype.addCriteria = function (criteria) {
            this._query.$and.push(criteria);
            return this;
        };
        Query.prototype.select = function (fields) {
            var _this = this;
            this._select = {};
            fields.forEach(function (field) {
                _this._select[field] = 1;
            });
            return this;
        };
        Query.prototype.query = function (query) {
            this._query = query;
            return this;
        };
        Query.prototype.populate = function (relations) {
            this._populate = relations;
            return this;
        };
        Query.prototype.distinct = function (property) {
            this._distinct = property;
            return this;
        };
        Query.prototype.sort = function (property) {
            this._sort = property;
            return this;
        };
        Query.prototype.skip = function (skip) {
            this._skip = skip;
            return this;
        };
        Query.prototype.limit = function (limit) {
            this._limit = limit;
            return this;
        };
        Query.prototype.removePagination = function () {
            this._skip = null;
            this._limit = null;
            return this;
        };
        Query.prototype.pagination = function (pagination) {
            this.limit(pagination.items_per_page);
            this.skip((pagination.current_page - 1) * pagination.items_per_page);
            return this;
        };
        Query.prototype.toFilter = function () {
            var filter = {};
            filter.query = (this.isQueryEmpty()) ? {} : this._query;
            var relations = "";
            this._populate.forEach(function (relation) {
                relations += "," + relation;
            });
            relations = relations.replace(/(^,)|(,$)/g, "");
            if (relations.length > 0)
                filter.populate = relations;
            if (this._skip != null)
                filter.skip = this._skip;
            if (this._limit != null)
                filter.limit = this._limit;
            filter.limit = 5000;
            if (this._sort != null)
                filter.sort = this._sort;
            if (this._distinct != null)
                filter.distinct = this._distinct;
            if (!this._count) {
                if (this._select != null)
                    filter.select = this._select;
            }
            return filter;
        };
        return Query;
    }());
    app.Query = Query;
})(app || (app = {}));
var app;
(function (app) {
    var ToasterService = (function () {
        function ToasterService($mdToast, $filter) {
            this.$mdToast = $mdToast;
            this.$filter = $filter;
        }
        ToasterService.prototype.pop = function (message, duration) {
            if (duration === void 0) { duration = 5000; }
            this.$mdToast.show(this.$mdToast.simple().hideDelay(duration).textContent(this.$filter("translate")(message)));
        };
        return ToasterService;
    }());
    app.ToasterService = ToasterService;
    angular.module("app").service("ToasterService", ToasterService);
})(app || (app = {}));
var app;
(function (app) {
    var TrackingService = (function () {
        function TrackingService($window, $rootScope) {
            this.$window = $window;
            this.$rootScope = $rootScope;
        }
        TrackingService.prototype.trackImport = function (data) {
            this.track("Import", "Project: " + data.project + ", Format: " + data.format + ", Language: " + data.language);
        };
        TrackingService.prototype.trackLogin = function (data) {
            this.track("Login", "User: " + data.email);
        };
        TrackingService.prototype.trackAddNewDefinition = function (data) {
            this.track("Add translation", "Text: " + data.text + ", Language: " + data.language);
        };
        TrackingService.prototype.trackAddProject = function (data) {
            this.track("Add Project", "Name: " + data.name + ", Languages: " + data.language);
        };
        TrackingService.prototype.trackExport = function (data) {
            this.track("Export", "Project: " + data.project + ", Language: " + data.language + ", Format: " + data.format);
        };
        TrackingService.prototype.track = function (action, label) {
            var event = {
                hitType: "event",
                eventCategory: this.$rootScope.user.email,
                eventAction: action,
                eventLabel: label
            };
            console.log(event);
            this.$window.ga("send", event);
        };
        return TrackingService;
    }());
    app.TrackingService = TrackingService;
    angular.module("app").service("TrackingService", TrackingService);
})(app || (app = {}));
var app;
(function (app) {
    var UtilityService = (function () {
        function UtilityService() {
        }
        UtilityService.prototype.uid = function () {
            var s4 = function () {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            };
            return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
        };
        return UtilityService;
    }());
    app.UtilityService = UtilityService;
    angular.module("app").service("UtilityService", UtilityService);
})(app || (app = {}));
var app;
(function (app) {
    /**
     * All custom filters should be defined in this class
     * Note each filter must be added in the button of the file
     */
    var CustomFilters = (function () {
        function CustomFilters() {
        }
        CustomFilters.elipsis = function () {
            return function (input, length) {
                if (input == null)
                    return input;
                if (input.length > length) {
                    return input.substring(0, length) + "...";
                }
                return input;
            };
        };
        CustomFilters.booleanToYesNo = function ($filter) {
            return function (input) {
                if (input === true)
                    return $filter("translate")("app.yes");
                return $filter("translate")("app.no");
            };
        };
        CustomFilters.numberToInfinite = function () {
            return function (input) {
                if (parseInt(input, 10) > 5) {
                    return "Unlimitted";
                }
                return input;
            };
        };
        return CustomFilters;
    }());
    app.CustomFilters = CustomFilters;
    angular.module("app").filter("elipsis", CustomFilters.elipsis);
    angular.module("app").filter("booleanToYesNo", CustomFilters.booleanToYesNo);
    angular.module("app").filter("numToInf", CustomFilters.numberToInfinite);
})(app || (app = {}));
var app;
(function (app) {
    var Confirm = (function () {
        /*@ngInject*/
        function Confirm($mdDialog) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.scope = {
                accept: "&",
                cTitle: "@",
                cBody: "@"
            };
            this.link = function (scope, element) {
                element.bind("click", function (ev) {
                    if (!scope.cTitle)
                        scope.cTitle = "Are you sure?";
                    if (!scope.cBody)
                        scope.cBody = "Do you want to proceed?";
                    var confirm = _this.$mdDialog.confirm()
                        .title(scope.cTitle)
                        .textContent(scope.cBody)
                        .ariaLabel("Confirmation")
                        .targetEvent(ev)
                        .ok("OK")
                        .cancel("CANCEL");
                    _this.$mdDialog.show(confirm).then(function () {
                        scope.accept();
                    });
                });
            };
        }
        Confirm.factory = function () {
            return function ($mdDialog) {
                return new Confirm($mdDialog);
            };
        };
        return Confirm;
    }());
    Confirm.$inject = ["$mdDialog"];
    angular.module("app").directive("confirm", Confirm.factory());
})(app || (app = {}));
var app;
(function (app) {
    var LoadingButton = (function () {
        function LoadingButton() {
            this.templateUrl = "src/components/loading-button/loading-button.html";
            this.scope = {
                isLoading: "=",
                btnText: "@",
                btnClass: "@",
                loaderClass: "@",
                loaderDiameter: "@",
                action: "&",
                ngDisabled: "="
            };
            this.link = function (scope, element) {
                scope.loaderClass = (scope.loaderClass) ? scope.loaderClass : "md-accent md-hue-1";
                scope.btnClass = (scope.btnClass) ? scope.btnClass : "md-primary md-raised";
                scope.loaderDiameter = (scope.loaderDiameter) ? scope.loaderDiameter : "35";
            };
        }
        LoadingButton.factory = function () {
            return function () {
                return new LoadingButton();
            };
        };
        return LoadingButton;
    }());
    angular.module("app").directive("mdLoadingButton", LoadingButton.factory());
})(app || (app = {}));
