namespace app {

	export class AccountController {

		constructor(
			private AccountService: AccountService,
			private ProjectService: ProjectService,
			private UserService: UserService,
			private account: Account,
			private $mdDialog: any) { }
	}
	angular.module("app").controller("AccountController", AccountController);
}