namespace app {

    class CommentsComponent implements ng.IDirective {
        static factory() {
            return () => {
                return new CommentsComponent();
            };
        }
        scope = {
            translation: "="
        };
        controllerAs = "vm";
        bindToController = true;
        controller = CommentsController;
        templateUrl = "src/features/translations/comments/comments.html";
    }
    angular.module("app").directive("comments", CommentsComponent.factory());
}