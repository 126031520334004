namespace app {

    class ProjectActivityComponent implements ng.IDirective {
        static factory() {
            return () => {
                return new ProjectActivityComponent();
            };
        }
        scope = { navId: "@" };
        controllerAs = "vm";
        bindToController = true;
        controller = ProjectActivityController;
        templateUrl = "src/features/projects/project-activity/project-activity.html";
    }
    angular.module("app").directive("projectActivity", ProjectActivityComponent.factory());
}