namespace app {

    class Confirm implements ng.IDirective {
        static factory() {
            return ($mdDialog: any) => {
                return new Confirm($mdDialog);
            };
        }
        /*@ngInject*/
        constructor(private $mdDialog: ng.material.IDialogService) {
        }
        scope = {
            accept: "&",
            cTitle: "@",
            cBody: "@"
        };

        link = (scope: any, element: JQuery) => {

            element.bind("click", (ev) => {

                if (!scope.cTitle)
                    scope.cTitle = "Are you sure?";
                if (!scope.cBody)
                    scope.cBody = "Do you want to proceed?";

                let confirm = this.$mdDialog.confirm()
                    .title(scope.cTitle)
                    .textContent(scope.cBody)
                    .ariaLabel("Confirmation")
                    .targetEvent(ev as any)
                    .ok("OK")
                    .cancel("CANCEL");
                this.$mdDialog.show(confirm).then(() => {
                    scope.accept();
                });
            });
        }
    }
    Confirm.$inject = ["$mdDialog"];
    angular.module("app").directive("confirm", Confirm.factory());
}