namespace app {
    export class CommentsController {
        private text: string | null;
        private form: ng.IFormController;
        private navId: string = "comments";
        private translation: Translation;
        private saving: boolean = false;

        constructor(
            private $rootScope: IRootScope,
            private TranslationService: TranslationService,
            private $mdSidenav: ng.material.ISidenavService) {}

        isSelf(comment: Comment) {
            return this.$rootScope.user && this.$rootScope.user.email === comment.username;
        }

        addComment(text: string) {
            this.saving = true;
            this.TranslationService.saveComment(this.translation, text).then((response) => {
                this.translation.comments.push(response);
                this.resetForm();
            }).finally(() => {
                this.saving = false;
            });
        }

        resetForm() {
            this.text = null;
            this.form.$setPristine();
            this.form.$setUntouched();
        }

        close() {
            this.$mdSidenav(this.navId).close();
        }
    }
    angular.module("app").controller("CommentsController", CommentsController);
}