namespace app {
	export class Query {
		private _select: any = null;
		private _sort: string | null = null;
		private _skip: number | null = null;
		private _limit: number | null = null;
		private _count: boolean = false;
		private _distinct: string | null = null;
		private _populate: string[] = [];
		private _query: any = { $and: [] };

		isQueryEmpty() {
			return (this._query.$and.length === 0);
		}

		addCriteria(criteria: any) {
			this._query.$and.push(criteria);
			return this;
		}

		select(fields: string[]) {
			this._select = {};
			fields.forEach((field) => {
				this._select[field] = 1;
			});
			return this;
		}

		query(query: any) {
			this._query = query;
			return this;
		}

		populate(relations: string[]) {
			this._populate = relations;
			return this;
		}

		distinct(property: string) {
			this._distinct = property;
			return this;
		}

		sort(property: string) {
			this._sort = property;
			return this;
		}

		skip(skip: number) {
			this._skip = skip;
			return this;
		}

		limit(limit: number) {
			this._limit = limit;
			return this;
		}

		removePagination() {
			this._skip = null;
			this._limit = null;
			return this;
		}

		pagination(pagination: IPagination) {
			this.limit(pagination.items_per_page);
			this.skip((pagination.current_page - 1) * pagination.items_per_page);
			return this;
		}

		toFilter() {
			let filter: any = {};
			filter.query = (this.isQueryEmpty()) ? {} : this._query;
			let relations = "";
			this._populate.forEach((relation) => {
				relations += "," + relation;
			});
			relations = relations.replace(/(^,)|(,$)/g, "");
			if (relations.length > 0)
				filter.populate = relations;
			if (this._skip != null)
				filter.skip = this._skip;
			if (this._limit != null)
				filter.limit = this._limit;
			filter.limit = 5000;
			if (this._sort != null)
				filter.sort = this._sort;
			if (this._distinct != null)
				filter.distinct = this._distinct;
			if (!this._count) {
				if (this._select != null)
					filter.select = this._select;
			}

			return filter;
		}
	}
}