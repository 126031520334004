namespace app {
	export class InviteController {
		public form: ng.IFormController;
		public email: string;
		constructor(
			private $mdToast: ng.material.IToastService,
			private $rootScope: IRootScope,
			private AuthService: AuthService) {
				this.email = this.$rootScope.inviteEmail!;
		}

		createUser(password: string, repassword: string) {
			let token = this.$rootScope.inviteToken;

			if (!token)
				return this.$mdToast.show(this.$mdToast.simple().textContent("Upss something went wrong"));
			if (this.form.$invalid)
				return;
			this.AuthService.confirmInvite(token, password, repassword);
		}
	}
	angular.module("app").controller("InviteController", InviteController);
}