namespace app {
	export class FeedbackController {

        public saving: boolean = false;
        public subject: string;
        public description: string;
        public form: ng.IFormController;

        /** @ngInject */
        constructor(
            private FeedbackService: FeedbackService,
            private $state: ng.ui.IStateService,
            private $mdDialog: ng.material.IDialogService) {}

        submit() {
            this.saving = true;
            this.FeedbackService.sendFeedback(this.subject, this.description).then(() => {
                this.saving = false;
                this.close();
            });
		}

        close() {
            this.$mdDialog.hide();
        }
	}

	angular.module("app").controller("LanguageController", LanguageController);
}