namespace app {
	export class AppController {

		public user: User | undefined;

		/*@ngInject*/
		constructor(
			private $translate: ng.translate.ITranslateService,
			private $mdDialog: any,
			private localStorageService: angular.local.storage.ILocalStorageService,
			private $rootScope: IRootScope,
			private $state: ng.ui.IStateService) {

			this.user = $rootScope.user;
		}

		getUser() {
			return this.localStorageService.get("user");
		}

		logout() {
			this.localStorageService.clearAll();
			this.user = undefined;
			this.$rootScope.user = undefined;
			this.$state.go("pages.login");
		}

		openFeedbackModal() {
			console.log("FEEDBACK")
			this.$mdDialog.show({
                controller: FeedbackController,
                templateUrl: "src/features/feedback/feedback.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true
            });
		}
	}
}