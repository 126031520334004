namespace app {
    export class TrackingService {

        constructor(
            private $window: any,
            private $rootScope: IRootScope
        ) {}

        trackImport(data) {
            this.track("Import", "Project: " + data.project + ", Format: " + data.format + ", Language: " + data.language);
        }

        trackLogin(data) {
            this.track("Login", "User: " + data.email);
        }

        trackAddNewDefinition(data) {
            this.track("Add translation", "Text: " + data.text + ", Language: " + data.language);
        }

        trackAddProject(data) {
            this.track("Add Project", "Name: " + data.name + ", Languages: " + data.language);
        }

        trackExport(data) {
            this.track("Export", "Project: " + data.project + ", Language: " + data.language  + ", Format: " + data.format);
        }

        private track(action, label) {
            const event = {
                hitType: "event",
                eventCategory: this.$rootScope.user!.email,
                eventAction: action,
                eventLabel: label
            };
            console.log(event);
            this.$window.ga("send", event);
        }
    }
    angular.module("app").service("TrackingService", TrackingService);
}