namespace app {

    export class AddContributorController {

        public language: Language | null = null;
        public email: string | null = null;
        public is_email_valid: boolean | null = null;
        public form: ng.IFormController;
        public saving: boolean = false;

        constructor(
            private UserService: UserService,
            private ProjectService: ProjectService,
            private ToasterService: ToasterService,
            private users: User[],
            private project: Project,
            private $mdDialog: ng.material.IDialogService,
            private $rootScope: IRootScope) { }

        isSelf(user: User) {
            if (this.$rootScope.user && user.email === this.$rootScope.user.email)
                return true;
            return false;
        }

        /**
          * Returns the languages a user has access to
          */
        getContributorLanguages(user: User) {
            let langs: string[] = this.project.contributors
                .filter(contrib => contrib.user_id === user._id)
                .map(contrib => contrib.language_id);
            (user as any).language_count = langs.length;
            return langs;
        }

        /**
         * Get available languages for a specific user
         */
        getUnusedUserLanguages(user: User) {
            let langs: string[] = [];
            this.project.languages.forEach(language => {
                let hasLanguage = false;
                this.project.contributors.forEach(contrib => {
                    if (contrib.user_id === user._id) {
                        if (contrib.language_id === language._id) {
                            hasLanguage = true;
                        }
                    }
                });
                if (!hasLanguage && langs.indexOf(language._id) === -1)
                    langs.push(language._id);
            });
            return langs;
        }

        checkMailValidity(email: string) {
            if (!email || email.length < 3)
                return;
            this.UserService.checkEmailValidity(email).then((response: ICountResponse) => {
                this.is_email_valid = (response.count === 0);
            });
        }

        /**
         * Adds a languages to a contributor
         */
        addLanguage(user: User, language_id: string) {
            this.ProjectService.addContributorLanguage(this.project._id, user._id, language_id).then((response) => {
                this.project.contributors.push({
                    user_id: user._id,
                    language_id: language_id
                });
            });
        }

        /**
         * Remove a language from a contributor
         */
        removeLanguage(user: User, language_id: string, event: MouseEvent) {
            event.stopPropagation();
            event.preventDefault();
            this.ProjectService.removeContributorLanguage(this.project._id, user._id, language_id).then((response) => {
                this.project.contributors = this.project.contributors.filter(contrib => {
                    return !(contrib.user_id === user._id && contrib.language_id === language_id);
                });
            });
        }

        /**
         * Creates a new contributors and sends an invite
         * User is added to the project contributors
         */
        inviteContributor(email: string, language: Language) {
            let newContributor = new Contributor();
            newContributor.email = email;
            newContributor["invite"] = true;
            if (!this.project.contributors)
                this.project.contributors = [];
            let contribResponse;
            this.saving = true;
            this.UserService
                .save(newContributor)
                .then((response: Contributor) => {
                    contribResponse = response;
                    return this.ProjectService.addContributorLanguage(this.project._id, response._id, language._id);
                })
                .then(() => {
                    this.users.push(contribResponse);
                    this.project.contributors.push({
                        user_id: contribResponse._id,
                        language_id: language._id
                    });
                    this.ToasterService.pop("app.contributor.invited");
                    this.email = null;
                    this.language = null;
                    this.form.$setPristine();
                    this.form.$setUntouched();
                })
                .finally(() => this.saving = false);
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("AddContributorController", AddContributorController);
}