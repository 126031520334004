/// <reference path="../../core/base-data.service.ts" />

namespace app {
    export class AccountService extends DataService<Account> {

        public resource: string = "accounts";

        public getNextInvoice() {
            return this.Restangular.one(this.resource + "/invoices/next").get();
        }
        public getInvoices() {
            return this.Restangular.all(this.resource + "/invoices/list").getList();
        }
        public getSubscription() {
            return this.Restangular.one(this.resource + "/subscription/plan").get();
        }
        public changePlan(id: string) {
            return this.Restangular.one(this.resource + "/subscription/plan").customPUT({ license_plan: id });
        }
        public addCard(token: string, card_id: string) {
            return this.Restangular.one(this.resource + "/subscription/card").customPOST({ card_token: token, card_id: card_id });
        }
        public getCurrentCardInfo() {
            return this.Restangular.one(this.resource + "/subscription/card").get();
        }
        public deleteCurrentCard() {
            return this.Restangular.one(this.resource).customDELETE("subscription/card");
        }
    }
    angular.module("app").service("AccountService", AccountService);
}