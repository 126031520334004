namespace app {

    interface IScope extends ng.IScope {
        onEnter(): void;
    }

    /**
     * Will trigger the method specific in attributes "on-enter" when pressing the enter key
     * Used on inputs for submitting /executing on enter
     */
    class OnEnter implements ng.IDirective {
        scope = {
            onEnter: "&"
        };
        static factory() {
            return () => {
                return new OnEnter();
            };
        }

        link = (scope: IScope, element: JQuery) => {
            element.bind("keydown keypress", (event) => {
                let keyCode = event.which || event.keyCode;
                // Enter is pressed
                if (keyCode === 13) {
                    scope.onEnter();
                    scope.$apply();
                    event.preventDefault();
                }
            });
        }
    }
    angular.module("app").directive("onEnter", OnEnter.factory());
}