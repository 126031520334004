namespace app {
    export class ChangePasswordController {
        constructor(
            private user: User,
            private UserService: UserService,
            private $mdDialog: ng.material.IDialogService,
            private ToasterService: ToasterService) {
        }
        changePassword(password: string, confirm: string) {
            if (password !== confirm)
                return;
            this.UserService.updatePassword(this.user, password).then((response) => {
                this.ToasterService.pop("app.password.updated");
                this.close();
            });
        }

        close() {
            this.$mdDialog.hide();
        }
    }

    angular.module("app").controller("ChangePasswordController", ChangePasswordController);
}