namespace app {
	export class ContributorListController {

		constructor(
			private UserService: UserService,
			private ModalService: ModalService,
			private users: User[],
			private $rootScope: IRootScope) {
				this.users = this.users.filter(user => !this.isSelf(user));

				this.$rootScope.$on("delete:user", (event, data: User) => {
					this.users = this.users.filter(user => user._id !== data._id);
				});
			}

		isSelf(user: User) {
			return this.$rootScope.user && user.email === this.$rootScope.user.email;
		}

		showContributorDetails(user: User, event: MouseEvent) {
			this.ModalService.showContributorDetailsModal(user, event);
		}
	}
	angular.module("app").controller("ContributorListController", ContributorListController);
}