namespace app {
    export interface IIdStateService extends ng.ui.IStateService {
        id: string;
        language_id: string;
    }

    export class AppRoutes {
        /*@ngInject*/
        constructor(
            private $stateProvider: ng.ui.IStateProvider,
            private $urlRouterProvider: ng.ui.IUrlRouterProvider) {

            $urlRouterProvider.otherwise(($injector: ng.auto.IInjectorService) => {
                const $state: any = $injector.get("$state");
                $state.go("pages.login");
            });

            $stateProvider
                .state("pages", {
                    abstract: true,
                    url: "/pages",
                    templateUrl: "src/features/authenticate/page.html",
                })
                .state("pages.login", {
                    url: "/login",
                    templateUrl: "src/features/authenticate/login.html",
                    controller: AuthController,
                    controllerAs: "vm"
                })
                .state("pages.logout", {
                    url: "/logout",
                    controller: LogoutController
                })
                .state("pages.invite", {
                    url: "/invite/confirm",
                    templateUrl: "src/features/authenticate/invite.html",
                    controller: InviteController,
                    controllerAs: "vm"
                })
                .state("pages.reset", {
                    url: "/reset_password",
                    templateUrl: "src/features/users/reset-password.html",
                    controller: ResetPasswordController,
                    controllerAs: "vm"
                })
                .state("pages.signup", {
                    url: "/signup",
                    templateUrl: "src/features/users/signup.html",
                    controller: SignupController,
                    controllerAs: "vm",
                    data: {
                        blockui: true
                    }
                })
                .state("pages.signupcomplete", {
                    url: "/signupcomplete",
                    template: "<div></div>",
                    controller: () => {}
                })
                .state("app", {
                    abstract: true,
                    url: "/app",
                    templateUrl: "src/app.html",
                    resolve: {
                        languages: (LanguageService: LanguageService) => {
                            return LanguageService.findAll();
                        }
                    }
                })
                .state("app.account", {
                    url: "/account",
                    templateUrl: "src/features/accounts/account.html",
                    controller: "AccountController",
                    controllerAs: "vm",
                    data: {
                        blockui: true
                    },
                    resolve: {
                        account: (AccountService: AccountService) => {
                            let query = new Query();
                            query.populate(["license_plan"]);
                            return AccountService.findById("self", query);
                        },
                        subscription: (AccountService: AccountService) => {
                            return AccountService.getSubscription();
                        }
                    },

                })
                .state("app.account.profile", {
                    url: "/profile",
                    templateUrl: "src/features/accounts/profile.html",
                    controller: "ProfileController",
                    controllerAs: "vm",
                    data: {
                        blockui: true
                    },
                    resolve: {
                        user: (UserService: UserService) => {
                            return UserService.getCurrentUser();
                        }
                    }
                })
                .state("app.account.subscription", {
                    url: "/subscription",
                    data: {
                        requireLogin: true
                    },
                    templateUrl: "src/features/accounts/account-subscription-details.html",
                    controller: "AccountSubscriptionController",
                    controllerAs: "vm",
                    resolve: {
                        license_plans: (LicensePlanService: LicensePlanService) => {
                            return LicensePlanService.findAll();
                        },
                        current_card: (AccountService: AccountService) => {
                            return AccountService.getCurrentCardInfo();
                        }
                    }
                })
                .state("app.account.invoices", {
                    url: "/invoices",
                    data: {
                        requireLogin: true
                    },
                    templateUrl: "src/features/accounts/account-invoice-list.html",
                    controller: "AccountInvoiceListController",
                    controllerAs: "vm",
                    resolve: {
                        invoices: (AccountService: AccountService) => {
                            return AccountService.getInvoices();
                        },
                        nextInvoice: (AccountService: AccountService) => {
                            return AccountService.getNextInvoice();
                        }
                    }
                })
                .state("app.license", {
                    url: "/license",
                    data: {
                        requireLogin: true
                    },
                    templateUrl: "src/features/licenses/license-plan.html",
                    controller: "LicensePlanController",
                    controllerAs: "vm",
                    resolve: {
                        account: (AccountService: AccountService) => {
                            let query = new Query();
                            query.populate(["license_plan"]);
                            return AccountService.findById("self", query);
                        },
                        license_plans: (LicensePlanService: LicensePlanService) => {
                            return LicensePlanService.findAll();
                        }
                    }
                })
                .state("app.contributors", {
                    url: "/contributors",
                    templateUrl: "src/features/users/contributor-list.html",
                    controller: "ContributorListController",
                    controllerAs: "vm",
                    data: {
                        blockui: true
                    },
                    resolve: {
                        users: (UserService: UserService) => {
                            let query = new Query();
                            query.sort("username");
                            return UserService.findByQuery(query);
                        }
                    }
                })
                .state("app.projects", {
                    url: "/projects",
                    templateUrl: "src/features/projects/project-list.html",
                    controller: "ProjectListController",
                    controllerAs: "vm",
                    data: {
                        blockui: true
                    },
                    resolve: {
                        projects: (ProjectService: ProjectService) => {
                            return ProjectService.listByName();
                        }
                    }
                })
                .state("app.project", {
                    url: "/project/:id",
                    templateUrl: "src/features/projects/project.html",
                    controller: "ProjectController",
                    controllerAs: "vm",
                    data: {
                        blockui: true
                    },
                    resolve: {
                        project: (ProjectService: ProjectService, $stateParams: IIdStateService) => {
                            if ($stateParams.id) {
                                let query = new Query();
                                query.populate(["languages", "primary_language"]);
                                return ProjectService.findById($stateParams.id, query);
                            }
                        },
                        projectTags: (TranslationService: TranslationService, $stateParams: IIdStateService) => {
                            if ($stateParams.id)
                                return TranslationService.getProjectTags($stateParams.id);
                        },
                        contributors: (UserService: UserService) => {
                            return UserService.findContributors();
                        },
                        projects: (ProjectService: ProjectService) => {
                            let query = new Query();
                            query.select(["name"]);
                            return ProjectService.findByQuery(query);
                        },
                        translationCount: (TranslationService: TranslationService, $stateParams: IIdStateService) => {
                            let query = new Query();
                            query.addCriteria(C.equals("project_id", $stateParams.id));
                            return TranslationService.countByQuery(query);
                        }

                    }
                })
                .state("app.project.translation", {
                    url: "/:language_id",
                    templateUrl: "src/features/translations/translation.html",
                    controller: "TranslationController",
                    controllerAs: "vm",
                    data: {
                        blockui: false
                    },
                    resolve: {
                        language: (LanguageService: LanguageService, $stateParams: ng.ui.IStateParamsService) => {
                            return LanguageService.findById($stateParams["language_id"]);
                        },
                        translations: (TranslationService: TranslationService, $stateParams: IIdStateService) => {
                            return TranslationService.findByFilter($stateParams.id, $stateParams.language_id);
                        },
                        definitionCount: (TranslationService: TranslationService, $stateParams: IIdStateService) => {
                            return TranslationService.findTranslationCounts($stateParams.id, $stateParams.language_id);
                        },
                        translationCount: (TranslationService: TranslationService, $stateParams: IIdStateService) => {
                            let query = new Query();
                            query.addCriteria(C.equals("project_id", $stateParams.id));
                            return TranslationService.countByQuery(query);
                        }
                    }
                })
                .state("app.languages", {
                    url: "/languages",
                    templateUrl: "src/features/languages/language-list.html",
                    controller: "LanguageListController",
                    controllerAs: "vm"
                })
                .state("app.language", {
                    url: "/languages/:id",
                    templateUrl: "src/features/languages/language.html",
                    controller: "LanguageController",
                    controllerAs: "vm",
                    resolve: {
                        language: (LanguageService: LanguageService, $stateParams: ng.ui.IStateParamsService) => {
                            if (AppRoutes.hasId($stateParams))
                                return LanguageService.findById($stateParams["id"]);
                            return null;
                        }
                    }
                });
        }
        static hasId($stateParams) {
            if ($stateParams["id"] && $stateParams["id"] !== "")
                return true;
            return false;
        }
    }
}