namespace app {
    export class DefinitionDetailsController {

        public referenceKey: string;
        public form: ng.IFormController;

        constructor(
            private $mdDialog: ng.material.IDialogService,
            private $rootScope: ng.IRootScopeService,
            private TranslationService: TranslationService,
            private translation: Translation,
            private project: Project) {

            this.referenceKey = this.translation.reference_key;
        }

        close() {
            this.$mdDialog.cancel();
        }

        delete() {
           this.$rootScope.$broadcast("delete.translation", this.translation);
           this.close();
        }

        save(referenceKey: string) {
            if (referenceKey.length < 2) {
                return;
            }
            this.TranslationService.updateReferenceKey(this.translation._id, referenceKey).then(() => {
                this.translation.reference_key = referenceKey;
                this.close();
            });
        }
    }
    angular.module("app").controller("DefinitionDetailsController", DefinitionDetailsController);
}