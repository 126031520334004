namespace app {
    export class ProjectActivityController {

        private navId: string;
        private activities: ProjectActivity[] = [];
        constructor(
            private ProjectService: ProjectService,
            private ProjectActivityService: ProjectActivityService,
            private $rootScope: IRootScope,
            private $mdSidenav: ng.material.ISidenavService,
            private $stateParams: ng.ui.IStateParamsService,
            private AppConstants: IAppConstants) {

            this.ProjectActivityService.findRecentActiviy($stateParams["id"]).then((response) => {
                this.activities = response;
            });
        }

        generateText(activity: ProjectActivity) {
            let languageName = (!activity.language_id) ? " - " : activity.language_id.name;
            if (activity.type === this.AppConstants.EVENT_PROJECT_DEFINITION_ADDED)
                // tslint:disable-next-line:max-line-length
                return "Saved new text <b>" + activity.text + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_IMPORT)
                return "Imported <b>" + activity.text + "</b> number of definitions in <b>" + languageName + "</b>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_COMMENT_POSTED)
                // tslint:disable-next-line:max-line-length
                return "Added comment <b>" + activity.text + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TOGGLE_FUZZY) {
                let fuzzy = (activity.text) ? "on" : "off";
                // tslint:disable-next-line:max-line-length
                return "<span>Toggle fuzzy <b>" + fuzzy + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b></span>";
            }
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TOGGLE_REVIEWED) {
                let reviewed = (activity.text) ? "on" : "off";
                // tslint:disable-next-line:max-line-length
                return "<span>Toggle reviewed <b>" + reviewed + "</b> for <b>" + activity.reference_key + "</b> in <b>" + languageName + "</b></span>";
            }
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TRANSLATION_ADDED)
                // tslint:disable-next-line:max-line-length
                return "<span>Added key <b>" + activity.reference_key + "</b> with primary text <b>" + activity.text + "</b> in <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_TRANSLATION_DELETED)
                return "<span>Deleted key <b>" + activity.reference_key + "</b> with primary text <b>" + activity.text + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_NOTIFY)
                return "<span>Notified contributors by email</span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_CONTRIBUTOR_ADDED)
                return "<span>Added <b>" + activity.text + "</b> as contributor for <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_CONTRIBUTOR_DELETED)
                return "<span>Removed <b>" + activity.text + "</b> as contributor for <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_LANGUAGE_ADDED)
                return "<span>Added language <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_LANGUAGE_DELETED)
                return "<span>Removed language <b>" + languageName + "</b></span>";
            else if (activity.type === this.AppConstants.EVENT_PROJECT_CREATED)
                return "<span>Created project <b>" + activity.project_id.name + "</b></span>";
        }

        close() {
            this.$mdSidenav(this.navId).close();
        }
    }
    angular.module("app").controller("AddProjectController", AddProjectController);
}