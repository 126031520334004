namespace app {

    class LoadingButton implements ng.IDirective {
        static factory() {
            return () => {
                return new LoadingButton();
            };
        }
        templateUrl: string = "src/components/loading-button/loading-button.html";

        scope = {
            isLoading: "=",
            btnText: "@",
            btnClass: "@",
            loaderClass: "@",
            loaderDiameter: "@",
            action: "&",
            ngDisabled: "="
        };

        link = (scope: any, element: JQuery) => {
            scope.loaderClass = (scope.loaderClass) ? scope.loaderClass : "md-accent md-hue-1";
            scope.btnClass = (scope.btnClass) ? scope.btnClass : "md-primary md-raised";
            scope.loaderDiameter = (scope.loaderDiameter) ? scope.loaderDiameter : "35";
        }
    }
    angular.module("app").directive("mdLoadingButton", LoadingButton.factory());
}