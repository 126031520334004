namespace app {
	export class LanguageController {

		constructor(
			private LanguageService: LanguageService,
			private $state: ng.ui.IStateService,
			private language: Language,
			protected Restangular: restangular.IService) {}

		save(language) {
			this.LanguageService.save(language).then(() => {
				this.$state.go("app.languages");
			});
		}

		remove(language) {
			this.LanguageService.delete(language).then((response) => {
				this.$state.go("app.languages");
			});
		}
	}

	angular.module("app").controller("LanguageController", LanguageController);
}