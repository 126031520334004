namespace app {

    angular.module("app", [
        "app.views",
        "restangular",
        "LocalStorageModule",
        "ngMaterial",
        "ngMessages",
        "cfp.hotkeys",
        "ngAnimate",
        "ngCookies",
        "pascalprecht.translate",
        "ui.router",
        "ngSanitize",
        "sly",
        "angular-stripe",
        "credit-cards",
        "ngFileUpload"
    ])
        .constant("AppConstants", AppConstants)
        .config(AppRoutes)
        .config(AppConfigs)
        .run(AppRun)
        .controller("AppController", AppController)
        .factory("outputMissingKeysHandler", () => {
            // This is for handling missing keys - making it easy to add to the language sheet
            let missingKeys: string[] = [];
            (window as any).printMissingTranslationKeys = () => {
                console.info(missingKeys.sort().join("\n"));
            };

            // Function is called whenever a translation cannot be found
            return (translationID: string) => {
                if (missingKeys.indexOf(translationID) < 0 && translationID.indexOf(".") >= 0) {
                    if (missingKeys.length === 0) { // First missing key
                        // tslint:disable-next-line:max-line-length
                        console.log("%c%s", "color: #f05050; background: #edf1f2;padding:0 2em;font-size:1.2em;", "Found missing translation keys! Call printMissingTranslationKeys() to view.");
                    }
                    missingKeys.push(translationID);
                }
            };
        });

    // Bootstrap the application manually
    angular.element(document).ready(() => {
        angular.bootstrap(document, ["app"]);
    });
}