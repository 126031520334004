namespace app {
    export class AddCreditCardController {

        public card: ICard;
        public cardTypes: string[] = ["Visa", "Visa (debit)", "American Express", "MasterCard", "Discover", "Diners Club", "JCB"];
        public years: number[] = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
        public months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        public addingcard: boolean = false;
        constructor(
            private AccountService: AccountService,
            private ToasterService: ToasterService,
            private stripe: any,
            private $mdDialog: ng.material.IDialogService,
            private $rootScope: IRootScope) {
        }

        addCard(card: ICard) {
            this.addingcard = true;
            let copy = angular.copy(card);
            this.stripe.card.createToken(copy).then((response): restangular.IPromise<any> => {
                return this.AccountService.addCard(response.id, response.card.id);
            }).then((resCard) => {
                this.$rootScope.$broadcast("card_added", resCard.data);
                this.ToasterService.pop("app.added.card.successful");
                this.close();
            }).catch(() => {
                this.ToasterService.pop("app.added.card.failure");
            }).finally(() => {
                this.addingcard = false;
            });
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("AddCreditCardController", AddCreditCardController);
}