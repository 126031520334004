/// <reference path="../../core/base-data.service.ts" />

namespace app {
    export class UserService extends DataService<User> {

        public resource: string = "users";

        findContributors() {
            let query = new Query()
                .addCriteria(C.equals("contributor", true))
                .populate(["projects.language_id"])
                .sort("email");
            return this.findByQuery(query);
        }

        getCurrentUser() {
            if (this.$rootScope.user) {
                let deferred = this.$q.defer();
                this.$timeout(() => {
                    return deferred.resolve(this.$rootScope.user);
                });
                return deferred.promise;
            } else {
                return this.Restangular.one(this.resource + "/current").get();
            }
        }

        saveUserProject(user: User) {
            return this.Restangular.one(this.resource + "/projects", user._id).customPUT({
                projects: user.projects
            });
        }

        countByEmail(email: string) {
            let query = new Query();
            query.addCriteria(C.equals("email", email));
            return this.countByQuery(query);
        }
        checkEmailValidity(email: string) {
            return this.Restangular.one("check_email_validity").get({ email: email });
        }

        updatePassword(user: User, password: string) {
            return this.Restangular.one("update_password/" + this.resource, user._id).customPUT({
                password: password
            });
        }

        requestResetPassword(email: string) {
            return this.Restangular.one("request_reset_password").customPOST({
                email: email
            });
        }
        doResetPassword(password: string, token: string) {
            return this.Restangular.one("do_reset_password").customPOST({
                password: password,
                token: token
            });
        }

        signup(email: string, password: string) {
            let user = new User();
            user.email = email;
            user["password"] = password;
            return this.Restangular.one("signup").customPOST({
                user: user
            });
        }
    }
    angular.module("app").service("UserService", UserService);
}