namespace app {

    export class AddLanguageController {

        public saving: boolean;
        public languages: Language[] = [];
        constructor(
            private ProjectService: ProjectService,
            private project: Project,
            private unusedLanguages: Language[],
            private $mdDialog: ng.material.IDialogService) {}

        addLanguages(languages: Language[]) {
            this.saving = true;
            let ids: string[] = languages.map(lang => lang._id);
            this.ProjectService.addLanguages(this.project._id, ids).then((response) => {
                languages.forEach(lang => {
                    this.project.languages.push(lang);
                    this.unusedLanguages.splice(this.unusedLanguages.indexOf(lang), 1);
                    this.close();
                });
            }).finally(() => {
                this.saving = false;
            });
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("AddLanguageController", AddLanguageController);
}