namespace app {
    export class ResetPasswordController {

        private loading: boolean = false;

        constructor(
            private ToasterService: ToasterService,
            private UserService: UserService,
            private $location: ng.ILocationService,
            private $rootScope: IRootScope,
            private $mdToast: ng.material.IToastService,
            private $state: ng.ui.IStateService) {
        }

        /**
         * Do the password reset!
         */
        resetPassword(password: string) {
            let token = this.$rootScope.resetToken;
            if (!token)
				return this.$mdToast.show(this.$mdToast.simple().textContent("Upss something went wrong"));
            this.loading = true;
            this.UserService.doResetPassword(password, token).then(() => {
                this.ToasterService.pop("app.password.has.been.reset");
                this.$state.go("pages.login");
            }).finally(() => {
                this.loading = false;
            });
        }
    }
    angular.module("app").controller("ResetPasswordController", ResetPasswordController);
}