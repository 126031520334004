namespace app {
    export class ModalService {

        constructor(
            private $mdDialog: any,
            private LanguageService: LanguageService,
            private ProjectService: ProjectService) { }

        public showImportModal(event: Event, project: Project) {
            this.$mdDialog.show({
                controller: ProjectImportController,
                templateUrl: "src/features/projects/project-import-modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    languages: (LanguageService: LanguageService) => {
                        return this.LanguageService.findAll();
                    }
                },
                locals: {
                    project: project
                }
            });
        }

        public showContributorDetailsModal(user: User, event: Event) {
            this.$mdDialog.show({
                controller: ContributorDetailsController,
                templateUrl: "src/features/users/contributor-details.modal.html",
                parent: angular.element(document.body),
                targetEvent: event,
                controllerAs: "vm",
                clickOutsideToClose: true,
                bindToController: true,
                resolve: {
                    userProjects: () => {
                        return this.ProjectService.getContributorProjects(user._id);
                    }
                },
                locals: {
                    contributor: user
                }
            });
        }
    }
    angular.module("app").service("ModalService", ModalService);
}