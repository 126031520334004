namespace app {
    export class ForgotPasswordController {
        private email_not_exists: boolean = false;
        private saving: boolean = false;

        constructor(
            private UserService: UserService,
            private $mdDialog: any,
            private ToasterService: ToasterService) {
        }

        resetPassword(email: string) {
            this.email_not_exists = false;
            this.saving = true;
            this.UserService.requestResetPassword(email).then(x => {
                if (x.success === false)
                    return this.email_not_exists = true;
                this.close();
                this.ToasterService.pop("app.email.reset.mail.sent");
            }).finally(() => {
                this.saving = false;
            });
        }

        close() {
            this.$mdDialog.hide();
        }

    }
    angular.module("app").controller("ForgotPasswordController", ForgotPasswordController);
}