namespace app {
    export class ProjectExportController {

        public formats: IFormatObject[] = [];
        public token: string;

        constructor(
            private ProjectService: ProjectService,
            private project: Project,
            private TrackingService: TrackingService,
            private $mdDialog: ng.material.IDialogService,
            private localStorageService: angular.local.storage.ILocalStorageService) {

            this.formats = this.ProjectService.getSupportedFormats();
            this.token = this.localStorageService.get<string>("token");
        }

        export(format: string) {
            this.ProjectService.validateKeyCount().then(() => {
                this.TrackingService.trackExport({
                    project: this.project.name,
                    format,
                    language: this.project.languages.map(l => l.name).join(", ")
                });
                setTimeout(() => {
                    window.location.assign("/api/v1/projects/" + this.project._id + "/export/" + format + "?token=" + this.token);
                }, 500);
            });
        }

        downloadConfig(format: string) {
            window.location.assign("/api/v1/projects/" + this.project._id + "/cliconfig/" + format + "?token=" + this.token);
        }

        close() {
            this.$mdDialog.hide();
        }
}
angular.module("app").controller("ProjectExportController", ProjectExportController);
}