/// <reference path="../../core/base-data.service.ts" />
namespace app {
	export class FeedbackService extends DataService<Language> {
		public resource: string = "feedback";

        sendFeedback(subject: string, description: string) {
            return this.save({subject, description});
        }
	}
	angular.module("app").service("FeedbackService", FeedbackService);
}