namespace app {

    export class AddDefinitionController {

        private related: Translation[] = [];
        private related_proposed: boolean = false;
        private loadingRelated: boolean = false;
        private active_related: Translation | null;
        private context: string = "";
        private key_valid: boolean | null = null;
        private reference_key: string | null = null;
        private text: string | null;
        private form: ng.IFormController;
        private saving: boolean = false;

        constructor(
            private TranslationService: TranslationService,
            private TrackingService: TrackingService,
            private ProjectService: ProjectService,
            private $rootScope: IRootScope,
            private $stateParams: IIdStateService,
            private translations: Translation[],
            private project: Project,
            private $mdDialog: any,
            private definitionCount: any,
            private translationCount: any,
            private ToasterService: ToasterService) {}

        copyRelated(related: Translation) {
            let translation = new Translation();
            translation.active_definition = related.active_definition;
            translation.definitions = related.definitions;
            translation.comments = [];
            translation.primary_text = related.primary_text;
            translation.project_id = this.project._id;
            let now = new Date();
            translation.definitions.forEach((definition, idx) => {
                definition.copied_from = related.definitions[idx]._id;
                definition.copy_time = now.getTime();
            });
            translation.reference_key = this.reference_key as string;
            this.persist(translation);
        }

        save(reference_key: string, text: string, context: string) {
            if (this.form.$invalid)
                return;
            if (this.active_related)
                return this.copyRelated(this.active_related);
            let translation = new Translation();
            translation.reference_key = reference_key;
            translation.project_id = this.$stateParams.id;
            translation.primary_text = text;
            if (context)
                translation.context = context;
            let definition = new Definition();
            definition.language_id = this.project.primary_language._id;
            definition.translated_by = this.$rootScope.user!.email;
            definition.text = text;
            translation.definitions.push(definition);
            this.persist(translation);
        }

        private persist(translation: Translation) {
            this.saving = true;
            this.TranslationService.save(translation)
                .then((response) => {
                    this.TranslationService.initTranslation(response);
                    this.translations.unshift(response);
                    this.translationCount.count++;
                    if (this.project.primary_language._id === this.$stateParams.language_id)
                        this.definitionCount.count++;
                    this.resetForm();
                    this.$rootScope.$broadcast("add.translation", translation);
                    this.ToasterService.pop("String was successfully added!");
                    this.TrackingService.trackAddNewDefinition({
                        text: translation.active_definition.text,
                        language: this.project.primary_language.name
                    });
                })
                .finally(() => {
                    this.saving = false;
                }).catch(() => {
                    this.close();
                });
        }
        private resetForm() {
            this.related = [];
            this.active_related = null;
            this.key_valid = null;
            this.reference_key = null;
            this.text = null;
            this.context = "";
            this.form.$setUntouched();
            this.$rootScope.$broadcast("focus:addtrans:ref");
        }

        validateReferenceKey(reference_key: string) {
            if (!reference_key || reference_key === "")
                return this.key_valid = null;
            let query = new Query();
            query.addCriteria(
                C.and(
                    C.equals("project_id", this.project._id),
                    C.equals("reference_key", reference_key)
                )
            );
            this.TranslationService.countByQuery(query).then((response) => {
                this.key_valid = (response.count === 0);
            });
        }

        checkRelated(text: string) {
            if (text && text.length > 2) {
                this.loadingRelated = true;
                this.TranslationService.findSimilar(this.project.primary_language._id, text).then((response: Translation[]) => {
                    this.related = response;
                    this.related_proposed = true;
                }).finally(() => {
                    this.loadingRelated = false;
                });
            }
        }

        close() {
            this.$mdDialog.hide();
        }
    }
    angular.module("app").controller("AddDefinitionController", AddDefinitionController);
}