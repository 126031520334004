/// <reference path="../../../core/base-data.service.ts" />

namespace app {
    export class ProjectActivityService extends DataService<ProjectActivity> {
        public resource: string = "project_activities";

        public pagination: IPagination = {
            items_per_page: 30,
            current_page: 1,
            total_items: 10,
            num_pages: 1,
            append: false
        };

        /**
         * Find recent project activity
         */
        findRecentActiviy(project_id) {
            let q = new Query();
            q.addCriteria(C.and(
                C.equals("project_id", project_id)
            ));
            q.sort("-create_time");
            q.pagination(this.pagination);
            q.populate(["language_id", "project_id", "translation_id"]);
            return this.findByQuery(q);
        }
    }
    angular.module("app").service("ProjectActivityService", ProjectActivityService);
}