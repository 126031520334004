namespace app {
    export class ToasterService {

        constructor(
            private $mdToast: ng.material.IToastService,
            private $filter: any
        ) {}

        pop(message, duration = 5000) {
            this.$mdToast.show(this.$mdToast.simple().hideDelay(duration).textContent(this.$filter("translate")(message)));
        }
    }
    angular.module("app").service("ToasterService", ToasterService);
}