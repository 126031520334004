namespace app {

    interface IScope extends ng.IScope {
        email: string;
    }

    class ValidateEmail implements ng.IDirective {
        static factory() {
            return (UserService: UserService, $q: ng.IQService) => {
                return new ValidateEmail(UserService, $q);
            };
        }

        constructor(private UserService: UserService, private $q: ng.IQService) {}

        require = "ngModel";
        link = (scope: IScope, element, attributes, ngModel: ng.INgModelController) => {
            ngModel.$asyncValidators["duplicate"] = (modelValue) => {
                return this.UserService.checkEmailValidity(modelValue).then((response: ICountResponse) => {
                    if (response.count === 0)
                        return true;
                    return this.$q.reject("Email already exists");

                });
            };

            scope.$watch("email", () => {
                ngModel.$validate();
            });
        }
    }
    angular.module("app").directive("validateEmail", ValidateEmail.factory());
}