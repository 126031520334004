namespace app {

    export interface IRootScope extends ng.IRootScopeService {
        dataloading: boolean;
        bootstrapped: boolean;
        authorized: boolean;
        authenticating: boolean;
        stateLoading: any;
        blockui: boolean;
        searching: boolean;
        user: User | undefined;
        noalert: boolean;
        loadingBarTimer: any;
        loading: boolean;
        year: number;
        stateName: string;
        currentLanguage: string;
        inviteToken?: string;
        inviteEmail?: string;
        resetToken?: string;
        changeLanguage(lang: string): void;
    }
    /**
     * Executes in the runs phase of the angular application
     */
    export class AppRun {

        /*@ngInject*/
        constructor(
            $window: ng.IWindowService,
            $rootScope: IRootScope,
            $state: ng.ui.IStateService,
            $location: ng.ILocationService,
            $timeout: ng.ITimeoutService,
            localStorageService: angular.local.storage.ILocalStorageService,
            Restangular: restangular.IService,
            AuthService: AuthService,
            $mdToast: ng.material.IToastService,
            ToasterService: ToasterService,
            $translate: ng.translate.ITranslateService) {

            ($window as any).ga("create", "UA-96473888-1", "auto");

            // Show loader initially
            $rootScope.blockui = false;
            // Handle toast on response
            Restangular.addResponseInterceptor((data, operation, what, url, response, deferred) => {
                $rootScope.dataloading = false;
                return data;
            });
            $rootScope.stateLoading = {};
            $rootScope.year = new Date().getFullYear();

            // Catch API erorrs
            Restangular.setErrorInterceptor(
                (response) => {
                    if (response.status === 401) {
                        $mdToast.show($mdToast.simple().textContent("Unauthorized"));
                        localStorageService.clearAll();
                        $state.go("pages.login");
                    } else if (response.status === 403) {
                        ToasterService.pop(response.data.message, 10000);
                        $state.go("app.account.subscription");
                        return response;
                    } else if (response.status === 404) {
                        $mdToast.show($mdToast.simple().textContent("Resource not found"));
                        console.error("Resource not available...");
                        $rootScope.blockui = false;
                    } else if (response.status === 400 || response.status === 500) {
                        console.log(response.data);
                        if (response.data.message)
                            ToasterService.pop(response.data.message);
                        if (response.data.errmsg)
                            ToasterService.pop(response.data.errmsg);
                        $rootScope.blockui = false;
                    }
                    return false; // stop the promise chain
                }
            );

            Restangular.addFullRequestInterceptor((element, operation, what, url, headers, params, httpConfig) => {
                $rootScope.dataloading = true;
                return element;
            });

            // Set token if it is localstorage
            let token = localStorageService.get("token");
            if (token) {
                Restangular.setDefaultHeaders({ "x-access-token": token });
            }
            // Set language if it is in localstorage
            let lang = localStorageService.get<string>("site.language");
            $rootScope.currentLanguage = (lang) ? lang : "en_US";

            $rootScope.changeLanguage = (code: string) => {
                $translate.use(code);
                $rootScope.currentLanguage = code;
                localStorageService.set("site.language", code);
            };

            /**
             * Handles when we get authorized
             * Note that this does not mean we are done bootstrapping the application
             */
            $rootScope.$on("authorized", (event, data) => {
                $rootScope.authorized = true;
                $state.go("app.projects");
            });
            /**
             * Handles unauthorized sessions
             */
            $rootScope.$on("unauthorized", (event, data) => {
                $rootScope.bootstrapped = true;
                $rootScope.authorized = false;
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                ToasterService.pop("Unauthorized");
                $state.go("pages.login");

            });

            /**
             * BlockUI when changing state
             */
            $rootScope.$on("$stateChangeStart", (event, toState, toParams, fromState, fromParams) => {
                $rootScope.stateLoading = toState.name;
                if (toState.data && toState.data.blockui)
                    $rootScope.blockui = true;
                // if(toState.data && toState.data.requireLogin);
                //  return;
            });
            /**
             * unblock UI when changing state is done
             */
            $rootScope.$on("$stateChangeSuccess", (event, toState, toParams, fromState, fromParams) => {
                ($window as any).ga("send", "pageview", $location.path());
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                $rootScope.stateName = toState.name;
            });
            /**
             * unblock UI when state is not found
             */
            $rootScope.$on("$stateNotFound", (event, unfoundState, fromState, fromParams) => {
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                console.log("stateNotFound", event);
            });
            /**
             * unblock UI when state error occurs
             */
            $rootScope.$on("$stateChangeError", (event, toState, toParams, fromState, fromParams, error) => {
                $rootScope.stateLoading = false;
                $rootScope.blockui = false;
                console.log("stateError", error);
            });
            // Handle special invites route
            if ($location.path() === "/pages/invite/confirm") {
                $rootScope.inviteToken = $location.search().token;
                $rootScope.inviteEmail = $location.search().email;
                $state.go("pages.invite");
            }
            // Handle password resets route
            else if ($location.path() === "/pages/reset_password") {
                $rootScope.resetToken = $location.search().token;
                $state.go("pages.reset");
            }
            // Handle signup route
            else if ($location.path() === "/pages/signup") {
                return;
            }
            else if ($location.path() === "/pages/login") {
                return;
            }
            else if ($location.path() === "/") {
                if (AuthService.isAuthenticated())
                    $timeout(() => $state.go("app.projects"), 10);
            } else {
                if (!AuthService.isAuthenticated()) {
                    $state.go("pages.login");
                }
            }
        }
    }
}