namespace app {
	export class LanguageListController {

		constructor(
			private LanguageService: LanguageService,
			private languages: any[]) {}

		remove(language) {
			this.LanguageService.delete(language).then(() => {
				this.languages.splice(this.languages.indexOf(language), 1);
			});
		}
	}
	angular.module("app").controller("LanguageListController", LanguageListController);
}