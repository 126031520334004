namespace app {

    interface IScope extends ng.IScope {
        otherModelValue: string;
    }

    class CompareTo implements ng.IDirective {
        static factory() {
            return () => {
                return new CompareTo();
            };
        }
        require = "ngModel";
        scope = {
            otherModelValue: "=compareTo"
        };
        link = (scope: IScope, element, attributes, ngModel) => {
            ngModel.$validators.compareTo = (modelValue) => {
                return modelValue === scope.otherModelValue;
            };

            scope.$watch("otherModelValue", () => {
                ngModel.$validate();
            });
        }
    }
    angular.module("app").directive("compareTo", CompareTo.factory());
}