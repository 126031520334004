namespace app {

    interface IScope extends ng.IScope {
        closeDialog(): void;
    }

    class DialogHeader implements ng.IDirective {
        static factory() {
            return ($mdDialog: ng.material.IDialogService) => {
                return new DialogHeader($mdDialog);
            };
        }
        constructor(private $mdDialog: ng.material.IDialogService) { }

        scope = {
            text: "@"
        };

        link = (scope: IScope, element, attributes, ngModel) => {
            scope.closeDialog = () => {
                this.$mdDialog.cancel();
            };
        }

        template = "<md-toolbar>" +
        '<div class="md-toolbar-tools">' +
        "<h2 translate>{{text}}</h2>" +
        "<span flex></span>" +
        '<md-button class="md-icon-button" ng-click="closeDialog()">' +
        '<md-icon aria-label="Close dialog">close</md-icon>' +
        "</md-button>" +
        "</div>" +
        "</md-toolbar>";
    }
    angular.module("app").directive("mdDialogHeader", DialogHeader.factory());
}
