
namespace app {
    /**
     * Executes in the configurations phase of the angular application
     */
    export class AppConfigs {

        /*@ngInject*/
        constructor(
            $provide: ng.auto.IProvideService,
            $translateProvider: ng.translate.ITranslateProvider,
            $locationProvider: ng.ILocationProvider,
            RestangularProvider: restangular.IProvider,
            $mdThemingProvider: ng.material.IThemingProvider,
            $mdIconProvider: ng.material.IIconProvider,
            stripeProvider: any) {

            $translateProvider
                .useStaticFilesLoader({
                    prefix: "/assets/i18n/",
                    suffix: ".json"
                })
                .preferredLanguage(AppConstants.DEFAULT_LANGUAGE)
                .fallbackLanguage(AppConstants.DEFAULT_LANGUAGE)
                .useLocalStorage();
            // Configure restangular with base url
            RestangularProvider.setBaseUrl(AppConstants.API_URL);
            $translateProvider.useMissingTranslationHandler("outputMissingKeysHandler");

            // Set html5 mode
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            }).hashPrefix("!");
            // Stripe
            stripeProvider.setPublishableKey(AppConstants.STRIPE_PUB_KEY);

            // Theming
            $mdThemingProvider
                .theme("default")
                .primaryPalette("blue-grey")
                .accentPalette("teal", {
                    default: "500" // use shade 200 for default, and keep all other shades the same
                })
                .warnPalette("red", {
                    default: "500"
                });

            // Icons
            $mdIconProvider
                .iconSet("social", "img/icons/sets/social-icons.svg", 24)
                .defaultIconSet("img/icons/sets/core-icons.svg", 24);

            // Exception handling
            $provide.decorator("$exceptionHandler", ($delegate: any, $log: ng.ILogService) => {
                return (exception: Error, cause?: string, source?: string) => {
                    $delegate(exception, cause);
                    $log.error(exception.message, { exception: exception, cause: cause }, source, "error");
                };
            });
        }
    }
}