namespace app {
    export class SignupController {

        public signingup: boolean = false;
        public is_email_valid: boolean | null = null;
        public form: ng.IFormController;
        constructor(
            private UserService: UserService,
            private AuthService: AuthService,
            private $state: ng.ui.IStateService) { }

        signup(email: string, password: string) {
            if (this.form.$invalid)
                return;
            if (this.is_email_valid === false) {
                return;
            }
            this.signingup = true;
            this.UserService.signup(email, password).then(response => {
                this.$state.go("pages.signupcomplete").then(() => {
                    this.AuthService.authenticate(email, password);
                });
            }).catch(() => {
                this.signingup = false;
            });
        }
    }
    angular.module("app").controller("SignupController", SignupController);
}