namespace app {

    interface IScope extends ng.IScope {
        focusOn: string;
    }

    class FocusOn implements ng.IDirective {
        static factory() {
            return () => {
                return new FocusOn();
            };
        }

        scope = {
            focusOn: "@"
        };

        link = (scope: IScope, element, attributes, ngModel) => {
            scope.$on(scope.focusOn, () => {
                element[0].focus();
            });
        }
    }
    angular.module("app").directive("focusOn", FocusOn.factory());
}