namespace app {

    export interface IAppConstants {
        API_URL: string;
        EVENT_PROJECT_COMMENT_POSTED: string;
        EVENT_PROJECT_DEFINITION_ADDED: string;
        EVENT_PROJECT_TRANSLATION_ADDED: string;
        EVENT_PROJECT_TRANSLATION_DELETED: string;
        EVENT_PROJECT_LANGUAGE_ADDED: string;
        EVENT_PROJECT_LANGUAGE_DELETED: string;
        EVENT_PROJECT_CONTRIBUTOR_ADDED: string;
        EVENT_PROJECT_CONTRIBUTOR_DELETED: string;
        EVENT_PROJECT_TOGGLE_FUZZY: string;
        EVENT_PROJECT_TOGGLE_REVIEWED: string;
        EVENT_PROJECT_IMPORT: string;
        EVENT_PROJECT_EXPORT: string;
        EVENT_PROJECT_NOTIFY: string;
        EVENT_PROJECT_CREATED: string;
    }

    export class AppConstants {
        public static DEFAULT_LANGUAGE: string = "en_US";
        public static STRIPE_PUB_KEY: string = "pk_live_hoqNp4Fhiq4uoXoscqNdfBQs";
        public static get API_URL(): string { return "/api/v1/"; }
        public static get EVENT_PROJECT_COMMENT_POSTED(): string { return "EVENT_PROJECT_COMMENT_POSTED"; }
        public static get EVENT_PROJECT_DEFINITION_ADDED(): string { return "EVENT_PROJECT_DEFINITION_ADDED"; }
        public static get EVENT_PROJECT_TRANSLATION_ADDED(): string { return "EVENT_PROJECT_TRANSLATION_ADDED"; }
        public static get EVENT_PROJECT_TRANSLATION_DELETED(): string { return "EVENT_PROJECT_TRANSLATION_DELETED"; }
        public static get EVENT_PROJECT_LANGUAGE_ADDED(): string { return "EVENT_PROJECT_LANGUAGE_ADDED"; }
        public static get EVENT_PROJECT_LANGUAGE_DELETED(): string { return "EVENT_PROJECT_LANGUAGE_DELETED"; }
        public static get EVENT_PROJECT_CONTRIBUTOR_ADDED(): string { return "EVENT_PROJECT_CONTRIBUTOR_ADDED"; }
        public static get EVENT_PROJECT_CONTRIBUTOR_DELETED(): string { return "EVENT_PROJECT_CONTRIBUTOR_DELETED"; }
        public static get EVENT_PROJECT_TOGGLE_FUZZY(): string { return "EVENT_PROJECT_TOGGLE_FUZZY"; }
        public static get EVENT_PROJECT_TOGGLE_REVIEWED(): string { return "EVENT_PROJECT_TOGGLE_REVIEWED"; }
        public static get EVENT_PROJECT_IMPORT(): string { return "EVENT_PROJECT_IMPORT"; }
        public static get EVENT_PROJECT_EXPORT(): string { return "EVENT_PROJECT_EXPORT"; }
        public static get EVENT_PROJECT_NOTIFY(): string { return "EVENT_PROJECT_NOTIFY"; }
        public static get EVENT_PROJECT_CREATED(): string { return "EVENT_PROJECT_CREATED"; }
    }

}