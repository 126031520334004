namespace app {
    export class ContributorDetailsController {

        constructor(
            private userProjects: Project[],
            private contributor: User,
            private $rootScope: IRootScope,
            private $mdDialog: any,
            private ToasterService: ToasterService,
            private CountryService: CountryService,
            private ProjectService: ProjectService,
            private $state: ng.ui.IStateService,
            private UserService: UserService) {

            userProjects.forEach(project => {
                project.user_languages = this.getUserLanguages(project);
            });
        }

        addLanguage(project: Project, language_id: string) {
            this.ProjectService.addContributorLanguage(project._id, this.contributor._id, language_id).then((response) => {
                project.contributors.push({
                    user_id: this.contributor._id,
                    language_id: language_id
                });
                project.user_languages.push(language_id);
            });
        }

        removeLanguage(project: Project, lang_id: string, event: Event) {
            this.ProjectService.removeContributorLanguage(project._id, this.contributor._id, lang_id).then(() => {
                project.user_languages = (project as any).user_languages.filter(lang => lang !== lang_id);
            });
            event.stopPropagation();
        }

        close() {
            this.$mdDialog.cancel();
        }

        save(user: User) {
            this.UserService.save(user).then(() => {
                this.ToasterService.pop("User updated!");
                this.$state.go("app.contributors");
            });
        }

        isSelf(user: User) {
			return this.$rootScope.user && user.email === this.$rootScope.user.email;
		}

        remove(user: User) {
			if (this.isSelf(user))
				return;
			this.UserService.delete(user).then((response) => {
				this.$rootScope.$broadcast("delete:user", user);
			});
		}

        getUnusedUserLanguages(project: Project) {
            return project.languages.filter(language => {
                let isUserLang = project.user_languages.some(userLang => userLang === language);
                return !isUserLang;
            });
        }

        private getUserLanguages(project: Project) {
            return project.contributors
                    .filter(contrib => contrib.user_id === this.contributor._id)
                    .map(contrib => contrib.language_id);
        }
    }
    angular.module("app").controller("ContributorDetailsController", ContributorDetailsController);
}