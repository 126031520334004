namespace app {

    class RestrictToAdmin implements ng.IDirective {
        public ngIf: any;
        static factory() {
            return (ngIfDirective: any, $rootScope: IRootScope) => {
                return new RestrictToAdmin(ngIfDirective, $rootScope);
            };
        }
        /*@ngInject*/
        constructor(ngIfDirective: any, private $rootScope: IRootScope) {
            this.ngIf = ngIfDirective[0];
        }
        link = (scope: any, element: JQuery, $attr: any) => {
            if (this.$rootScope.user && this.$rootScope.user.admin) {
                $attr.ngIf = () => {
                    return false;
                };
                this.ngIf.link.apply(this.ngIf);
            }

        }
    }
    angular.module("app").directive("restrictToAdmin", RestrictToAdmin.factory());
}