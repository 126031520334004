namespace app {
	export class LogoutController {
		constructor(
			private AuthService: AuthService,
			private $state: ng.ui.IStateService) {

			AuthService.logout();
			this.$state.go("pages.login");
		}
	}
	angular.module("app").controller("LogoutController", LogoutController);
}