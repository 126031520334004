namespace app {
    class IconCarouselController {
        private icons: string[] = [];
        private interval: number;
        private icon: string;
        private text: string = "";
        private timer: ng.IPromise<any>;
        constructor(private $interval: ng.ITimeoutService) {
            this.nextIcon();
            this.timer = $interval(() => { this.nextIcon(); }, this.interval || 5000);
        }
        nextIcon() {
            let idx = this.icons.indexOf(this.icon);
            let nextIdx = (idx === -1 || idx === this.icons.length - 1) ? 0 : idx + 1;
            this.icon = this.icons[nextIdx];
            this.setText(this.icon);
        }

        setText(icon: string) {
            if (icon === "desktop_mac")
                this.text = "Desktop App";
            if (icon === "phone_android")
                this.text = "Mobile App";
            if (icon === "web")
                this.text = "Web site";
        }
    }

    class IconCarousel implements ng.IDirective {
        static factory() {
            return () => {
                return new IconCarousel();
            };
        }
        require = "ngMdIcon";

        // tslint:disable-next-line:max-line-length
        template = '<div style="color : grey; text-align : center" class="md-display-1">Localize your</div><ng-md-icon style="height : auto !important; width: auto !important;" size="192" options=\'{"rotation": "none", "easing" : "expo-out", "duration" : "700"}\' icon="{{vm.icon}}"></ng-md-icon><div style="color : grey; text-align : center" class="md-display-1">{{vm.text}}</div>';
        scope = {
            icons: "=",
            interval: "@",
            animation: "@"
        };
        bindToController = true;
        controllerAs = "vm";
        controller = IconCarouselController;

    }
    angular.module("app").directive("iconCarousel", IconCarousel.factory());
}