namespace app {

    interface IAttributes extends ng.IAttributes {
        errSrc: string;
        src: string;
    }
    /**
	 * will set a default image if we get a 404 on image src
	 */
    class ImageNotFound implements ng.IDirective {

        static factory() {
            return () => {
                return new ImageNotFound();
            };
        }

        link = (scope: ng.IScope, element: any, attrs: IAttributes) => {
            element.bind("error", () => {
                if (attrs.src !== attrs.errSrc) {
                    attrs.$set("src", attrs.errSrc);
                }
            });
        }
    }
    angular.module("app").directive("errSrc", ImageNotFound.factory());
}